<div *ngIf="this.isLoading" class="progress-spinner">
   <p-progressSpinner></p-progressSpinner>
</div>

<div class="card container">
   <div class="mb-3" id="mention_table">
      <div class="tyalls-table-name mb-3 flex justify-content-between">
         <h6
            (click)="changeDate(-1)"
            class="tyalls-table-heading task-scroll-text cursor-pointer ml-1"
         >
            < <span>Previous</span>
         </h6>

         <h6 class="tyalls-table-heading">
            <span class="opacity-60">Tasks for: </span>
            {{ this.selectedDate | DateLocalFormat: false }}
         </h6>

         <h6
            (click)="changeDate(1)"
            class="tyalls-table-heading task-scroll-text cursor-pointer mr-1"
         >
            <span>Next</span> >
         </h6>
      </div>
   </div>

   <app-day-task-table
      (dateChange)="this.changeDate($event)"
      [taskList]="tasks"
   ></app-day-task-table>
</div>
