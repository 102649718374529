import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'toCamelCase'
})
export class ToCamelCasePipe implements PipeTransform {
   transform(value: string): unknown {
      return value.replace(/\b\S/g, (t) => t.toUpperCase());
   }
}
