import { Component, OnInit } from '@angular/core';
import { LoadingOverlayStoreService } from '../../../services/stores/loading-overlay-store.service';
import { DropDownStoreService } from '../../../services/stores/drop-down-store.service';
import { Property } from '../../../domain/property';
import { PropertyStoreService } from '../../../services/stores/property-store.service';

@Component({
   selector: 'app-property-users',
   templateUrl: './property-users.component.html',
   styleUrls: ['./property-users.component.scss']
})
export class PropertyUsersComponent implements OnInit {
   isAddUserVisible: boolean;
   isLoading: boolean;
   currentProperty: Property | undefined;

   constructor(
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dropDownStore: DropDownStoreService,
      private propertyStoreService: PropertyStoreService
   ) {
      this.isAddUserVisible = false;
      this.isLoading = true;
      this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
         this.isLoading = loadingState.Users;
      });
      this.propertyStoreService.currentProperty.subscribe((property: Property) => {
         this.currentProperty = property;
      });
   }

   async ngOnInit(): Promise<void> {
      this.updateDropDownState(false);
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   onDialogClose() {
      this.isAddUserVisible = false;
   }

   onAddUserOpen() {
      this.isAddUserVisible = true;
   }
}
