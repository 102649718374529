<div *ngIf="this.isLoading" class="progress-spinner">
	<p-progressSpinner></p-progressSpinner>
</div>

<div [hidden]="!this.isPrimary" id="mention_table">
	<div class="relative tyalls-table-name flex justify-content-between pr-3">

		<div (click)="backButton()" class="absolute back-tag-position cursor-pointer flex gap-2">
			<div [hidden]="!this.isPrimary" class="text-white schedule-tag-comments">
				<div class="flex text-white font-bold mr-2"> < Back </div>
			</div>
		</div>

		<div class="absolute date-tag-position flex gap-2">
			<div [hidden]="!this.isPrimary" class="text-white schedule-tag-comments">
				<div class="flex">
					<span class="dark-purple-text font-bold mr-2">Due: </span>
					{{ this.currentTask.dueDate | DateLocalFormat: false }}
				</div>
			</div>
		</div>

		<div class="hidden lg:block">
			<div *ngIf="!this.pdfPanelInFocus" class="w-1rem">
				<h6
					[hidden]="!this.currentTask.prevTask"
					(click)="goToPreviousTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer ml-1"
				>
					<
				</h6>
			</div>

			<div *ngIf="this.pdfPanelInFocus" class="w-6rem mb-2">
				<h6
					[hidden]="!this.currentTask.prevTask"
					(click)="goToPreviousTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer ml-1"
				>
					< <span>Previous</span>
				</h6>
			</div>
		</div>

		<div class="lg:hidden block">
			<div *ngIf="this.pdfPanelInFocus" class="w-1rem mb-2">
				<h6
					[hidden]="!this.currentTask.prevTask"
					(click)="goToPreviousTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer ml-1 mr-2"
				>
					<
				</h6>
			</div>

			<div *ngIf="!this.pdfPanelInFocus" class="w-1rem mb-2">
				<h6
					[hidden]="!this.currentTask.prevTask"
					(click)="goToPreviousTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer ml-1 mr-2"
				>
					<
				</h6>
			</div>
		</div>

		<!-- Task title -->


		<h6
			*ngIf="this.pdfPanelInFocus" [ngClass]="this.pdfPanelInFocus? 'tyalls-table-heading flex text-sm md:text-base mt-1 md: mt-0':'tyalls-table-heading text-sm mt-1'"
		>
			<span *ngIf="this.pdfPanelInFocus" class="opacity-60 hidden md:block mr-1">Task name: </span>
			{{ this.currentTask.name.trim() }} <span *ngIf="currentTask.type === WorkflowType.Form">&nbsp;-&nbsp;{{this.currentTask.processId}}</span>
		</h6>

		<!-- Next button -->

		<div class="hidden lg:block">
			<div *ngIf="!this.pdfPanelInFocus" class="w-1rem mb-2">
				<h6
					[hidden]="!this.currentTask.nextTask"
					(click)="goToNextTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer text-right"
				>
					>
				</h6>
			</div>

			<div *ngIf="this.pdfPanelInFocus" class="w-6rem">
				<h6
					[hidden]="!this.currentTask.nextTask"
					(click)="goToNextTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer text-right"
				>
					<span>Next</span> >
				</h6>
			</div>
		</div>

		<div class="lg:hidden block">
			<div *ngIf="this.pdfPanelInFocus" class="w-1rem mb-2">
				<h6
					[hidden]="!this.currentTask.nextTask"
					(click)="goToNextTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer text-right"
				>
					>
				</h6>
			</div>

			<div *ngIf="!this.pdfPanelInFocus" class="w-1rem mb-2">
				<h6
					[hidden]="!this.currentTask.nextTask"
					(click)="goToNextTask()"
					class="tyalls-table-heading task-scroll-text cursor-pointer text-right"
				>
					>
				</h6>
			</div>
		</div>
	</div>
</div>

<!-- SPACER////////////////////////////////////////////////////////////////////// -->
<div
	*ngIf="this.currentFile?.type !== 4 && !this.isReviewable() && this.isPrimary"
	[class]="this.showPanelPadding"
></div>

<!-- REVIEW BUTTON ////////////////////////////////////////////////////////////// -->

<div *ngIf="this.isPrimary && this.hasFile">
	<div
		*ngIf="this.isReviewable()"
		[class]="this.showPanelPadding"
		class="flex justify-content-end pr-2 py-2"
	>
		<button
			(click)="onReview()"
			class="p-button-raised w-fit"
			label="Review"
			pButton
			pRipple
			type="button"
			[disabled]="isSubmitButtonDisabled()"
		></button>
	</div>

	<!-- REVIEW & ASSIGN BUTTONS /////////////////////////////////////////////////// -->

	<div
		*ngIf="this.isReviewableForm()"
		[class]="this.flexCss"
		class="choose-button-padding"
	>
		<div class="mb-2">
			<app-group-drop-down
				(groupChange)="this.relayGroupChange($event)"
				label="Please Select a Group to Assign the Form to"
			></app-group-drop-down>
		</div>

		<div class="mb-2">
			<button
				(click)="this.onAssign($event)"
				class="p-button-raised"
				label="Review & Assign"
				pButton
				pRipple
				type="button"
				[disabled]="isSubmitButtonDisabled()"
			></button>
		</div>

		<div class="mb-2">
			<button
				(click)="this.onComplete($event)"
				class="p-button-raised"
				label="Review & Complete Task"
				pButton
				pRipple
				type="button"
				[disabled]="isSubmitButtonDisabled()"
			></button>
		</div>
	</div>
</div>
<!-- RESEND BUTTON ////////////////////////////////////////////////////////////////// -->

<div
	class="flex justify-content-end choose-button-padding"
	*ngIf="this.currentFile?.type === 4 && this.phobosTaskInstance.completedDate"
>
	<div class="w-fit">
		<button
			(click)="onResend($event)"
			class="p-button-raised p-mr-2 p-mb-2"
			label="Resend Completion Email"
			pButton
			pRipple
			type="button"
			[disabled]="isSubmitButtonDisabled()"
		></button>
	</div>
</div>

<div class="panel-height" [hidden]="!this.hasFile" id="{{ this.containerId }}"></div>

<div *ngIf="this.isPrimary && !this.hasFile">
	<app-upload-workflow-file
		(uploadAction)="this.uploadWorkflowFile()"
		[uploadedFiles]="this.uploadedFiles"
	></app-upload-workflow-file>
</div>
