import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PropertyService } from '../../services/property.service';
import { TaskService } from '../../services/task.service';
import { UserStoreService } from '../../services/stores/user-store.service';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { MessageService } from 'primeng/api';
import { Property } from '../../domain/property';
import { RowButton } from '../../panel-components/models/row-button';
import { User } from '../../domain/user';
import { FormTaskService } from '../../services/form-task.service';
import { DataStoreService } from '../../services/data-store.service';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { Observable } from 'rxjs';

@Component({
   selector: 'app-complete-form-dialog',
   templateUrl: './complete-form-dialog.component.html',
   styleUrls: ['./complete-form-dialog.component.scss']
})
export class CompleteFormDialogComponent implements OnInit {
   @Input() isVisible: boolean;
   @Input() isResend: boolean;
   @Input() formId: number;
   @Output() dialogClose = new EventEmitter();
   selectedUsers: User[];
   propertyUsers: User[];
   filteredUsers: User[];
   currentProperty: Property;
   buttons: RowButton[];
   isLoading: boolean;
   constructor(
      private propertyService: PropertyService,
      private taskService: TaskService,
      private userStore: UserStoreService,
      private propertyStore: PropertyStoreService,
      private messageService: MessageService,
      private formTaskService: FormTaskService,
      private dataStoreService: DataStoreService
   ) {
      this.isLoading = false;
      this.isVisible = false;
      this.isResend = false;
      this.formId = 0;
      this.currentProperty = new Property();
      this.selectedUsers = [];
      this.propertyUsers = [];
      this.filteredUsers = [];
      this.buttons = [
         new RowButton(
            'cancel',
            'Cancel',
            'fal fa-times',
            ['p-mr-2', 'p-mb-2'],
            ['p-col-5', 'p-text-left']
         ),
         new RowButton(
            'complete_form',
            'Complete',
            'fal fa-plus',
            ['p-mr-2', 'p-mb-2'],
            ['p-col-5', 'p-text-right']
         )
      ];
   }

   ngOnInit(): void {
      this.userStore.users.subscribe((users) => {
         this.propertyUsers = users;
      });
      this.propertyStore.currentProperty.subscribe((prop) => {
         this.currentProperty = prop;
      });
   }
   close(): void {
      this.isVisible = false;
      this.dialogClose.emit();
   }

   sendCompletionEmail(emailUser: number[], sendEmail: boolean): Observable<number> {
      return this.formTaskService.sendCompleteFormTaskInstance(
         this.formId,
         sendEmail,
         emailUser
      );
   }
   complete(): void {
      if (this.formId) {
         this.isLoading = true;
         const emailUser = this.selectedUsers.map((u) => u.id);
         const sendEmail = emailUser.length > 0;
         if (!this.isResend) {
            this.formTaskService
               .completeFormTaskInstance(this.formId, sendEmail, emailUser)
               .subscribe(() => {
                  if (sendEmail) {
                     this.sendCompletionEmail(emailUser, sendEmail).subscribe(() => {
                        this.messageService.add({
                           severity: 'success',
                           summary: 'Completion Form Email Sent Successfully',
                           detail: `${this.formId} Completion Email has been sent`
                        });
                     });
                  }
                  const refreshDate = new RefreshDataState();
                  refreshDate.ClaimedTasks = true;
                  refreshDate.UnclaimedTasks = true;
                  refreshDate.MyTasks = true;
                  refreshDate.MyTasksMonthly = true;
                  this.dataStoreService.refreshTaskData(refreshDate);
                  this.messageService.add({
                     severity: 'success',
                     summary: 'Completed Form Successfully',
                     detail: `${this.formId} has been completed`
                  });
                  this.isLoading = false;
                  this.close();
               });
         } else {
            if (sendEmail) {
               this.sendCompletionEmail(emailUser, sendEmail).subscribe(() => {
                  this.messageService.add({
                     severity: 'success',
                     summary: 'Completion Form Email Sent Successfully',
                     detail: `${this.formId} Completion Email has been sent`
                  });
                  this.isLoading = false;
                  this.close();
               });
            } else {
               this.isLoading = false;
               this.close();
            }
         }
      }
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'cancel':
            this.close();
            break;
         case 'complete_form':
            this.complete();
            break;
         default:
            break;
      }
   }

   filterUsers($event: any) {
      const filtered: User[] = [];
      const query = $event.query;

      for (let i = 0; i < this.propertyUsers.length; i++) {
         const user = this.propertyUsers[i];
         if (user.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(user);
         }
      }

      this.filteredUsers = filtered;
   }
}
