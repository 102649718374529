import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Property } from '../../domain/property';
import { PropertyService } from '../../services/property.service';

@Component({
   selector: 'app-all-properties-drop-down',
   templateUrl: './all-properties-drop-down.component.html',
   styleUrl: './all-properties-drop-down.component.css'
})
export class AllPropertiesDropDownComponent {
   @Input()
   label: string;
   properties: Property[];
   selectedProperty: Property | undefined;
   @Output() propertyChange: EventEmitter<Property> = new EventEmitter();

   constructor(private propertyService: PropertyService) {
      this.label = '';
      this.properties = [];
      this.propertyService.get(true, false).subscribe((properties) => {
         this.properties = properties;
      });
   }

   selectedPropertyChange($event: any) {
      if ($event.value) {
         this.propertyChange.emit($event.value);
      }
   }
}
