import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableParent } from '../parent/table-parent';
import { DatePipe } from '@angular/common';
import { Task } from '../../domain/task';
import { Router } from '@angular/router';
import { TaskUtils } from '../../util/task-utils';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';

@Component({
   selector: 'app-day-task-table',
   templateUrl: './day-task-table.component.html',
   styleUrls: ['./day-task-table.component.scss']
})
export class DayTaskTableComponent extends TableParent {
   selectedTasks: Task | undefined;
   @Input() taskList: Task[];

   @Output() dateChange: EventEmitter<number> = new EventEmitter();

   private selectedProperty: Property | undefined;

   constructor(
      datePipe: DatePipe,
      private router: Router,
      private propStore: PropertyStoreService
   ) {
      super(datePipe);
      propStore.currentProperty.subscribe((value) => {
         this.selectedProperty = value;
      });
      this.taskList = [];
   }

   async goToTask(taskData: Task, $event: MouseEvent): Promise<void> {
      if (this.selectedProperty) {
         $event.preventDefault();
         await TaskUtils.goToTask(taskData, this.router, this.selectedProperty);
      }
   }

   previousDay(): void {
      this.dateChange.emit(-1);
   }

   nextDay(): void {
      this.dateChange.emit(1);
   }
}
