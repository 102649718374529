import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { PortfolioReportRequest } from '../../../services/request/portfolio-report-request';
import { PortfolioReportRecord } from '../../../domain/portfolio-report-record';
import { PortfolioReportStoreService } from '../../../services/stores/portfolio-report-store.service';
import { Subject, Subscription } from 'rxjs';
import { PortfolioReportHistory } from '../../../domain/portfolio-report-history';
import { PropertyStoreService } from '../../../services/stores/property-store.service';
import { DropDownStoreService } from '../../../services/stores/drop-down-store.service';
import { Chart, ChartData, ChartOptions, registerables } from 'chart.js';
import { ReportRequest } from '../../../services/request/report-request';

Chart.register(...registerables);

@Component({
   selector: 'app-portfolio-report',
   templateUrl: './portfolio-report.component.html',
   styleUrls: ['./portfolio-report.component.scss']
})
export class PortfolioReportComponent implements OnInit, OnDestroy {
   isRunning = false;
   isSaving = false;
   portfolioReportForm: FormGroup;
   reportData: PortfolioReportRecord[] = [];
   minFilterDate: Date = DateTime.fromISO('2019-01-01').toJSDate();
   maxFilterDate: Date = DateTime.now().toJSDate();
   lastRequest?: PortfolioReportRequest;
   isHistoricalRun = false;
   hasRun = false;
   data: ChartData<'doughnut'> | undefined;
   options: ChartOptions<'doughnut'> | undefined;

   private historySubscription: Subscription = new Subscription();
   private portfolioReportHistoryData?: PortfolioReportHistory;
   private destroy$ = new Subject<void>();

   constructor(
      private _reportStore: PortfolioReportStoreService,
      private propStore: PropertyStoreService,
      private dropDownStore: DropDownStoreService,
      private fb: FormBuilder
   ) {
      this.portfolioReportForm = this.fb.group({
         dateRangeOption: [''],
         startDate: [''],
         finishDate: ['']
      });
   }

   ngOnInit(): void {
      this.initializeChart();

      // Subscribe to portfolio report history once during initialization
      this.historySubscription.add(
         this._reportStore.portfolioReportHistory.subscribe((data) => {
            this.isHistoricalRun = true;
            this.portfolioReportHistoryData = data;
            this.lastRequest = this.portfolioReportHistoryData.request;
            this.reportData = this.portfolioReportHistoryData.results;
            this.updateChartData(); // Update chart with new data
         })
      );
   }

   initializeChart(): void {
      this.data = {
         labels: ['Completed', 'Not Completed', 'Overdue'],
         datasets: [
            {
               data: [0, 0, 0], // Initialize with default values
               backgroundColor: ['#d8d8d8', '#ffd8b1', '#b1b5ff'],
               borderColor: ['#969696', '#ff8a1c', '#575cf4']
            }
         ]
      };

      this.options = {
         responsive: true,
         maintainAspectRatio: false,
         plugins: {
            datalabels: {
               display: (context) => {
                  return context.dataset.data[context.dataIndex] !== 0; // Hide if value is 0
               },
               color: (context: any) => {
                  return context.dataset.borderColor[context.dataIndex]; // Match segment color
               },
               font: {
                  weight: 'bold'
               }
            },
            legend: {
               position: 'right'
            },
            tooltip: {
               callbacks: {
                  label: (tooltipItem) => `${tooltipItem.label}: ${tooltipItem.raw}`
               }
            }
         }
      };
   }

   updateChartData(): void {
      const completed = this.reportData.reduce(
         (acc, item) => acc + item.completed,
         0
      );
      const notCompleted = this.reportData.reduce(
         (acc, item) => acc + item.notCompleted,
         0
      );
      const overdue = this.reportData.reduce((acc, item) => acc + item.overdue, 0);

      this.data = {
         labels: ['Completed', 'Not Completed', 'Overdue'],
         datasets: [
            {
               data: [completed, notCompleted, overdue],
               backgroundColor: ['#d8d8d8', '#ffd8b1', '#b1b5ff'],
               borderColor: ['#969696', '#ff8a1c', '#575cf4']
            }
         ]
      };
   }

   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   get dateRangeOption() {
      return this.portfolioReportForm.get('dateRangeOption')!;
   }

   get startDate() {
      return this.portfolioReportForm.get('startDate')!;
   }

   get finishDate() {
      return this.portfolioReportForm.get('finishDate')!;
   }

   runReport($event: MouseEvent): void {
      $event.preventDefault();
      this.isRunning = true;
      const request = new ReportRequest();
      switch (this.dateRangeOption.value) {
         case 'current_month':
         default:
            request.start = DateTime.local().startOf('month').toISODate();
            request.finish = DateTime.now().toISODate();
            break;
         case 'last_month':
            request.start = DateTime.now()
               .minus({ months: 1 })
               .startOf('month')
               .toISODate();
            request.finish = DateTime.now()
               .minus({ months: 1 })
               .endOf('month')
               .toISODate();
            break;
         case 'last_week':
            request.start = DateTime.now()
               .minus({ weeks: 1 })
               .startOf('week')
               .toISODate();
            request.finish = DateTime.now()
               .minus({ weeks: 1 })
               .endOf('week')
               .toISODate();
            break;
         case 'today':
            request.start = DateTime.now().startOf('day').toISODate();
            request.finish = DateTime.now().endOf('day').toISODate();
            break;
         case 'custom':
            request.start = DateTime.fromJSDate(this.startDate.value).toISODate();
            request.finish = DateTime.fromJSDate(this.finishDate.value).toISODate();
            break;
      }
      this._reportStore.runReportQuery(request).subscribe((records) => {
         this.reportData = records;
         this.updateChartData(); // Update chart with new data
         this.isRunning = false;
         this.hasRun = true;
      });
   }

   saveReportToFile($event: MouseEvent): void {
      $event.preventDefault();
      this.isSaving = true;
      // Implement save logic here
   }

   ngOnDestroy(): void {
      this.historySubscription.unsubscribe();
      this.destroy$.next();
      this.destroy$.complete();
   }
}
