import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RowButton } from '../models/row-button';

@Component({
   selector: 'app-table-heading-row',
   templateUrl: './table-heading-row.component.html',
   styleUrls: ['./table-heading-row.component.scss']
})
export class TableHeadingRowComponent {
   @Input()
   isLoading: boolean;

   @Input()
   hasButton: boolean;

   @Input()
   headingText: string;

   @Output()
   mainButtonClick = new EventEmitter();

   @Input()
   buttons: RowButton[];

   constructor() {
      this.isLoading = false;
      this.hasButton = true;
      this.headingText = '';
      this.buttons = [];
   }

   onClick($event: MouseEvent, id: string) {
      this.mainButtonClick.emit(id);
   }
}
