<div>
   <p-dropdown
      (onChange)="selectedGroupChange($event)"
      [(ngModel)]="selectedGroup"
      [options]="groups"
      [showClear]="false"
      name="selectedGroup"
      placeholder="Select a review group"
   >
      <ng-template pTemplate="selectedItem">
         <div *ngIf="selectedGroup">
            <div>
               {{ selectedGroup.displayName | trimString }}
            </div>
         </div>
      </ng-template>

      <ng-template let-group pTemplate="item">
         <div>
            <div>
               {{ group.displayName | trimString }}
            </div>
         </div>
      </ng-template>
   </p-dropdown>
</div>
