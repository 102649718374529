export class GroupsByTask {
   labels: string[];
   datasets: Dataset[];

   constructor() {
      this.labels = [];
      this.datasets = [];
   }
}

class Dataset {
   label: string;
   data: number[];
   backgroundColor: string;
   borderColor: string;
   borderWidth: number;
   barPercentage: number;
   maxBarThickness: number;
   borderSkipped: boolean;

   constructor() {
      this.label = '';
      this.data = [];
      this.backgroundColor = '';
      this.borderColor = '';
      this.borderWidth = 0;
      this.barPercentage = 0;
      this.maxBarThickness = 0;
      this.borderSkipped = true;
   }
}
