import { Component, OnInit } from '@angular/core';
import { UserPermissions } from '../../domain/security/user-permissions';
import { PermissionsService } from '../../services/permissions.service';
import { MessageService } from 'primeng/api';

@Component({
   selector: 'app-audit-basket-list',
   templateUrl: './audit-basket-list.component.html',
   styleUrls: ['./audit-basket-list.component.scss'],
   providers: [MessageService]
})
export class AuditBasketListComponent implements OnInit {
   userPermissions: Promise<UserPermissions> | undefined;

   constructor(
      public permissionService: PermissionsService,
      private service: MessageService
   ) {}

   ngOnInit(): void {
      this.userPermissions = this.permissionService.getPermission();
   }

   createNewAuditBasket() {
      this.userPermissions?.then((permissions) => {
         if (permissions != undefined && permissions.hasCreateAudit) {
            console.log('User has permissions');
         } else {
            this.service.add({
               key: 'tst',
               severity: 'error',
               summary: 'Access Exception',
               detail:
                  'You do not have the permissions to great a audit basket, please contact support if required'
            });
         }
      });
   }
}
