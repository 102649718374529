import { Component } from '@angular/core';
import { TaskUploadFile } from '../../domain/task-upload-file';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Task } from '../../domain/task';

@Component({
   selector: 'app-swap-upload-file-dialog',
   templateUrl: './swap-upload-file-dialog.component.html',
   styleUrls: ['./swap-upload-file-dialog.component.scss']
})
export class SwapUploadFileDialogComponent {
   selectedTask: Task;
   currentUploadFile: TaskUploadFile | undefined;
   taskUploadFiles: TaskUploadFile[];

   constructor(
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig
   ) {
      this.selectedTask = config.data.currentTask;
      this.taskUploadFiles = config.data.taskUploadFiles;
      for (const uploadFile of this.taskUploadFiles) {
         if (this.selectedTask.taskId === uploadFile.task?.taskId) {
            this.currentUploadFile = uploadFile;
         }
      }
   }

   onClose() {
      this.ref.close(this.currentUploadFile?.file);
   }

   getFileName(uploadFile: TaskUploadFile) {
      if (uploadFile.file) {
         return uploadFile.file.file.name;
      }
      return '';
   }

   hasTask(taskUploadFile: TaskUploadFile) {
      return taskUploadFile.task !== undefined;
   }
}
