export class GroupsByTaskTable {
   data: {
      group: string;
      timeToComplete: number;
      timeToCompletePrevious: number | string;
      total: number;
   }[];

   constructor() {
      this.data = [];
   }
}
