import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Comment } from '../../domain/comment';
import { DateTime } from 'luxon';

@Component({
   selector: 'app-task-comments',
   templateUrl: './task-comments.component.html',
   styleUrls: ['./task-comments.component.scss']
})
export class TaskCommentsComponent {
   @Input()
   comments: Array<Comment>;

   @Output()
   hideComments = new EventEmitter();

   constructor() {
      this.comments = [];
   }

   formatDate(dateString: string): string {
      const dateObject = DateTime.fromISO(dateString);
      if (dateObject.isValid) {
         return dateObject.toLocaleString(DateTime.DATETIME_MED);
      }
      return '';
   }
}
