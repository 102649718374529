import { Component, EventEmitter, Output } from '@angular/core';

@Component({
   selector: 'app-round-button-left',
   templateUrl: './round-button-left.component.html',
   styleUrl: './round-button-left.component.scss'
})
export class RoundButtonLeftComponent {
   @Output() LeftClick: EventEmitter<any> = new EventEmitter();

   constructor() {}

   leftClick() {
      this.LeftClick.emit();
   }
}
