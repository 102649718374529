<p-dialog
   [(visible)]="this.isVisible"
   [autoZIndex]="true"
   [baseZIndex]="90000"
   [closable]="false"
   [style]="{ width: '40rem' }"
   header="Add Comment"
>
   <div class="p-fluid">
      <div class="p-field">
         <div class="mb-2">
            <label for="mentions" class="font-size">Mention a colleague</label>
         </div>
         <p-autoComplete
            (completeMethod)="filterUsers($event)"
            [(ngModel)]="selectedUsers"
            [autoZIndex]="true"
            [baseZIndex]="100000"
            [dropdown]="true"
            [multiple]="true"
            [suggestions]="filteredUsers"
            field="fullName"
            inputId="mentions"
         >
         </p-autoComplete>
      </div>

      <div class="field mt-2">
         <div class="mb-2">
            <label for="icon" class="font-size"
               >Add a follow up date (optional)</label
            >
         </div>

         <p-calendar
            [(ngModel)]="followUpDate"
            [showIcon]="true"
            inputId="icon"
         ></p-calendar>
      </div>

      <div class="p-field">
         <div class="mb-2">
            <label for="float-input" class="font-size">Comment</label>
         </div>
         <textarea
            [(ngModel)]="commentText"
            class="col-12 overflow-hidden resize-handle"
            id="float-input"
            pInputTextarea
            rows="7"
         ></textarea>
      </div>
   </div>

   <div class="flex justify-content-between button-margin">
      <div>
         <button
            (click)="close()"
            class="p-button-raised w-fit"
            label="Close"
            pButton
            pRipple
            type="button"
         ></button>
      </div>

      <div>
         <button
            (click)="onSave()"
            class="p-button-raised w-fit"
            label="Save"
            pButton
            pRipple
            type="button"
         ></button>
      </div>
   </div>
</p-dialog>
