import { Component, OnInit } from '@angular/core';
import { Property } from '../../domain/property';
import { TaskService } from '../../services/task.service';
import { Task } from '../../domain/task';
import { MessageService } from 'primeng/api';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { WidgetType } from '../../services/util/widget-type';
import { WidgetStoreService } from '../../services/stores/widget-store.service';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { DataStoreService } from '../../services/data-store.service';
import { LoadingOverlayStoreService } from '../../services/stores/loading-overlay-store.service';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { UserPermissions } from '../../domain/security/user-permissions';
import { PermissionsService } from '../../services/permissions.service';
import { FormTaskService } from '../../services/form-task.service';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';
import { LoadingState } from '../../domain/loading-state';

@Component({
   selector: 'app-dashboard',
   templateUrl: './dashboard.component.html',
   styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
   properties: Property[];
   unclaimedTask: Task[];
   isLoadingTasks: boolean;
   isClaiming: boolean;
   currentlyVisibleWidgetPanel: WidgetType;
   isLoading: boolean;
   userPermissions: UserPermissions;
   currentProperty: Property | null;

   constructor(
      private taskService: TaskService,
      private messageService: MessageService,
      private propStore: PropertyStoreService,
      private widgetStore: WidgetStoreService,
      private workflowTaskStore: WorkflowTasksStoreService,
      private dataStoreService: DataStoreService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private formTaskService: FormTaskService,
      public permissionService: PermissionsService,
      private dropDownStore: DropDownStoreService
   ) {
      this.userPermissions = new UserPermissions();
      this.properties = [];
      this.unclaimedTask = [];
      this.isLoadingTasks = false;
      this.isClaiming = false;
      this.isLoading = true;
      this.currentProperty = null;
      this.currentlyVisibleWidgetPanel = WidgetType.UnclaimedTasks;
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this.propStore.currentProperty.subscribe((value) => {
         this.currentProperty = value;
      });
      this.workflowTaskStore.unclaimedTasks.subscribe((value) => {
         this.unclaimedTask = this.filterTasks(value);
      });
      this.widgetStore.currentVisibleWidgetPanel.subscribe((widget) => {
         if (widget.type != this.currentlyVisibleWidgetPanel) {
            this.currentlyVisibleWidgetPanel = widget.type;
         }
      });
      this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
         this.isLoading = !(
            !loadingState.UnclaimedTasks &&
            !loadingState.Mentions &&
            !loadingState.FollowUps &&
            !loadingState.AllTasks &&
            !loadingState.MyAssigned
         );
      });
      this.updateDropDownState(false);
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   claimTasks(taskList: Task[]) {
      if (this.currentProperty) {
         this.isClaiming = true;
         const loadingState = new LoadingState();
         loadingState.UnclaimedTasks = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         const taskIds: number[] = [];
         for (const task of taskList) {
            if (task.type == WorkflowType.Form && task.taskId) {
               this.formTaskService
                  .claimAssignmentInstance(task.taskId)
                  .subscribe((amount) => {
                     console.log(`Claimed ${amount} form tasks`);
                  });
            } else {
               if (this.currentProperty.isPhobosEnabled) {
                  if (task.taskId && task.taskId > 0) {
                     taskIds.push(task.taskId);
                  }
               } else {
                  if (task.processId && task.processId > 0) {
                     taskIds.push(task.processId);
                  }
               }
            }
         }
         this.taskService.claimTasks(taskIds, this.currentProperty?.id).subscribe(
            () => {
               //Remove the claimed tasks from the current list
               this.unclaimedTask = this.unclaimedTask.filter(
                  (obj) => !taskIds.includes(obj.processId)
               );
               this.isClaiming = false;
               const loadingState = new LoadingState();
               loadingState.UnclaimedTasks = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
               this.messageService.add({
                  severity: 'success',
                  summary: 'Tasks Successfully Claimed',
                  detail: `${taskList.length} tasks claimed`
               });
            },
            (error) => {
               console.log(error);
               this.messageService.add({
                  severity: 'error',
                  summary: 'Unable to claim Tasks',
                  detail: `There was a Error claiming ${taskList.length} tasks`
               });
               this.isClaiming = false;
            },
            () => {
               this.isClaiming = false;
               const refreshDate = new RefreshDataState();
               refreshDate.ClaimedTasks = true;
               refreshDate.UnclaimedTasks = true;
               refreshDate.AllPropertyTasks = true;
               refreshDate.MyTasksMonthly = true;
               refreshDate.AllTasksMonthly = true;
               this.dataStoreService.refreshTaskData(refreshDate);
            }
         );
      }
   }

   isPanelVisible(widget: WidgetType) {
      return widget == this.currentlyVisibleWidgetPanel;
   }

   private filterTasks(tasks: Task[]): Task[] {
      if (!this.userPermissions.forms.read) {
         return tasks.filter((f) => {
            return f.type != WorkflowType.Form;
         });
      }
      return tasks;
   }
}
