import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserPermissions } from '../domain/security/user-permissions';
import { environment } from '../../environments/environment';

@Injectable({
   providedIn: 'root'
})
export class PermissionsService {
   private jwtHelper: JwtHelperService;

   constructor(private auth: AuthService) {
      this.jwtHelper = new JwtHelperService();
   }

   async getPermission(): Promise<UserPermissions> {
      const permissionsArray = await this.getPermissions();
      const rolesArray = await this.getRoles();
      const userPermissions = new UserPermissions();
      userPermissions.hasAudit = permissionsArray.includes('read:audit-bucket');
      userPermissions.hasCreateAudit =
         permissionsArray.includes('create:audit-bucket');
      userPermissions.hasWorkflow = permissionsArray.includes('workflow:general');
      userPermissions.hasWorkflowRun = permissionsArray.includes('workflow:launch');
      userPermissions.isPropAdmin =
         rolesArray.includes('prop_admin') || rolesArray.includes('account_owner');
      userPermissions.updatePermissions(permissionsArray);
      return userPermissions;
   }

   private async getPermissions() {
      let permissionsArray = localStorage.getItem('permissions');
      if (localStorage.getItem('permissions') == null) {
         await this.getTokenData();
         if (localStorage.getItem('permissions') != null) {
            permissionsArray = localStorage.getItem('permissions');
         }
      }
      if (!permissionsArray) {
         console.error('Unable to get permissions data');
         permissionsArray = '';
      }
      return permissionsArray;
   }

   private async getTokenData() {
      if (this.auth.isAuthenticated$) {
         const token = await this.auth
            .getAccessTokenSilently({
               authorizationParams: {
                  audience: environment.auth.audience
               }
            })
            .toPromise();
         if (token) {
            const tokenJson = this.jwtHelper.decodeToken(token);
            localStorage.setItem('permissions', tokenJson.permissions);
            localStorage.setItem('roles', tokenJson.tyallsRoles);
         }
      }
   }
   private async getRoles() {
      let roleArray = localStorage.getItem('roles');
      if (localStorage.getItem('roles') == null) {
         await this.getTokenData();
         if (localStorage.getItem('roles') != null) {
            roleArray = localStorage.getItem('roles');
         }
      }
      if (!roleArray) {
         console.error('Unable to get permissions data');
         roleArray = '';
      }
      return roleArray;
   }
}
