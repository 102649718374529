import { Component, EventEmitter, Input, Output } from '@angular/core';
import { WorkflowFile } from '../../../domain/workflow-file';

@Component({
   selector: 'app-dropdown-menu',
   templateUrl: './dropdown-menu.component.html',
   styleUrls: ['./dropdown-menu.component.scss']
})
export class DropdownMenuComponent {
   @Input() items: WorkflowFile[] = [];
   @Input() isVisible: boolean = false;
   @Output() setCurrentSupportingFileFromDropdown: EventEmitter<number> =
      new EventEmitter<number>();

   constructor() {}

   selectItem(i: number) {
      this.setCurrentSupportingFileFromDropdown.emit(i);
   }
}
