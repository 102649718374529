<div class="p-grid container">
	<div class="card pb-6">

		<div id="mention_table">
			<div class="tyalls-table-name mb-3 flex justify-content-between pr-4">
				<h6 class="tyalls-table-heading">{{ currentProperty?.name }}</h6>
				<h6 class="tyalls-table-heading">Staff <span class="font-normal">- {{ selectedTask }}</span></h6>
			</div>
		</div>

		<form #formDir="ngForm" [formGroup]="groupsForm">
			<div class="p-field xl:flex justify-content-between mb-4 text-sm px-1">
				<div class="flex justify-content-between w-full">
					<div class="flex gap-2">
						<!-- Radio Buttons for Date Range -->
						<div class="p-field-radiobutton task-canvas mt-2 flex">
							<p-radioButton
								formControlName="dateRangeOption"
								inputId="current_month"
								name="dateRangeOption"
								value="current_month"
								class="mr-2 cursor-pointer"
								(click)="onDateRangeChange('current_month')"
							></p-radioButton>
							<div class="mr-1 content-center">
								<label class="cursor-pointer" for="current_month">Current Month</label>
							</div>
						</div>

						<div class="p-field-radiobutton task-canvas mt-2 flex">
							<p-radioButton
								formControlName="dateRangeOption"
								inputId="last_month"
								name="dateRangeOption"
								value="last_month"
								class="mr-2 cursor-pointer"
								(click)="onDateRangeChange('last_month')"
							></p-radioButton>
							<div class="mr-1 content-center">
								<label class="cursor-pointer" for="last_month">Last Month</label>
							</div>
						</div>

						<div class="p-field-radiobutton task-canvas mt-2 flex">
							<p-radioButton
								formControlName="dateRangeOption"
								inputId="last_week"
								name="dateRangeOption"
								value="last_week"
								class="mr-2 cursor-pointer"
								(click)="onDateRangeChange('last_week')"
							></p-radioButton>
							<div class="mr-1 content-center">
								<label class="cursor-pointer" for="last_week">Last Week</label>
							</div>
						</div>

						<div class="p-field-radiobutton task-canvas mt-2 flex">
							<p-radioButton
								formControlName="dateRangeOption"
								inputId="today"
								name="dateRangeOption"
								value="today"
								class="mr-2 cursor-pointer"
								(click)="onDateRangeChange('today')"
							></p-radioButton>
							<div class="mr-1 content-center">
								<label class="cursor-pointer" for="today">Today</label>
							</div>
						</div>
					</div>

					<div class="w-13rem mt-2">
						<p-dropdown
							[options]="groupViewOptions"
							optionLabel="title"
							placeholder="Select a Task"
							(onChange)="onTaskChange($event)"
						></p-dropdown>
					</div>
				</div>
			</div>
		</form>

		<!-- Chart -->
		<div class="flex px-6 gap-2">
			<div class="w-full">
				<div class="px-6 pt-4">
					<p-chart type="bar" [data]="data" [options]="options"></p-chart>
				</div>
			</div>
		</div>

		<form [formGroup]="previousForm">
			<div *ngIf="selectedTask !== null && dateRange !== ''" class="p-field-checkbox w-8rem text-sm flex ml-8">
				<p-checkbox
					formControlName="dateRangeOption"
					inputId="previous"
					name="dateRangeOption"
					value="previous"
					binary="true"
					class="mr-2 cursor-pointer"
					(onChange)="onPreviousChange()"
				></p-checkbox>
				<div class="mr-2 content-center">
					<label class="cursor-pointer" for="previous">Previous</label>
				</div>
			</div>
		</form>

		<!--		Data Table-->
		<div
			*ngIf="selectedTask !== null && dateRange !== ''"
			class="p-6">
			<p-table
				[value]="tableData.data"
				[resizableColumns]="true"
				styleClass="p-datatable-gridlines"
			>
				<ng-template pTemplate="header">
					<tr>
						<th class="text-sm" pResizableColumn>Group</th>
						<th class="text-center text-sm" pResizableColumn>Time to Complete</th>
						<th class="text-center text-sm" pResizableColumn>Time to Complete (Previous)</th>
						<th class="text-center text-sm" pResizableColumn>Total</th>
					</tr>
				</ng-template>

				<ng-template pTemplate="body" let-item>
					<tr>
						<td class="text-sm">{{ item.group }}</td>
						<td class="text-center text-sm">{{ item.timeToComplete }}</td>
						<td class="text-center text-sm">{{ item.timeToCompletePrevious }}</td>
						<td class="text-center text-sm">{{ item.total }}</td>
					</tr>
				</ng-template>

			</p-table>
		</div>

		<!-- AI Recommendations -->
		<div *ngIf="selectedTasks.datasets.length !== 0" class="px-6 gap-2 flex">
			<div (click)="showAiResponse()" class="header-ai border-round-lg cursor-pointer">
				<i
					class="fa-sharp fa-solid fa-chevron-down"
					[ngClass]="aiResponse ? 'rotate-180' : 'rotate-0'"
					style="color: #47a5de;"
				></i>
			</div>

			<div (click)="showAiResponse()"
					 class="expand-ai cursor-pointer w-full border-round-lg px-4 justify-content-between">
				<div class="flex justify-content-between">
					<div class="ai-text h-2 font-bold">Recommendations</div>
					<div class="ai-badge border-round-md px-2 font-bold">AI</div>
				</div>

				<div class="pr-2 pl-0" [@aiResponseAnimation]="aiResponse ? 'open' : 'closed'">
					<ol class="text-sm ai-text pt-4">
						<li class="line-height-3 mb-2">
							Car park occupancy has the highest time to claim (81 minutes) but the lowest time to review (19
							minutes).
							This might indicate inefficiencies in the claiming process.
						</li>
						<li class="line-height-3 mb-2">
							Minibar checks have a relatively low time to claim (56 minutes) but the highest time to review (86
							minutes).
							This could suggest bottlenecks in the review process.
						</li>
						<li class="line-height-3 mb-2">
							Daily rate check and Expense claim have moderate times for both claim and review, suggesting a balanced
							process.
						</li>
						<li class="line-height-3 mb-2">
							Vacant rooms have high times for both claim and review, indicating potential overall inefficiency.
						</li>
						<li class="line-height-3">
							Toilet checks have the lowest time to claim (55 minutes) and a relatively low time to review (27
							minutes),
							suggesting an efficient process.
						</li>
					</ol>
				</div>
			</div>
		</div>
	</div>
</div>

