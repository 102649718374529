export class RunRequest {
   workflowId: number;
   propertyId: number | undefined;
   runDate: string | null = null;

   constructor() {
      this.workflowId = 0;
      this.propertyId = 0;
   }
}
