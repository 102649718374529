import { Annotation } from './annotation';

export class WorkflowFile {
   id?: number;
   data: string;
   fileName?: string;
   source?: string;
   hash?: string;
   storageId?: string;
   documentId?: number;
   createdBy?: string;
   createdDate?: Date;
   lastModifiedBy?: string;
   lastModifiedDate?: Date;
   isPrimary?: boolean;
   type?: number;
   annotations: Array<Annotation>;
   mimeType: string;
   fileId?: number;

   constructor() {
      this.mimeType = 'application/pdf';
      this.data = '';
      this.annotations = new Array<Annotation>();
   }
}
