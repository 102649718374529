import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   ViewChild
} from '@angular/core';
import { DateTime } from 'luxon';
import { Task } from '../../domain/task';
import { Comment } from '../../domain/comment';
import { TaskService } from '../../services/task.service';
import { Assignment } from '../../domain/assignment';
import { RowButton } from '../models/row-button';
import { MessageService } from 'primeng/api';
import { WorkflowFile } from '../../domain/workflow-file';
import { HttpErrorResponse } from '@angular/common/http';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { FormTaskService } from '../../services/form-task.service';
import { DatePropertyTimezonePipe } from '../../pipes/date-property-timezone.pipe';
import { UploadFile } from '../../domain/uploadfile';
import { FileUpload } from 'primeng/fileupload';

@Component({
   selector: 'app-document-side-panel',
   templateUrl: './document-side-panel.component.html',
   styleUrls: ['./document-side-panel.component.scss']
})
export class DocumentSidePanelComponent implements OnChanges, OnInit {
   @ViewChild('subFileUploadForm')
   uploadForm: FileUpload | undefined;

   @Input() currentTask: Task;

   @Input() isPhobos: boolean = false;

   @Input() hasPrimaryDocument: boolean;

   @Output() addComment: EventEmitter<any> = new EventEmitter();

   comments: Comment[];
   assignments: Assignment[];
   buttons: RowButton[];
   currentSecondaryFile?: WorkflowFile;
   secondaryPdfContainer: string;
   supportingFiles: WorkflowFile[];
   supportingFilesIndex: number;
   uploadedFiles: UploadFile[] = [];
   commentsView: boolean;
   supportingFilesView: boolean;
   detailsView: boolean;
   showPanelPadding: string;
   isDropdownVisible: boolean = false;
   protected readonly DatePropertyTimezonePipe = DatePropertyTimezonePipe;

   constructor(
      private taskService: TaskService,
      private messageService: MessageService,
      private formTaskService: FormTaskService
   ) {
      this.currentTask = new Task();
      this.comments = [];
      this.assignments = [];
      this.supportingFiles = [];
      this.hasPrimaryDocument = false;
      this.currentSecondaryFile = new WorkflowFile();
      this.secondaryPdfContainer = 'sub-file-container';
      this.buttons = [
         new RowButton(
            'add_comment',
            'Add Comment',
            'fal fa-plus',
            ['p-button-rounded', 'p-mr-2', 'p-mb-2'],
            ['p-col', 'p-text-right']
         )
      ];
      this.commentsView = true;
      this.supportingFilesView = false;
      this.detailsView = false;
      this.supportingFilesIndex = 0;
      this.showPanelPadding = 'h-1rem';
   }

   get panelHeading(): string {
      if (this.supportingFilesView) {
         if (this.currentSecondaryFile) {
            return `Supporting File:  ${this.currentSecondaryFile.fileName}`;
         }
         return 'Supporting Files';
      } else if (this.detailsView) {
         return 'Details';
      }
      return 'Comments';
   }

   ngOnInit(): void {
      this.getAssignments();
      this.getComments();
      this.getSupportingFiles();
   }

   ngOnChanges(): void {
      this.showCommentsView();
      this.clear();
      this.getAssignments();
      this.getComments();
      this.getSupportingFiles();
   }

   toggleDropdown(): void {
      if (this.supportingFiles.length !== 0) {
         this.isDropdownVisible = !this.isDropdownVisible;
      }
   }

   getSupportingFiles() {
      // Resets dropdown when user scrolls through tasks in task header.
      this.isDropdownVisible = false;
      if (this.currentTask.processId) {
         if (this.isPhobos || this.currentTask.type == WorkflowType.Form) {
            this.formTaskService
               .getSupportingFilesByFormTaskInstance(this.currentTask.processId)
               .subscribe(
                  (files) => {
                     this.supportingFiles = files;
                     if (this.supportingFiles.length > 0) {
                        this.currentSecondaryFile =
                           this.supportingFiles[this.supportingFilesIndex];
                     }
                  },
                  (e: HttpErrorResponse) => {
                     console.error(
                        `Unable to load supporting files ${this.currentTask.processId}`,
                        e
                     );
                  }
               );
         } else {
            this.taskService
               .getSupportingFilesByProcessId(this.currentTask.processId)
               .subscribe(
                  (files) => {
                     this.supportingFiles = files;
                     if (this.supportingFiles.length > 0) {
                        this.currentSecondaryFile =
                           this.supportingFiles[this.supportingFilesIndex];
                     }
                  },
                  (e: HttpErrorResponse) => {
                     console.error(
                        `Unable to load supporting files ${this.currentTask.processId}`,
                        e
                     );
                  }
               );
         }
      }
   }

   getComments() {
      if (this.currentTask.processId) {
         switch (this.currentTask.type) {
            case WorkflowType.Form:
               this.formTaskService
                  .getCommentsForFormTaskInstance(this.currentTask.processId)
                  .subscribe((comments: Comment[]) => {
                     this.comments = comments;
                  });
               break;
            default:
               this.taskService
                  .getCommentsForWorkflowByProcessId(this.currentTask.processId)
                  .subscribe((comments: Comment[]) => {
                     this.comments = comments;
                  });
               break;
         }
      }
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'supporting_doc':
            break;
         case 'add_comment':
            this.addComment.emit();
            break;
         default:
            break;
      }
   }

   getAssignments() {
      if (this.currentTask.processId) {
         if (this.isPhobos || this.currentTask.type == WorkflowType.Form) {
            this.formTaskService
               .getFormTaskInstanceAssignments(this.currentTask.processId)
               .subscribe((fts) => {
                  this.assignments = fts.map((fta) => {
                     const assignmentResult = new Assignment();
                     assignmentResult.id = fta.id;
                     assignmentResult.assignee = `${fta.claimedBy?.firstName} ${fta.claimedBy?.lastName}`;
                     assignmentResult.workflowGroup = fta.group?.displayName ?? '';
                     assignmentResult.displayName = fta.group?.displayName ?? '';
                     assignmentResult.assigneeDisplay = fta.claimedBy
                        ? fta.claimedBy.email
                        : (fta.group?.displayName ?? '');
                     assignmentResult.finishDateTime = fta.completedDate;
                     assignmentResult.startDateTime = fta.createdDate;
                     assignmentResult.claimDateTime = fta.claimedDate;
                     return assignmentResult;
                  });
                  this.assignments = this.sortedAssignments(this.assignments);
               });
         } else {
            this.taskService
               .getAssignments(this.currentTask.processId)
               .subscribe((assignments) => {
                  const results = assignments.filter((assignment) => {
                     if (assignment.finishDateTime) {
                        const regexp = new RegExp(
                           /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        );
                        return regexp.test(assignment.assignee);
                     }
                     return false;
                  });
                  this.assignments = this.sortedAssignments(results);
               });
         }
      }
   }

   sortedAssignments(assignments: Assignment[]): Assignment[] {
      assignments.sort((a, b) => {
         const aDateTime = DateTime.fromSQL(a.finishDateTime);
         const bDateTime = DateTime.fromSQL(b.finishDateTime);
         if (aDateTime < bDateTime) {
            return -1;
         }
         if (aDateTime > bDateTime) {
            return 1;
         }
         return 0;
      });
      return assignments;
   }

   clear(): void {
      this.currentSecondaryFile = undefined;
      this.supportingFilesIndex = 0;
      this.comments = [];
      this.supportingFiles = [];
      this.assignments = [];
      this.uploadForm?.clear();
   }

   onUploadSupportDoc($event: any) {
      if ($event.files) {
         const uploadUserMessage = () => {
            this.messageService.add({
               severity: 'success',
               summary: 'Supporting File Uploaded for Task',
               detail: `${this.currentTask.processId} file has been uploaded`
            });
         };
         for (const file of $event.files) {
            if (this.isPhobos || this.currentTask.type == WorkflowType.Form) {
               this.formTaskService
                  .uploadSupportingFile(this.currentTask.processId, file)
                  .subscribe((files) => {
                     this.supportingFiles = files;
                     uploadUserMessage();
                     this.setCurrentSupportingFile(true);
                     this.uploadForm?.clear();
                  });
            } else {
               this.taskService
                  .uploadSupportingFile(this.currentTask.processId, file)
                  .subscribe((files) => {
                     this.supportingFiles = files;
                     uploadUserMessage();
                     this.setCurrentSupportingFile(true);
                     this.uploadForm?.clear();
                  });
            }
         }
      }
   }

   setCurrentSupportingFile(reset: boolean = false) {
      if (reset) {
         this.supportingFilesIndex = 0;
      }
      this.currentSecondaryFile = this.supportingFiles[this.supportingFilesIndex];
   }

   setCurrentSupportingFileFromDropdown(i: number) {
      this.showSupportingFilesView();
      this.supportingFilesIndex = i;
      this.setCurrentSupportingFile();
      this.isDropdownVisible = false;
   }

   showCommentsView() {
      this.commentsView = true;
      this.supportingFilesView = false;
      this.detailsView = false;
   }

   showSupportingFilesView() {
      this.commentsView = false;
      this.supportingFilesView = true;
      this.detailsView = false;

      // Load initial supporting file by index.
      if (this.supportingFiles.length > 0) {
         this.setCurrentSupportingFile();
      }
   }

   showDetailsView() {
      this.commentsView = false;
      this.supportingFilesView = false;
      this.detailsView = true;
   }
}
