<ng-container *ngIf="item?.visible">
	<li class="my-1">
		<a
			(click)="itemClick($event)"
			(mouseenter)="onMouseEnter()"
			*ngIf="item?.routerLink && !item?.items"
			[attr.tabindex]="0"
			[attr.target]="item?.target"
			[ngClass]="item?.class"
			[routerLinkActiveOptions]="{ exact: true }"
			[routerLink]="item?.routerLink"
			pRipple
			routerLinkActive="active-menuitem-routerlink"
			class="menu-spacing"
		>
			<i [ngClass]="item?.icon" class="icon-size"></i>
			<span class="menu-text">{{ item?.label }}</span>
		</a>
		<div class="layout-menu-tooltip">
			<div class="layout-menu-tooltip-arrow"></div>
			<div class="layout-menu-tooltip-text">{{ item?.label }}</div>
		</div>
	</li>
</ng-container>
