import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnDestroy,
   Output
} from '@angular/core';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';
import { Subscription } from 'rxjs';

@Component({
   selector: 'app-current-property-drop-down',
   templateUrl: './current-property-drop-down.component.html',
   styleUrls: ['./current-property-drop-down.component.css']
})
export class CurrentPropertyDropDownComponent implements OnChanges, OnDestroy {
   propertiesItems: Property[];

   selectedProperty: Property | undefined;

   @Input() properties: Property[];

   @Output() propertyChange: EventEmitter<Property> = new EventEmitter();

   propPanelTextLeft: string;
   propPanelTextRight: string;
   private dropDownStateSubscription: Subscription;
   isDropDownDisabled: boolean = false;
   isDropDownHidden: boolean = false;

   constructor(
      private propStore: PropertyStoreService,
      private dropDownStore: DropDownStoreService
   ) {
      this.propertiesItems = [];
      this.properties = [];
      this.propPanelTextLeft = '';
      this.propPanelTextRight = '';
      this.dropDownStateSubscription = this.dropDownStore.dropDownState$.subscribe(
         (state: any) => {
            this.isDropDownDisabled = state;
         }
      );
      propStore.properties.subscribe((props) => {
         this.properties = props;
         if (this.properties != undefined) {
            if (this.properties.length == 1) {
               // this.isDropDownDisabled = true;

               this.isDropDownHidden = true;
               this.propStore.setCurrentProperty(this.properties[0]);
            } else {
               this.propertiesItems = this.properties;
            }
         }
      });

      propStore.currentProperty.subscribe((value) => {
         this.selectedProperty = value;
         this.propPanelTextLeft = `Welcome! ${this.selectedProperty.name}`;
      });
   }

   ngOnChanges(): void {
      this.dropDownStateSubscription = this.dropDownStore.dropDownState$.subscribe(
         (state) => {
            console.log(state);
            this.isDropDownDisabled = state;
         }
      );
   }

   ngOnDestroy(): void {
      if (this.dropDownStateSubscription) {
         this.dropDownStateSubscription.unsubscribe();
      }
   }

   selectedPropertyChange($event: { value: Property }) {
      if ($event.value) {
         this.propStore.setCurrentProperty($event.value);
      }
   }
}
