import {
   Component,
   Input,
   OnChanges,
   OnInit,
   SimpleChanges,
   ViewChild
} from '@angular/core';
import { RowButton } from '../models/row-button';
import { User } from '../../domain/user';
import { UserStoreService } from '../../services/stores/user-store.service';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { Role } from '../../domain/role';
import { Group } from '../../domain/group';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Property } from '../../domain/property';
import { UserService } from '../../services/user.service';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { DataStoreService } from '../../services/data-store.service';
import { PermissionsService } from '../../services/permissions.service';
import { UserPermissions } from '../../domain/security/user-permissions';
import { PropertyService } from '../../services/property.service';
import { GroupService } from '../../services/group.service';
import { TaskService } from '../../services/task.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { saveAs } from 'file-saver';

@Component({
   selector: 'app-user-table',
   templateUrl: './user-table.component.html',
   styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit, OnChanges {
   users: User[];
   selectedUsers: User[];
   isCurrentlyVisible: boolean;
   isLoading: boolean;
   headingButtons: RowButton[];
   firstRow: number;
   roles: Role[];
   selectedRole: Role | undefined;
   groups: Group[];
   availableGroups: Group[];
   selectedGroup: Group | undefined;
   currentUser: User | undefined;
   @Input()
   currentProperty: Property | undefined;
   @ViewChild('roleOverlay') roleOverlay: OverlayPanel | undefined;
   @ViewChild('groupOverlay') groupOverlay: OverlayPanel | undefined;
   //@Output() addUserDialogOpen = new EventEmitter();
   userPermissions: UserPermissions;
   isAddUserDialogVisible: boolean = false;

   constructor(
      private userStore: UserStoreService,
      private userService: UserService,
      private taskService: TaskService,
      private propStore: PropertyStoreService,
      private propertyService: PropertyService,
      private dataStoreService: DataStoreService,
      public permissionService: PermissionsService,
      private groupService: GroupService,
      private messageService: MessageService,
      private confirmationService: ConfirmationService,
      private workflowTasksStore: WorkflowTasksStoreService
   ) {
      this.currentProperty = undefined;
      this.isLoading = false;
      this.firstRow = 0;
      this.users = [];
      this.roles = [];
      this.groups = [];
      this.availableGroups = [];
      this.selectedUsers = [];
      this.isCurrentlyVisible = true;
      this.headingButtons = [];

      this.userPermissions = new UserPermissions();
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this.headingButtons.forEach(
         (x) => (x.isVisible = this.userPermissions.user.create)
      );
   }

   removeBeforeFirstUnderscore(name: string): string {
      const parts = name.split('_');
      if (parts.length > 1) {
         return parts.slice(1).join('_');
      }
      return name;
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'add_user':
            this.isAddUserDialogVisible = true;
            break;
         case 'download_users':
            this.downloadUsersToFile();
            break;
         default:
            break;
      }
   }

   onAddUserDialogClose() {
      this.isAddUserDialogVisible = false;
   }

   onUserAdded() {
      this.refreshUserList();
   }

   toggleAccess(user: User, isEnabled: boolean) {
      if (this.currentProperty) {
         if (!isEnabled) {
            this.confirmationService.confirm({
               message: `You are about to remove access for ${user.username}. This will remove them from this property. Are you sure?`,
               header: 'Confirm Remove Access',
               icon: 'pi pi-exclamation-triangle',
               acceptLabel: 'Yes, Remove Access',
               rejectLabel: 'No, Go Back',
               accept: () => {
                  this.userService
                     .removeUserFromProperty(user.authId, this.currentProperty!.id)
                     .subscribe({
                        next: () => {
                           this.users = this.users.filter(
                              (u) => u.authId !== user.authId
                           );
                           this.selectedUsers = this.selectedUsers.filter(
                              (u) => u.authId !== user.authId
                           );
                           const refreshDataStore = new RefreshDataState();
                           refreshDataStore.Users = true;
                           this.dataStoreService.refreshTaskData(refreshDataStore);

                           this.messageService.add({
                              severity: 'success',
                              summary: 'User Access Removed',
                              detail: `${user.username}'s access has been removed from ${this.currentProperty?.name}`
                           });
                        },
                        error: (err) => {
                           console.error('Failed to remove user access:', err);
                           this.messageService.add({
                              severity: 'error',
                              summary: 'Error',
                              detail: `Failed to remove ${user.username}'s access`
                           });
                        }
                     });
               },
               reject: () => {
                  console.log('User canceled removing access');
               }
            });
         }
      }
   }

   addRole() {
      if (this.currentUser && this.selectedRole && this.currentProperty) {
         this.userService
            .addRole(this.currentUser?.authId, this.currentProperty.id, [
               this.selectedRole
            ])
            .subscribe((u) => {
               this.updateUser(u);
               this.roleOverlay?.hide(); // Close the role overlay panel
            });
      }
   }

   addGroup() {
      if (this.currentUser && this.selectedGroup && this.currentProperty) {
         this.userService
            .addGroup(this.currentUser?.authId, this.currentProperty.id, [
               this.selectedGroup
            ])
            .subscribe((u) => {
               this.updateUser(u);
               if (this.selectedGroup) {
                  this.selectedGroup.isDisable = true;
               }
               this.updateAvailableGroups();
               this.groupOverlay?.hide(); // Close the group overlay panel
            });
      }
   }

   updateUser(passedUser: User) {
      const objIndex = this.users.findIndex(
         (obj) => obj.authId === passedUser.authId
      );
      if (objIndex > -1) {
         const user = this.users[objIndex];
         user.isEnabled = passedUser.isEnabled;
         user.groups = passedUser.groups;
         user.roles = passedUser.roles;
         this.users[objIndex] = user;
      }
   }

   openDialog(user: User, addRole: boolean, addGroup: boolean, $event: any) {
      this.currentUser = user;
      if (addRole) {
         this.roleOverlay?.show($event);
      } else if (addGroup) {
         this.filterGroupsForUser();
         this.groupOverlay?.show($event);
      }
   }

   removeGroup(group: Group, userId: string) {
      if (userId && group && this.currentProperty) {
         this.userService
            .deleteGroup(userId, this.currentProperty?.id, group)
            .subscribe((u) => {
               this.updateUser(u);
               const foundGroup = this.availableGroups.find(
                  (group) => group.id === group.id
               );
               if (foundGroup) {
                  foundGroup.isDisable = false;
               }
            });
      }
   }

   removeRole(role: Role, userId: string) {
      if (userId && role && this.currentProperty) {
         this.userService
            .deleteRole(userId, this.currentProperty.id, role)
            .subscribe((u) => {
               this.updateUser(u);
            });
      }
   }

   hasNoUsers(): boolean {
      return !this.isLoading && this.users.length === 0;
   }

   private updateAvailableGroups() {
      //deep copy the array so we don't end up with issues later
      this.availableGroups = this.groups.map((obj) => ({ ...obj }));
   }

   filterGroupsForUser() {
      if (this.currentUser) {
         this.updateAvailableGroups();
         for (const selectedGroup of this.currentUser.groups) {
            const foundGroup = this.availableGroups.find(
               (group) => group.id === selectedGroup.id
            );
            if (foundGroup) {
               foundGroup.isDisable = true;
            }
         }
      }
   }

   refreshUserList(): void {
      if (!this.currentProperty) {
         console.error('No property selected for refreshing user list');
         return;
      }

      this.isLoading = true;

      this.propertyService.getUsers(this.currentProperty.id).subscribe({
         next: (users) => {
            this.users = users.filter((user) => user.isEnabled);
            this.firstRow = 0;
            console.log(
               `Refreshed user list for property ${this.currentProperty!.id}`
            );
         },
         error: (error) => {
            console.error(`Failed to refresh users:`, error);
         },
         complete: () => {
            this.isLoading = false; // <== Hide spinner
         }
      });
   }

   confirmDeclaimTasks(user: User) {
      this.confirmationService.confirm({
         message: `You are about to de-claim ${user.outstandingTaskCount} tasks from ${user.username}. Are you sure?`,
         header: 'Confirm De-Claim',
         icon: 'pi pi-exclamation-triangle',
         acceptLabel: 'Yes, De-Claim',
         rejectLabel: 'No, Go Back',
         accept: () => {
            this.declaimOutstandingUserTasks(user);
         },
         reject: () => {
            console.log('User canceled');
         }
      });
   }
   declaimOutstandingUserTasks(user: User): void {
      if (!this.currentProperty) {
         console.error('No property selected');
         return;
      }

      console.log('User Ids', user.id);

      this.isLoading = true;

      this.taskService.declaimTasks(user.id, this.currentProperty.id).subscribe({
         next: (response) => {
            console.log(
               `Successfully declaimed tasks for user ${user.username}:`,
               response.declaimedTaskCount
            );

            this.messageService.add({
               severity: 'success',
               summary: 'Tasks De-Claimed',
               detail: `${response.declaimedTaskCount} tasks were successfully declaimed for ${user.username}`
            });

            this.refreshUserList();
            this.workflowTasksStore.refreshAllTasks();
         },
         error: (error) => {
            console.error(
               `Failed to declaim tasks for user ${user.username}:`,
               error
            );
            this.messageService.add({
               severity: 'error',
               summary: 'Failed',
               detail: `Failed to declaim tasks for ${user.username}`
            });
         },
         complete: () => {
            this.isLoading = false;
         }
      });
   }

   async downloadUsersToFile() {
      const ExcelJS = await import('exceljs');

      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Users');

      worksheet.columns = [
         { header: 'Username', key: 'username', width: 30 },
         { header: 'First Name', key: 'firstName', width: 20 },
         { header: 'Last Name', key: 'lastName', width: 20 },
         { header: 'Last Seen', key: 'lastSeen', width: 30 },
         { header: 'Groups', key: 'groups', width: 40 },
         { header: 'Roles', key: 'roles', width: 40 }
      ];

      this.users.forEach((user) => {
         worksheet.addRow({
            username: user.username,
            firstName: user.firstName,
            lastName: user.lastName,
            lastSeen: user.lastSeen
               ? new Date(user.lastSeen).toLocaleString()
               : 'Never',
            groups: user.groups.map((g) => g.displayName).join(', '),
            roles: user.roles.map((r) => r.name.replace('_', ' ')).join(', ')
         });
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
         type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      saveAs(blob, `tyalls_users_${new Date().toISOString().split('T')[0]}.xlsx`);
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes['currentProperty']) {
         if (this.currentProperty) {
            this.isLoading = true;
            this.propertyService
               .getUsers(this.currentProperty.id)
               .subscribe((users) => {
                  this.users = users.filter((user) => {
                     return user.isEnabled;
                  });
                  this.firstRow = 0;
                  this.isLoading = false;
               });
            this.propertyService
               .getRoles(this.currentProperty.id)
               .subscribe((roles) => {
                  this.roles = roles;
               });
            this.groupService
               .getGroups(this.currentProperty.id)
               .subscribe((groups) => {
                  this.groups = groups;
                  this.updateAvailableGroups();
               });
         }
      }
   }
}
