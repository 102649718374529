import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges,
   ViewChild
} from '@angular/core';
import { RowButton } from '../models/row-button';
import { User } from '../../domain/user';
import { UserStoreService } from '../../services/stores/user-store.service';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { Role } from '../../domain/role';
import { Group } from '../../domain/group';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Property } from '../../domain/property';
import { UserService } from '../../services/user.service';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { DataStoreService } from '../../services/data-store.service';
import { PermissionsService } from '../../services/permissions.service';
import { UserPermissions } from '../../domain/security/user-permissions';
import { PropertyService } from '../../services/property.service';
import { GroupService } from '../../services/group.service';

@Component({
   selector: 'app-user-table',
   templateUrl: './user-table.component.html',
   styleUrls: ['./user-table.component.scss']
})
export class UserTableComponent implements OnInit, OnChanges {
   users: User[];
   selectedUsers: User[];
   isCurrentlyVisible: boolean;
   isLoading: boolean;
   headingButtons: RowButton[];
   firstRow: number;
   roles: Role[];
   selectedRole: Role | undefined;
   groups: Group[];
   availableGroups: Group[];
   selectedGroup: Group | undefined;
   currentUser: User | undefined;
   @Input()
   currentProperty: Property | undefined;
   @ViewChild('roleOverlay') roleOverlay: OverlayPanel | undefined;
   @ViewChild('groupOverlay') groupOverlay: OverlayPanel | undefined;
   @Output() addUserDialogOpen = new EventEmitter();
   userPermissions: UserPermissions;

   constructor(
      private userStore: UserStoreService,
      private userService: UserService,
      private propStore: PropertyStoreService,
      private propertyService: PropertyService,
      private dataStoreService: DataStoreService,
      public permissionService: PermissionsService,
      private groupService: GroupService
   ) {
      this.currentProperty = undefined;
      this.isLoading = false;
      this.firstRow = 0;
      this.users = [];
      this.roles = [];
      this.groups = [];
      this.availableGroups = [];
      this.selectedUsers = [];
      this.isCurrentlyVisible = true;
      this.headingButtons = [
         new RowButton(
            'add_user',
            'Add User',
            'fal fa-plus',
            [
               'p-button-rounded',
               'p-button-success',
               'p-button-outlined',
               'p-mr-2',
               'p-mb-2'
            ],
            ['p-col-12', 'p-text-right'],
            'right'
         )
      ];
      this.userPermissions = new UserPermissions();
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this.headingButtons.forEach(
         (x) => (x.isVisible = this.userPermissions.user.create)
      );
   }

   removeBeforeFirstUnderscore(name: string): string {
      const parts = name.split('_');
      if (parts.length > 1) {
         return parts.slice(1).join('_');
      }
      return name;
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'add_user':
            this.addUserDialogOpen.emit();
            break;
         default:
            break;
      }
   }

   toggleAccess(user: User, isEnabled: boolean) {
      if (this.currentProperty) {
         if (!isEnabled) {
            this.userService
               .removeUserFromProperty(user.authId, this.currentProperty.id)
               .subscribe(() => {
                  const refreshDataStore = new RefreshDataState();
                  refreshDataStore.Users = true;
                  this.dataStoreService.refreshTaskData(refreshDataStore);
               });
         }
      }
   }

   addRole() {
      if (this.currentUser && this.selectedRole && this.currentProperty) {
         this.userService
            .addRole(this.currentUser?.authId, this.currentProperty.id, [
               this.selectedRole
            ])
            .subscribe((u) => {
               this.updateUser(u);
               this.roleOverlay?.hide(); // Close the role overlay panel
            });
      }
   }

   addGroup() {
      if (this.currentUser && this.selectedGroup && this.currentProperty) {
         this.userService
            .addGroup(this.currentUser?.authId, this.currentProperty.id, [
               this.selectedGroup
            ])
            .subscribe((u) => {
               this.updateUser(u);
               if (this.selectedGroup) {
                  this.selectedGroup.isDisable = true;
               }
               this.updateAvailableGroups();
               this.groupOverlay?.hide(); // Close the group overlay panel
            });
      }
   }

   updateUser(passedUser: User) {
      const objIndex = this.users.findIndex(
         (obj) => obj.authId === passedUser.authId
      );
      if (objIndex > -1) {
         const user = this.users[objIndex];
         user.isEnabled = passedUser.isEnabled;
         user.groups = passedUser.groups;
         user.roles = passedUser.roles;
         this.users[objIndex] = user;
      }
   }

   openDialog(user: User, addRole: boolean, addGroup: boolean, $event: any) {
      this.currentUser = user;
      if (addRole) {
         this.roleOverlay?.show($event);
      } else if (addGroup) {
         this.filterGroupsForUser();
         this.groupOverlay?.show($event);
      }
   }

   removeGroup(group: Group, userId: string) {
      if (userId && group && this.currentProperty) {
         this.userService
            .deleteGroup(userId, this.currentProperty?.id, group)
            .subscribe((u) => {
               this.updateUser(u);
               const foundGroup = this.availableGroups.find(
                  (group) => group.id === group.id
               );
               if (foundGroup) {
                  foundGroup.isDisable = false;
               }
            });
      }
   }

   removeRole(role: Role, userId: string) {
      if (userId && role && this.currentProperty) {
         this.userService
            .deleteRole(userId, this.currentProperty.id, role)
            .subscribe((u) => {
               this.updateUser(u);
            });
      }
   }

   private updateAvailableGroups() {
      //deep copy the array so we don't end up with issues later
      this.availableGroups = this.groups.map((obj) => ({ ...obj }));
   }

   filterGroupsForUser() {
      if (this.currentUser) {
         this.updateAvailableGroups();
         for (const selectedGroup of this.currentUser.groups) {
            const foundGroup = this.availableGroups.find(
               (group) => group.id === selectedGroup.id
            );
            if (foundGroup) {
               foundGroup.isDisable = true;
            }
         }
      }
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes['currentProperty']) {
         if (this.currentProperty) {
            this.propertyService
               .getUsers(this.currentProperty.id)
               .subscribe((users) => {
                  this.users = users.filter((user) => {
                     return user.isEnabled;
                  });
                  this.firstRow = 0;
               });
            this.propertyService
               .getRoles(this.currentProperty.id)
               .subscribe((roles) => {
                  this.roles = roles;
               });
            this.groupService
               .getGroups(this.currentProperty.id)
               .subscribe((groups) => {
                  this.groups = groups;
                  this.updateAvailableGroups();
               });
         }
      }
   }
}
