import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Workflow } from '../../domain/workflow';
import { PropertyStoreService } from './property-store.service';
import { WorkflowService } from '../workflow.service';
import { WorkflowRunRecord } from '../../domain/workflow-run-record';
import { DataStoreService } from '../data-store.service';

@Injectable({
   providedIn: 'root'
})
export class WorkflowStoreService {
   private _workflows: BehaviorSubject<Workflow[]> = new BehaviorSubject(
      new Array<Workflow>()
   );
   public readonly workflows: Observable<Workflow[]> = this._workflows.asObservable();
   private _lastWorkflowRunDate: ReplaySubject<string> = new ReplaySubject();

   public readonly lastWorkflowRunDate: Observable<string> =
      this._lastWorkflowRunDate.asObservable();
   private _workflowHistoryRecords: BehaviorSubject<WorkflowRunRecord[]> =
      new BehaviorSubject(new Array<WorkflowRunRecord>());

   public readonly workflowHistoryRecords: Observable<WorkflowRunRecord[]> =
      this._workflowHistoryRecords.asObservable();

   private currentPropertyId: number | undefined;

   constructor(
      private propertyStore: PropertyStoreService,
      private workflowService: WorkflowService,
      private dataStoreService: DataStoreService
   ) {
      propertyStore.currentProperty.subscribe((property) => {
         this.currentPropertyId = property.id;
         this.getWorkflowsForProperty();
         this.getLastWorkflowRunTime();
         this.getWorkflowHistory();
      });
      dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (this.currentPropertyId) {
            if (value.WorkflowHistory) {
               this.getLastWorkflowRunTime();
               this.getWorkflowHistory();
            }
         }
      });
   }

   public getWorkflowsForProperty() {
      if (this.currentPropertyId) {
         this.workflowService
            .getWorkflowsForProperties(this.currentPropertyId)
            .subscribe((workflows) => {
               this._workflows.next(workflows);
            });
      }
   }

   public getLastWorkflowRunTime() {
      if (this.currentPropertyId) {
         this.workflowService
            .getLastWorkflowRuntime(this.currentPropertyId)
            .subscribe((lastRun) => {
               this._lastWorkflowRunDate.next(lastRun);
            });
      }
   }

   public getWorkflowHistory() {
      if (this.currentPropertyId) {
         this.workflowService
            .getWorkflowHistoryForPropertiesByUser(this.currentPropertyId)
            .subscribe((workflowHistoryRecords) => {
               this._workflowHistoryRecords.next(workflowHistoryRecords);
            });
      }
   }
}
