export class UploadFile {
   file: File;
   isPrimary: boolean;
   isUploaded: boolean;

   constructor(file: File) {
      this.file = file;
      this.isPrimary = false;
      this.isUploaded = false;
   }
}
