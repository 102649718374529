export class Assignment {
   workflowGroup: string;
   assignee: string;
   assigneeDisplay: string;
   startDateTime: string;
   finishDateTime: string;
   claimDateTime: string;
   id: number;
   displayName: string;

   constructor() {
      this.workflowGroup = '';
      this.assignee = '';
      this.startDateTime = '';
      this.finishDateTime = '';
      this.claimDateTime = '';
      this.id = 0;
      this.displayName = '';
      this.assigneeDisplay = '';
   }
}
