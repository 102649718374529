import { Pipe, PipeTransform } from '@angular/core';
import { Task } from '../domain/task';
import { SortParameters } from './domain/sort-parameters';

@Pipe({
   name: 'taskSort'
})
export class TaskSortPipe implements PipeTransform {
   transform(value: Task[], sortParameter: SortParameters): Task[] {
      return value.sort((a: any, b: any) => {
         if (sortParameter.ascending) {
            if (a[sortParameter.propertyName] < b[sortParameter.propertyName]) {
               return -1;
            } else if (
               a[sortParameter.propertyName] > b[sortParameter.propertyName]
            ) {
               return 1;
            } else {
               return 0;
            }
         } else {
            if (a[sortParameter.propertyName] > b[sortParameter.propertyName]) {
               return -1;
            } else if (
               a[sortParameter.propertyName] < b[sortParameter.propertyName]
            ) {
               return 1;
            } else {
               return 0;
            }
         }
      });
   }
}
