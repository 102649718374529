<div *ngIf="this.isLoading" class="progress-spinner">
   <p-progressSpinner></p-progressSpinner>
</div>

<div class="container">
	<div class="sm-10 md-2 lg-2 pb-3">
		<app-ai-daily-panel *ngIf="this.userPermissions.forms.alpha"></app-ai-daily-panel>
	</div>
   <div class="sm-10 md-2 lg-2">
      <app-widget-row></app-widget-row>
   </div>

   <div class="sm-10 md-10 lg-10 pt-3">
      <div class="card">
         <div *ngIf="this.isPanelVisible(1)">
            <app-workflow-table
               (claimTasksEvent)="claimTasks($event)"
               [isClaiming]="isClaiming"
               [loading]="isLoadingTasks"
               [taskList]="unclaimedTask"
							 [currentProperty]="currentProperty"
            ></app-workflow-table>
         </div>
         <div *ngIf="this.isPanelVisible(2)">
            <app-mention-table></app-mention-table>
         </div>
         <div *ngIf="this.isPanelVisible(3)">
            <app-follow-up-table></app-follow-up-table>
         </div>
         <div *ngIf="this.isPanelVisible(4)">
            <app-claimed-tasks-table></app-claimed-tasks-table>
         </div>
      </div>
   </div>
</div>
