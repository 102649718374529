import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserPermissions } from '../domain/security/user-permissions';
import { environment } from '../../environments/environment';
import { firstValueFrom } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class PermissionsService {
   private jwtHelper: JwtHelperService;

   // Local cache of last refresh time (epoch ms)
   private lastRefreshTime: number | null = null;

   // Refresh interval in milliseconds (default: 5 minutes)
   private refreshIntervalMs: number = 5 * 60 * 1000;

   constructor(private auth: AuthService) {
      this.jwtHelper = new JwtHelperService();
      // Initialize last refresh time from localStorage
      this.lastRefreshTime = this.getLastRefreshTime();
   }

   // Public method to get user permissions
   async getPermission(): Promise<UserPermissions> {
      // Refresh token data only if needed
      const shouldRefresh = this.shouldRefreshToken();
      await this.loadFreshTokenData();
      if (shouldRefresh) {
         await this.loadFreshTokenData();
      }

      // Retrieve roles and permissions from localStorage (existing logic)
      const permissionsArray = await this.getPermissions();
      const rolesArray = await this.getRoles();

      const userPermissions = new UserPermissions();

      userPermissions.hasAudit = permissionsArray.includes('read:audit-bucket');
      userPermissions.hasCreateAudit =
         permissionsArray.includes('create:audit-bucket');
      userPermissions.hasWorkflow = permissionsArray.includes('workflow:general');
      userPermissions.hasWorkflowRun = permissionsArray.includes('workflow:launch');
      userPermissions.isPropAdmin =
         rolesArray.includes('prop_admin') || rolesArray.includes('account_owner');
      userPermissions.isBillingAdmin =
         rolesArray.includes('billing_admin') || rolesArray.includes('account_owner');

      userPermissions.updatePermissions(permissionsArray);

      return userPermissions;
   }

   // Retrieves permissions from localStorage
   private async getPermissions() {
      let permissionsArray = localStorage.getItem('permissions');

      if (localStorage.getItem('permissions') == null) {
         await this.getTokenData(); // fallback, not typically triggered anymore
         if (localStorage.getItem('permissions') != null) {
            permissionsArray = localStorage.getItem('permissions');
         }
      }

      if (!permissionsArray) {
         console.error('Unable to get permissions data');
         permissionsArray = '';
      }

      return permissionsArray;
   }

   // Retrieves roles from localStorage
   private async getRoles() {
      let roleArray = localStorage.getItem('roles');

      if (localStorage.getItem('roles') == null) {
         await this.getTokenData(); // fallback, not typically triggered anymore
         if (localStorage.getItem('roles') != null) {
            roleArray = localStorage.getItem('roles');
         }
      }

      if (!roleArray) {
         console.error('Unable to get roles data');
         roleArray = '';
      }

      return roleArray;
   }

   // Returns true if enough time has passed since the last refresh
   private shouldRefreshToken(): boolean {
      const currentTime = Date.now();

      if (this.lastRefreshTime === null) {
         return true; // We've never refreshed before
      }

      const timeSinceLastRefresh = currentTime - this.lastRefreshTime;

      return timeSinceLastRefresh >= this.refreshIntervalMs;
   }

   private async refreshTokenAndStoreData(
      updateRefreshTime: boolean = false
   ): Promise<void> {
      const isAuthenticated = await firstValueFrom(this.auth.isAuthenticated$);

      if (!isAuthenticated) {
         console.warn('User is not authenticated');
         return;
      }

      try {
         const token = await firstValueFrom(
            this.auth.getAccessTokenSilently({
               cacheMode: 'off',
               authorizationParams: {
                  audience: environment.auth.audience
               }
            })
         );

         if (token) {
            const tokenJson = this.jwtHelper.decodeToken(token);

            if (tokenJson.permissions) {
               localStorage.setItem('permissions', tokenJson.permissions);
            }

            if (tokenJson.tyallsRoles) {
               localStorage.setItem('roles', tokenJson.tyallsRoles);
            }

            if (updateRefreshTime) {
               const currentTime = Date.now();
               this.lastRefreshTime = currentTime;
               this.setLastRefreshTime(currentTime);
               console.log('Roles and permissions successfully refreshed.');
            } else {
               console.log('Fallback token data retrieved and stored.');
            }
         } else {
            console.error('Token is null or undefined');
         }
      } catch (error) {
         console.error('Failed to get token data', error);
      }
   }

   // Returns the last refresh time from localStorage
   private getLastRefreshTime(): number | null {
      const time = localStorage.getItem('lastRefreshTime');
      return time ? Number(time) : null;
   }

   // Saves the last refresh time to localStorage
   private setLastRefreshTime(time: number): void {
      localStorage.setItem('lastRefreshTime', time.toString());
   }

   private async loadFreshTokenData(): Promise<void> {
      await this.refreshTokenAndStoreData(true);
   }

   private async getTokenData() {
      await this.refreshTokenAndStoreData(false);
   }
}
