import { AfterViewInit, Component, OnInit } from '@angular/core';
import { WorkflowService } from '../../../services/workflow.service';
import { PropertyStoreService } from '../../../services/stores/property-store.service';
import { Property } from '../../../domain/property';
import { SelectItem } from 'primeng/api';
import { Workflow } from '../../../domain/workflow';
import { TaskService } from '../../../services/task.service';
import { ReportRequest } from '../../../services/request/report-request';
import { DateTime } from 'luxon';
import { AuditReportRecord } from '../../../domain/audit-report-record';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportStoreService } from '../../../services/stores/report-store.service';
import { AuditReportHistory } from '../../../domain/audit-report-history';
import { Subscription } from 'rxjs';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { TaskUtils } from '../../../util/task-utils';
import { Router } from '@angular/router';
import { PermissionsService } from '../../../services/permissions.service';
import { UserPermissions } from '../../../domain/security/user-permissions';
import { WorkflowType } from '../../../domain/enums/workflow-type';
import { DropDownStoreService } from '../../../services/stores/drop-down-store.service';
import { Assignment } from '../../../domain/assignment';
import { DatePropertyTimezonePipe } from '../../../pipes/date-property-timezone.pipe';

@Component({
   selector: 'app-audit-report',
   templateUrl: './audit-report.component.html',
   styleUrls: ['./audit-report.component.scss']
})
export class AuditReportComponent implements AfterViewInit, OnInit {
   auditReportForm: FormGroup;
   auditReportList: AuditReportRecord[];
   properties: Property[];
   workflows: Workflow[];
   activeWorkflows: Workflow[];
   propertyDownValues: SelectItem[];
   isRunning: boolean;
   hasRun: boolean;
   isHistoricalRun: boolean;
   minFilterDate: Date;
   maxFilterDate: Date;
   lastRequest?: ReportRequest;
   auditReportHistoryData: AuditReportHistory | undefined;
   isSaving: boolean;
   private historySubscription: Subscription | undefined;
   userPermissions: UserPermissions;
   currentProperty: Property | undefined;
   numRecords: string | undefined;
   datesOfRecords: string | undefined;
   showWorkflowDropdown: boolean;

   constructor(
      private _workflowService: WorkflowService,
      private _propertyStore: PropertyStoreService,
      private _reportStore: ReportStoreService,
      private _taskService: TaskService,
      private router: Router,
      public permissionService: PermissionsService,
      private dropDownStore: DropDownStoreService
   ) {
      _propertyStore.currentProperty.subscribe((value) => {
         this.currentProperty = value;
         this._workflowService
            .getWorkflowsForProperties(this.currentProperty.id)
            .subscribe((workflows) => {
               this.workflows = workflows;
               this.selectedWorkFlows.setValue([]);
               this.updateActiveWorkflows();
            });
      });
      this.userPermissions = new UserPermissions();
      this.minFilterDate = DateTime.fromISO('2019-01-01').toJSDate();
      this.maxFilterDate = DateTime.now().toJSDate();
      this.isHistoricalRun = false;
      this.isSaving = false;
      this.auditReportForm = new FormGroup({
         property: new FormControl(
            [],
            [Validators.required, Validators.minLength(1)]
         ),
         workflows: new FormControl(
            [],
            [Validators.required, Validators.minLength(1)]
         ),
         startDate: new FormControl(new Date(), Validators.required),
         finishDate: new FormControl(new Date(), Validators.required),
         dateRangeOption: new FormControl('', Validators.required),
         workflowType: new FormControl('standard', Validators.required)
      });
      this.auditReportHistoryData = undefined;
      this.auditReportList = [];
      this.properties = [];
      this.propertyDownValues = [];
      this.workflows = [];
      this.activeWorkflows = [];
      this.isRunning = false;
      this.lastRequest = undefined;
      _propertyStore.properties.subscribe((properties) => {
         this.properties = properties;
         for (const prop of this.properties) {
            const item = {
               label: prop.name,
               value: prop
            };
            this.propertyDownValues.push(item);
         }
      });
      this.numRecords = undefined;
      this.datesOfRecords = undefined;
      this.hasRun = false;
      this.showWorkflowDropdown = true;
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this.updateDropDownState(false);
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   get dateRangeOption() {
      return this.auditReportForm.get('dateRangeOption')!;
   }

   get selectedWorkFlows() {
      return this.auditReportForm.get('workflows')!;
   }

   get startDate() {
      return this.auditReportForm.get('startDate')!;
   }

   get finishDate() {
      return this.auditReportForm.get('finishDate')!;
   }

   get workflowType() {
      return this.auditReportForm.get('workflowType');
   }

   runAudit($event: MouseEvent) {
      $event.preventDefault();
      this.isRunning = true;
      this.hasRun = false;

      if (this.currentProperty != undefined) {
         this.lastRequest = new ReportRequest();

         let requestWorkflows: Workflow[] = [];
         this.lastRequest.workflowIds = [];
         this.lastRequest.formData = {
            property: this.currentProperty,
            workflows: requestWorkflows,
            startDate: this.startDate,
            finishDate: this.finishDate,
            dateRangeOption: this.dateRangeOption
         };
         if (this.selectedWorkFlows.value.length != this.activeWorkflows.length) {
            requestWorkflows = this.selectedWorkFlows.value.map((w: Workflow) => ({
               ...w
            }));

            this.workflows
               .filter((w: Workflow) => {
                  return !w.isActive;
               })
               .forEach((w: Workflow) => {
                  if (requestWorkflows.some((rw: Workflow) => rw.name === w.name)) {
                     requestWorkflows.push(w);
                  }
               });
            this.lastRequest.workflowIds = this.selectedWorkFlows.value.map(
               (w: Workflow) => {
                  return w.id;
               }
            );
         }

         switch (this.dateRangeOption.value) {
            case 'current_month':
            default:
               this.lastRequest.start = DateTime.local().startOf('month').toISODate();
               this.lastRequest.finish = DateTime.now().toISODate();
               this.hasRun = true;
               break;
            case 'last_month':
               this.lastRequest.start = DateTime.now()
                  .minus({ months: 1 })
                  .startOf('month')
                  .toISODate();
               this.lastRequest.finish = DateTime.now()
                  .minus({ months: 1 })
                  .endOf('month')
                  .toISODate();
               this.hasRun = true;
               break;
            case 'last_week':
               this.lastRequest.start = DateTime.now()
                  .minus({ weeks: 1 })
                  .startOf('week')
                  .toISODate();
               this.lastRequest.finish = DateTime.now()
                  .minus({ weeks: 1 })
                  .endOf('week')
                  .toISODate();
               this.hasRun = true;
               break;
            case 'today':
               this.lastRequest.start = DateTime.now().startOf('day').toISODate();
               this.lastRequest.finish = DateTime.now().endOf('day').toISODate();
               this.hasRun = true;
               break;
            case 'custom':
               this.lastRequest.start = DateTime.fromJSDate(
                  this.startDate.value
               ).toISODate();
               this.lastRequest.finish = DateTime.fromJSDate(
                  this.finishDate.value
               ).toISODate();
               this.hasRun = true;
               break;
         }

         this.historySubscription?.unsubscribe();

         if (
            this.currentProperty.isPhobosEnabled ||
            this.workflowType?.value === 'form'
         ) {
            console.log('Running phobos Report');
            this._reportStore
               .runFormAuditQuery(this.currentProperty.id, this.lastRequest)
               .subscribe((records) => {
                  this.auditReportList = records;
                  this.filterForms(this.workflowType?.value === 'form');
                  this.isRunning = false;
                  this.isHistoricalRun = false;
               });
         } else {
            console.log('Running Audit Report');
            this._reportStore
               .runAuditQuery(this.currentProperty.id, this.lastRequest)
               .subscribe((records) => {
                  this.auditReportList = records;
                  this.filterForms(this.workflowType?.value === 'form');
                  this.isRunning = false;
                  this.isHistoricalRun = false;
               });
         }
      }
   }

   filterForms(formsOnly: boolean) {
      if (formsOnly) {
         this.auditReportList = this.auditReportList.filter(
            (ar) => ar.dailyState === 'FORM'
         );
      } else {
         this.auditReportList = this.auditReportList.filter(
            (ar) => ar.dailyState !== 'FORM'
         );
      }
   }

   ngAfterViewInit(): void {
      this.historySubscription = this._reportStore.auditReportHistory.subscribe(
         (data) => {
            this.isHistoricalRun = true;
            this.auditReportHistoryData = data;
            this.lastRequest = this.auditReportHistoryData.request;
            this.auditReportList = this.auditReportHistoryData.results;
         }
      );
   }

   populateWorkbook(worksheet: ExcelJS.Worksheet) {
      worksheet.columns = [
         { header: 'Property', key: 'property' },
         { header: 'Workflow Name', key: 'workflowName' },
         { header: 'Due Date', key: 'dueDate' },
         { header: 'Daily State', key: 'dailyState' },
         { header: 'Status', key: 'status' },
         { header: 'Currently With', key: 'currentlyWith' }
      ];

      for (const record of this.auditReportList) {
         const dataRow = [
            record.propCode,
            record.workflowName,
            record.dueDate,
            record.dailyState,
            record.status,
            record.currentlyWith
         ];
         for (const assignment of this.sortAssignments(record.assignments)) {
            dataRow.push(assignment.assignee);
            dataRow.push(assignment.claimDateTime);
            dataRow.push(assignment.finishDateTime);
         }
         worksheet.addRow(dataRow);
      }
      return worksheet;
   }

   sortAssignments(assignments: Assignment[]) {
      const trasnformer = new DatePropertyTimezonePipe(this._propertyStore);
      return assignments.sort((a: Assignment, b: Assignment) => {
         if (a.finishDateTime != null) {
            return -1;
         }
         const aFinishDate = trasnformer.getFormatedDateTime(
            a.finishDateTime,
            true,
            true
         );
         const bFinishDate = trasnformer.getFormatedDateTime(
            b.finishDateTime,
            true,
            true
         );
         if (
            aFinishDate != null &&
            bFinishDate != null &&
            aFinishDate > bFinishDate
         ) {
            return 1;
         }
         return 0;
      });
   }
   async saveAuditReportToFile($event: MouseEvent) {
      $event.preventDefault();
      this.isSaving = true;

      const workbook = new ExcelJS.Workbook();
      const sheet = workbook.addWorksheet('report');
      this.populateWorkbook(sheet);
      const buffer = await workbook.xlsx.writeBuffer();
      const fileType =
         'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      const fileExtension = '.xlsx';

      const blob = new Blob([buffer], { type: fileType });

      saveAs(
         blob,
         `tyalls_audit_report_${this.lastRequest?.start}-${this.lastRequest?.finish}.${fileExtension}`
      );
      this.isSaving = false;
   }

   updateActiveWorkflows(): void {
      this.selectedWorkFlows.setValue([]);
      if (this.workflowType?.value === 'form') {
         this.activeWorkflows = this.workflows.filter((w) => {
            return w.type === WorkflowType.Form && w.isActive;
         });
      } else {
         this.activeWorkflows = this.workflows.filter((w) => {
            return w.type !== WorkflowType.Form && w.isActive;
         });
      }
   }

   async goToTask(auditReportList: AuditReportRecord, $event: MouseEvent) {
      if (this.currentProperty) {
         $event.preventDefault();
         await TaskUtils.goToTaskViaAuditRecord(
            auditReportList,
            this.router,
            this.currentProperty
         );
      }
   }
}
