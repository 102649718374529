<p-dialog
   (close)="this.close($event)"
   (onHide)="this.close($event)"
   [(visible)]="this.isVisible"
   [autoZIndex]="true"
   [baseZIndex]="90000"
   [closable]="true"
   [style]="{ width: '50vw' }"
   header="Add User"
>
   <form #formDir="ngForm" [formGroup]="userForm">
      <div class="p-fluid p-grid p-pt-3">
         <div class="p-field p-col-12">
            <div class="p-grid">
               <div class="p-col-6">
                  <div class="p-inputgroup">
                     <span class="p-inputgroup-addon">
                        <i *ngIf="!this.isSearching" class="fal fa-user"></i>
                        <i
                           *ngIf="this.isSearching"
                           class="fal fa-spinner fa-pulse"
                        ></i>
                     </span>
                     <input
                        (blur)="this.onEmailChange()"
                        formControlName="email"
                        inputId="email"
                        pInputText
                        placeholder="Email"
                        type="text"
                     />
                  </div>
               </div>
            </div>
         </div>
         <div *ngIf="this.isNewUser" class="p-field p-col-12">
            <div class="p-grid">
               <div class="p-col-6">
                  <label for="firstName">First Name</label>
                  <input
                     formControlName="firstName"
                     inputId="firstName"
                     pInputText
                     type="text"
                  />
               </div>
            </div>
         </div>
         <div *ngIf="this.isNewUser" class="p-field p-col-12">
            <div class="p-grid">
               <div class="p-col-6">
                  <label for="lastName">Last Name</label>
                  <input
                     formControlName="lastName"
                     inputId="lastName"
                     pInputText
                     type="text"
                  />
               </div>
            </div>
         </div>
         <div *ngIf="this.isNewUser" class="p-field p-col-12">
            <div class="p-grid">
               <div class="p-col-6">
                  <label for="username-input">Username</label>
                  <input
                     formControlName="username"
                     inputId="username"
                     pInputText
                     type="text"
                  />
               </div>
            </div>
         </div>
         <div *ngIf="hasAccess && isExistingUser" class="p-col-12 p-pt-3">
            <p>
               The Email Address you have entered already has access to this property
            </p>
         </div>
         <div class="p-col-12 p-pt-3">
            <div class="p-grid">
               <div *ngIf="isNewUser" class="p-col-3">
                  <div class="p-field">
                     <p-button
                        (onClick)="this.onAddUser($event)"
                        [disabled]="userForm.invalid"
                        icon="fal fa-plus"
                        label="Add User"
                     ></p-button>
                  </div>
               </div>
               <div *ngIf="isExistingUser && !hasAccess" class="p-col-3">
                  <div class="p-field">
                     <p-button
                        (onClick)="this.onExistingUser($event)"
                        icon="fal fa-plus"
                        label="Add Existing"
                     ></p-button>
                  </div>
               </div>
               <div class="p-col-3 p-col-order-last">
                  <div class="p-field">
                     <p-button
                        (onClick)="this.search($event)"
                        *ngIf="!this.isExistingUser && !this.isNewUser"
                        [disabled]="this.isSearching"
                        icon="fal fa-times"
                        label="Next"
                     ></p-button>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </form>
</p-dialog>
