import { ChartOptions } from 'chart.js';

export class StaffReportChartOptions {
   options: ChartOptions<'bar'>;

   constructor() {
      this.options = {
         maintainAspectRatio: false,
         indexAxis: 'x',
         aspectRatio: 0.8,
         plugins: {
            legend: {
               position: 'bottom',
               labels: {
                  color: '#444444'
               }
            },
            tooltip: {
               callbacks: {
                  label: (tooltipItem: any) => {
                     const datasetLabel = tooltipItem.dataset.label;
                     const value = tooltipItem.raw;

                     // Determine which dataset the tooltip item belongs to
                     if (datasetLabel === 'Hours to claim') {
                        return `${value} hours to claim`;
                     } else if (datasetLabel === 'Hours to review') {
                        return `${value} hours to review`;
                     }
                     return `${value}`;
                  }
               }
            },
            datalabels: {
               anchor: 'center',
               align: 'center',
               font: {
                  weight: 'bold'
               },
               color: (context: any) => {
                  // Match the data label color to the borderColor of the dataset
                  return context.dataset.borderColor;
               },
               display: (context: any) => {
                  return context.dataset.data[context.dataIndex] !== 0; // Hide if value is 0
               },
               formatter: (value: number) => `${value}`
            }
         },
         scales: {
            x: {
               stacked: true,
               ticks: {
                  color: '#444444'
               },
               grid: {
                  color: '#d3d3d3',
                  drawBorder: false
               }
            },
            y: {
               stacked: true,
               ticks: {
                  color: '#555555'
               },
               grid: {
                  color: '#d3d3d3',
                  drawBorder: false
               }
            }
         },
         // onHover: (event: any, chartElement: any) => {
         //    event.native.target.style.cursor = chartElement[0]
         //       ? 'pointer'
         //       : 'default';
         // },
         // onClick: (event: any) => {
         //    const chart = event.chart;
         //    const elements = chart.getElementsAtEventForMode(
         //       event,
         //       'nearest',
         //       { intersect: true },
         //       true
         //    );
         //
         //    if (elements.length > 0) {
         //       const chartIndex = elements[0].index;
         //       const label = chart.data.labels[chartIndex];
         //
         //       console.log(`Clicked index: ${chartIndex}`);
         //       console.log(`Label: ${label}`);
         //    }
         // },
         datasets: {
            bar: {
               maxBarThickness: 40
            }
         }
      };
   }
}
