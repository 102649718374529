import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Group } from '../../domain/group';
import { PropertyStoreService } from './property-store.service';
import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { DataStoreService } from '../data-store.service';
import { LoadingState } from '../../domain/loading-state';
import { GroupService } from '../group.service';
import { IRefreshable } from './irefreshable';
import { UpdateGroupRequest } from '../request/update-group-request';

@Injectable({
   providedIn: 'root'
})
export class GroupStoreService implements IRefreshable {
   public currentPropertyId: number | undefined;
   private _groups: BehaviorSubject<Group[]> = new BehaviorSubject(
      new Array<Group>()
   );
   public readonly groups: Observable<Group[]> = this._groups.asObservable();

   constructor(
      private propertyStore: PropertyStoreService,
      private groupService: GroupService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService
   ) {
      this.propertyStore.currentProperty.subscribe((property) => {
         this.currentPropertyId = property.id;
         this.updateData();
      });
      this.dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (value.Groups) {
            this.updateData();
         }
      });
   }

   refreshStore(): void {
      this.updateData();
   }

   private updateData() {
      if (this.currentPropertyId) {
         const loadingState = new LoadingState();
         loadingState.Groups = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.groupService.getGroups(this.currentPropertyId).subscribe((groups) => {
            groups.map((group: any, index: number) => (group.index = index + 1));
            const loadingState = new LoadingState();
            loadingState.Groups = false;
            this.loadingOverlayStore.setLoadingState(loadingState);
            this._groups.next(groups);
         });
      }
      return new Observable<Group>();
   }

   updateGroup(
      selectedGroup: Group,
      request: UpdateGroupRequest
   ): Observable<number> {
      if (this.currentPropertyId && selectedGroup) {
         return this.groupService.updateGroup(
            this.currentPropertyId,
            selectedGroup,
            request
         );
      }
      return new Observable<number>();
   }
}
