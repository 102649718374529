<!-- Users Page Component. -->
<div *ngIf="this.isLoading" class="progress-spinner">
	<p-progressSpinner></p-progressSpinner>
</div>
<app-add-user-dialog
	(addUserDialogClose)="this.onDialogClose()"
	[isVisible]="this.isAddUserVisible"
	[currentProperty]="currentSelectedProperty"
>
</app-add-user-dialog>
<div class="p-grid container">
	<div class="p-sm-10 p-md-10 p-lg-10 p-pt-3">
		<div class="card">
			<app-all-properties-drop-down (propertyChange)="this.propertyChange($event)"></app-all-properties-drop-down>
			<app-user-table
				(addUserDialogOpen)="this.onAddUserOpen()"
				[hidden]="this.isLoading"
				[currentProperty]="currentSelectedProperty"
			></app-user-table>
		</div>
	</div>
</div>
