<div *ngIf="this.isLoading" class="progress-spinner">
   <p-progressSpinner></p-progressSpinner>
</div>
<app-add-coment-dialog
   (commentDialogClose)="this.onDialogClose()"
   [currentFile]="this.primaryFile"
   [isVisible]="this.isCommentDialogVisible"
   [processId]="this.processId"
   [taskType]="WorkflowType.Daily"
></app-add-coment-dialog>

<div class="grid container align-center">
   <div [class]="this.pdfCss">
      <div class="card-task-detail relative">
         <app-pdf-file-panel
            (goToNextTaskClicked)="this.goToNextTask()"
            (goToPreviousClicked)="this.goToPreviousTask()"
            (reviewClicked)="this.onReview()"
            (uploadFile)="this.uploadWorkflowFile($event)"
            [containerId]="this.mainPdfContainer"
            [currentFile]="this.primaryFile"
            [currentTask]="this.currentTask"
            [hasFile]="this.primaryFile.documentId !== undefined"
            [isLoading]="this.isLoading"
            [isPrimary]="true"
            [pdfPanelInFocus]="this.pdfPanelInFocus"
            [showPanelPadding]="this.showPanelPadding"
						[isSubmitButtonDisabled] = "this.isSubmitting"
         ></app-pdf-file-panel>
      </div>
   </div>

   <div class="flex justify-content-center mx-3 pt-5">
      <div class="hidden lg:block">
         <app-round-button-left
            [hidden]="!this.pdfPanelInFocus"
            (LeftClick)="getOpenComment()"
         ></app-round-button-left>

         <app-round-button-right
            [hidden]="this.pdfPanelInFocus"
            (RightClick)="getOpenPdf()"
         ></app-round-button-right>
      </div>
   </div>

   <div [class]="this.commentsCss">
      <div class="card relative">
         <app-document-side-panel
            [currentTask]="this.currentTask"
            [hasPrimaryDocument]="this.primaryFile.documentId !== undefined"
            (addComment)="openCommentDialog()"
            [isPhobos]="false"
         ></app-document-side-panel>
      </div>
   </div>
</div>
