<div *ngIf="this.isCurrentlyVisible" id="claimed_tasks_table">
   <app-table-heading-row
      (mainButtonClick)="this.onButtonRowClick($event)"
      [buttons]="this.headingButtons"
      [hasButton]="true"
      headingText=""
   ></app-table-heading-row>
   <p-table
      #dtc
      [(first)]="firstRow"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      [value]="claimedTasks"
      dataKey="processId"
      styleClass="p-datatable-tyalls p-datatable-gridlines p-datatable-striped p-datatable-sm"
   >
      <ng-template pTemplate="caption">
         <h5 class="tyalls-table-heading">Claimed Tasks</h5>
      </ng-template>
      <ng-template pTemplate="header">
         <tr>
            <th pSortableColumn="name">
               Workflow Name
               <p-sortIcon field="name"></p-sortIcon>
            </th>
            <th class="date-col" pSortableColumn="dueDate">
               Due Date
               <p-sortIcon field="dueDate"></p-sortIcon>
            </th>
            <th class="daily-state-col" pSortableColumn="isDaily">
               Daily State
               <p-sortIcon field="isDaily"></p-sortIcon>
            </th>
         </tr>
      </ng-template>
      <ng-template let-task pTemplate="body">
         <tr>
            <td>
               <span class="p-column-title">Name</span>
               {{ task.name }}
            </td>
            <td class="date-col">
               <span class="p-column-title">Due Date</span>
               {{ this.formatDate(task.dueDate) }}
            </td>
            <td class="daily-state-col">
               <span class="p-column-title">Daily State</span>
               <p-checkbox
                  [(ngModel)]="task.isDaily"
                  [binary]="true"
                  [disabled]="true"
                  name="isDaily"
               ></p-checkbox>
            </td>
         </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
         <tr>
            <td colspan="8">No tasks found</td>
         </tr>
      </ng-template>
   </p-table>
</div>
