import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
   name: 'DateLocalFormat'
})
export class DateLocalFormatPipe implements PipeTransform {
   transform(value: unknown, includeTime = false): unknown {
      let formattedDate = null;
      if (value instanceof DateTime) {
         formattedDate = value;
      } else if (value instanceof Date) {
         formattedDate = DateTime.fromJSDate(value);
      } else if (typeof value === 'string') {
         formattedDate = DateTime.fromISO(value);
      } else {
         return '';
      }
      if (includeTime) {
         return formattedDate.toLocaleString(DateTime.DATETIME_SHORT);
      }
      return formattedDate.toLocaleString(DateTime.DATE_SHORT);
   }
}
