<div class="container">
   <div>
      <div class="card">
         <div id="mention_table">
            <div class="tyalls-table-name mb-3">
               <h6 class="tyalls-table-heading">Run workflow<span class="font-light"> - {{currentProperty?.name}}</span></h6>
            </div>
         </div>

         <div class="pt-1 pb-3 mx-1 lg:flex justify-content-between">
            <div class="flex gap-4 pt-2 font-bold px-1">
               <div>
                  <div class="schedule-canvas">
                     <p-checkbox
                        class="mr-2"
                        (onChange)="this.onCheckboxClick()"
                        [(ngModel)]="workFlowFilters"
                        inputId="dailyType"
                        name="workflowFilterGroup"
                        value="{{ 0 }}"
                     ></p-checkbox>
                     <label for="dailyType">Daily</label>
                  </div>
               </div>

               <div>
                  <div class="schedule-canvas">
                     <p-checkbox
                        class="mr-2"
                        (onChange)="this.onCheckboxClick()"
                        [(ngModel)]="workFlowFilters"
                        inputId="nonDailyType"
                        name="workflowFilterGroup"
                        value="{{ 1 }}"
                     ></p-checkbox>
                     <label for="nonDailyType">Non Daily</label>
                  </div>
               </div>

               <div>
                  <div class="schedule-canvas">
                     <p-checkbox
                        class="mr-2"
                        (onChange)="this.onCheckboxClick()"
                        [(ngModel)]="workFlowFilters"
                        inputId="formType"
                        name="workflowFilterGroup"
                        value="{{ 4 }}"
                     ></p-checkbox>
                     <label for="formType">Form</label>
                  </div>
               </div>
            </div>

            <div class="mt-6 lg:mt-2 pr-1">
               <div class="dropdown-width">
                  <span class="p-float-label">
                     <p-dropdown
                        (onChange)="selectedWorkflowChange($event)"
                        [(ngModel)]="selectedWorkFlow"
                        [autoDisplayFirst]="false"
                        [disabled]="this.workFlowsLoaded"
                        [options]="this.dropDownValues"
                        [showClear]="false"
                        inputId="workflow"
                     ></p-dropdown>
                     <label for="workflow">Workflow</label>
                  </span>
               </div>
            </div>
         </div>

         <div class="flex mb-4 mt-4">
            <div
               *ngIf="
                  this.selectedWorkFlow !== undefined &&
                  this.selectedWorkFlow?.type !== WorkflowType.Form
               "
            >
               <span class="p-float-label">
                  <p-calendar
                     [(ngModel)]="this.runDate"
                     [defaultDate]="this.startDate"
                     [showIcon]="true"
                     dateFormat="yy-mm-dd"
                     inputId="run_date"
                  ></p-calendar>
                  <label for="run_date">Launch Date</label>
               </span>
            </div>

            <div
               *ngIf="
                  this.startDate !== undefined &&
                  this.selectedWorkFlow !== undefined &&
                  this.selectedWorkFlow?.type !== WorkflowType.Form
               "
               class="ml-3"
            >
               <p-button
                  (onClick)="this.runWorkFlow($event)"
                  [loading]="this.isLaunching"
                  icon="fal fa-play"
                  label="Run"
               ></p-button>
            </div>

            <div
               *ngIf="
                  this.startDate !== undefined &&
                  this.selectedWorkFlow !== undefined &&
                  this.selectedWorkFlow?.type === WorkflowType.Form
               "
            >
               <p-button
								 (onClick)="this.createForm()"
                  icon="fal fa-sparkles"
                  label="Create Form"
               ></p-button>
            </div>
         </div>

         <div class="p-1">
            <div *ngIf="workFlowRunHistory.length > 0; else noHistory">
               <div>
                  <h5>Workflow Run History</h5>
               </div>
               <p-table [value]="this.workFlowRunHistory" responsiveLayout="scroll">
                  <ng-template pTemplate="header">
                     <tr>
                        <th>Date</th>
                        <th>Name</th>
                        <th>Run By</th>
                        <th>
                           <div class="flex justify-content-center">Completed</div>
                        </th>
                     </tr>
                  </ng-template>
                  <ng-template let-workflowRun pTemplate="body">
                     <tr>
                        <td>{{ workflowRun.runDate }}</td>
                        <td>{{ workflowRun.workflowName }}</td>
                        <td>{{ workflowRun.runByUser }}</td>
                        <td>
                           <div
                              *ngIf="workflowRun.isCompleted === true; else elseBlock"
                           >
                              <!-- <i class="fal fa-check-circle fa-2x"></i> -->
                              <div class="flex justify-content-center">
                                 <h6>No</h6>
                              </div>
                           </div>
                           <ng-template #elseBlock>
                              <!-- <i class="fal fa-times-circle fa-2x"></i> -->
                              <div class="flex justify-content-center">
                                 <h6>Yes</h6>
                              </div>
                           </ng-template>
                        </td>
                     </tr>
                  </ng-template>
               </p-table>
            </div>
            <ng-template #noHistory>
               <p class="text-center">No Workflow History</p>
            </ng-template>
         </div>
      </div>
   </div>
</div>
