<div class="card flex justify-content-center">
	<p-accordion class="w-full" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
		<p-accordionTab>
			<ng-template pTemplate="header">
                <span class="flex justify-content-between gap-2 w-full">
										<div>
											<span class="font-bold white-space-nowrap">
													Task Date {{this.taskDate() | DateLocalFormat }}
											</span>
										</div>
										<div>
											<span class="font-bold white-space-nowrap">
												Auto-Completed Tasks
											</span>
											<p-badge value="{{this.totalCompletedTaskInstances()}}" class="ml-2 mr-2" />
										</div>
										<div>
											<span class="font-bold white-space-nowrap">
												AI-Tasks Processed
											</span>
											<p-badge value="{{this.totalProcessedTaskInstances()}}" class="ml-2 mr-2" />
										</div>
                </span>
			</ng-template>
			<p class="m-0">
				Lorem ipsum dolor sit amet...
			</p>
		</p-accordionTab>
	</p-accordion>
</div>
