<div class="grid container align-center">
   <div [class]="this.pdfCss">
      <div class="card-task-detail relative">
         <div *ngIf="!hasError">
            <app-pdf-file-panel
               (groupChange)="this.groupSelected($event)"
               (resendWorkflowCompletion)="this.resend($event)"
               (assignWorkflow)="assign($event)"
               (completeWorkflow)="complete($event)"
               (goToNextTaskClicked)="this.goToNextTask()"
               (goToPreviousClicked)="this.goToPreviousTask()"
               (uploadFile)="this.uploadWorkflowFile($event)"
               (reviewClicked)="this.onReview()"
               [containerId]="this.mainPdfContainer"
               [currentFile]="this.primaryFile"
               [currentTask]="this.currentTask"
               [hasFile]="this.primaryFile.storageId !== undefined"
               [isLoading]="this.isLoading"
               [isPrimary]="true"
               [pdfPanelInFocus]="this.pdfPanelInFocus"
               [flexCss]="this.flexCss"
               [phobosTaskInstance]="this.phobosTaskInstance"
               [showPanelPadding]="this.showPanelPadding"
							 [isSubmitButtonDisabled]="this.isSubmitting"
            ></app-pdf-file-panel>
         </div>
      </div>
   </div>

   <app-error-panel *ngIf="hasError"></app-error-panel>

   <div class="flex justify-content-center mx-3 pt-5">
      <div class="hidden xl:block">
         <app-round-button-left
            [hidden]="!this.pdfPanelInFocus"
            (LeftClick)="getOpenComment()"
         ></app-round-button-left>

         <app-round-button-right
            [hidden]="this.pdfPanelInFocus"
            (RightClick)="getOpenPdf()"
         ></app-round-button-right>
      </div>
   </div>

   <div [class]="this.commentsCss" *ngIf="!hasError">
      <div class="card relative">
         <app-document-side-panel
            [currentTask]="this.currentTask"
            [hasPrimaryDocument]="this.primaryFile.documentId !== undefined"
            (addComment)="openCommentDialog()"
            [isPhobos]="true"
         ></app-document-side-panel>
      </div>
   </div>

   <app-complete-form-dialog
      (dialogClose)="this.completeFormDialogClose()"
      [formId]="this.phobosTaskInstance.id"
      [isVisible]="this.isCompleteFormVisible"
      [isResend]="this.isResend"
   ></app-complete-form-dialog>

   <app-add-coment-dialog
      (commentDialogClose)="this.onDialogClose()"
      [currentFile]="this.primaryFile"
      [isVisible]="this.isCommentDialogVisible"
      [processId]="this.phobosTaskInstance.id"
      [taskType]="WorkflowType.Form"
   ></app-add-coment-dialog>
</div>
