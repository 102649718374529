import {
   ChangeDetectorRef,
   Component,
   HostBinding,
   Input,
   OnDestroy,
   OnInit
} from '@angular/core';
import { AppComponent } from '../../app.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuService } from '../../services/menu.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Component({
   selector: 'app-menuitem',
   animations: [
      trigger('children', [
         state(
            'void',
            style({
               height: '0px',
               padding: '0px'
            })
         ),
         state(
            'hiddenAnimated',
            style({
               height: '0px',
               padding: '0px'
            })
         ),
         state(
            'visibleAnimated',
            style({
               height: '*'
            })
         ),
         state(
            'visible',
            style({
               height: '*'
            })
         ),
         state(
            'hidden',
            style({
               height: '0px',
               padding: '0px'
            })
         ),
         transition(
            'visibleAnimated => hiddenAnimated',
            animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
         ),
         transition(
            'hiddenAnimated => visibleAnimated',
            animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
         ),
         transition(
            'void => visibleAnimated, visibleAnimated => void',
            animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
         )
      ])
   ],
   templateUrl: './menuitem.component.html',
   styleUrls: ['./menuitem.component.scss']
})
export class MenuitemComponent implements OnInit, OnDestroy {
   @HostBinding('[class.active-menuitem]') role = '(active)';
   @Input() item?: MenuItem;

   @Input() index: number;

   @Input() root: boolean;

   @Input() parentKey: string;

   active = false;

   menuSourceSubscription: Subscription;

   menuResetSubscription: Subscription;

   key: string;

   constructor(
      public app: AppComponent,
      public router: Router,
      private cd: ChangeDetectorRef,
      private menuService: MenuService
   ) {
      this.index = 0;
      this.root = false;
      this.parentKey = '';
      this.active = false;
      this.key = '';
      this.menuSourceSubscription = this.menuService.menuSource$.subscribe((key) => {
         // deactivate current active menu
         if (this.active && this.key !== key && key.indexOf(this.key) !== 0) {
            this.active = false;
         }
      });

      this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
         this.active = false;
      });

      this.router.events
         .pipe(filter((event) => event instanceof NavigationEnd))
         .subscribe(() => {
            if (this.item && this.item.routerLink) {
               this.updateActiveStateFromRoute();
            } else {
               this.active = false;
            }
         });
   }

   ngOnInit(): void {
      if (this.item && this.item.routerLink) {
         this.updateActiveStateFromRoute();
      }

      this.key = this.parentKey
         ? this.parentKey + '-' + this.index
         : String(this.index);
   }

   updateActiveStateFromRoute() {
      if (this.item)
         this.active = this.router.isActive(
            this.item.routerLink[0],
            !this.item.items
         );
   }

   itemClick(event: Event) {
      // avoid processing disabled items
      if (!this.item || this.item.disabled) {
         event.preventDefault();
         return true;
      }

      // notify other items
      this.menuService.onMenuStateChange(this.key);

      return true;
   }

   onMouseEnter() {}

   ngOnDestroy() {
      if (this.menuSourceSubscription) {
         this.menuSourceSubscription.unsubscribe();
      }

      if (this.menuResetSubscription) {
         this.menuResetSubscription.unsubscribe();
      }
   }
}
