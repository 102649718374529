<div *ngIf="this.isLoading" class="progress-spinner">
   <p-progressSpinner></p-progressSpinner>
</div>
<div class="card container">
   <div id="mention_table">
      <div class="tyalls-table-name mb-3">
         <h6 class="tyalls-table-heading">Launch<span class="font-light"> - {{selectedProperty?.name}}</span></h6>
      </div>
   </div>

   <div class="p-grid">
      <div class="sm-12 md-12 lg-12 pt-2">
         <div class="p-grid">
            <div class="lg:flex justify-content-between">
               <div class="flex gap-4 justify-content-between">
                  <div class="flex">
                     <div class="sm-2 md-1 lg-1 elements-center title-paddiing mr-3">
                        <h6>Date</h6>
                     </div>
                     <div class="p-sm-4 p-md-2 p-lg-2">
                        <p-calendar
                           (onSelect)="this.updateDate()"
                           [(ngModel)]="taskListDate"
                           [showIcon]="true"
                           dateFormat="dd/mm/yy"
                           inputId="icon"
                        ></p-calendar>
                     </div>
                  </div>

                  <div class="flex">
                     <div class="sm-2 md-1 lg-1 elements-center title-paddiing">
                        <h6>Sort By</h6>
                     </div>
                     <div class="p-sm-4 p-md-3 p-lg-2 ml-3">
                        <p-dropdown
                           [(ngModel)]="selectedSortOption"
                           [options]="this.sortOptions"
                           optionLabel="displayName"
                        ></p-dropdown>
                     </div>
                  </div>
               </div>

               <div class="divider"></div>

               <div class="flex justify-content-end gap-4 mb-2 mt-3 lg:mt-0">
                  <div>
                     <button
                        (click)="onSave()"
                        *ngIf="taskUploadFiles.length > 0"
                        class="p-button"
                        icon="fal fa-save"
                        label="Save"
                        pButton
                        pRipple
                        type="button"
                     ></button>
                  </div>

                  <div>
                     <input
                        #fileUpload
                        (change)="onUpload($event)"
                        class="file-input"
                        multiple
                        type="file"
                     />
                     <button
                        (click)="fileUpload.click()"
                        [pTooltip]="tooltip"
                        class="p-button"
                        icon="fal fa-upload"
                        label="Bulk upload files"
                        pButton
                        pRipple
                        type="button"
                     ></button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="mt-4">
         <div class="task-canvas">
            <app-task-list
               [(taskUploadFiles)]="taskUploadFiles"
               [sortParameters]="this.selectedSortOption"
               [tasks]="tasks"
               [currentProperty]="selectedProperty"
            ></app-task-list>
         </div>
      </div>
   </div>
</div>
