export class ReportRequest {
   workflowIds: number[];
   start: string | null = null;
   finish: string | null = null;
   formData: any;

   constructor() {
      this.workflowIds = [];
      this.formData = {};
   }

   toJSON() {
      return {
         workflowIds: this.workflowIds,
         start: this.start,
         finish: this.finish
      };
   }
}
