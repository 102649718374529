import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Base } from './base';
import { Workflow } from '../domain/workflow';
import { WorkflowRunRecord } from '../domain/workflow-run-record';
import { RunRequest } from './request/run-request';
import { Observable } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class WorkflowService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   getLastWorkflowRuntime(propId: number) {
      return this.http.get<string>(
         `${environment.apiEndpoint}/workflow/property/${encodeURIComponent(
            propId
         )}/lastrun`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getWorkflowsForProperties(propId: number): Observable<Workflow[]> {
      return this.http.get<Workflow[]>(
         `${environment.apiEndpoint}/workflow/property/${encodeURIComponent(propId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getWorkflowHistoryForProperties(propId: number) {
      return this.http.get<WorkflowRunRecord[]>(
         `${environment.apiEndpoint}/workflow/property/${encodeURIComponent(
            propId
         )}/history`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getWorkflowHistoryForPropertiesByUser(propId: number) {
      return this.http.get<WorkflowRunRecord[]>(
         `${environment.apiEndpoint}/workflow/property/${encodeURIComponent(
            propId
         )}/history/user`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   runWorkflow(workflowRunRequest: RunRequest) {
      return this.http.post<WorkflowRunRecord>(
         `${environment.apiEndpoint}/workflow`,
         workflowRunRequest,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }
}
