import { Injectable } from '@angular/core';
import { Base } from './base';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../domain/user';
import { Role } from '../domain/role';
import { Group } from '../domain/group';
import { UserQueryResponse } from './response/user-query-response';
import { UserQueryRequest } from './request/user-query-request';

@Injectable({
   providedIn: 'root'
})
export class UserService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   addUser(
      username: string,
      email: string,
      firstName: string,
      lastName: string,
      propertyId: number,
      roles: Role[]
   ): Observable<User> {
      return this.http.post<User>(
         `${environment.apiEndpoint}/user`,
         {
            username: username,
            email: email,
            firstName: firstName,
            lastName: lastName,
            isEnabled: true,
            propertyId: propertyId,
            roles: roles.map((r) => r.id),
            groups: []
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   updateEnabled(userId: string, value: boolean): Observable<User> {
      return this.http.post<User>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(userId)}/enabled`,
         {
            username: '',
            email: '',
            firstName: '',
            lastName: '',
            isEnabled: value,
            propertyId: undefined,
            roles: [],
            groups: []
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addRole(userId: string, propertyId: number, roles: Role[]): Observable<User> {
      return this.http.post<User>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(
            userId
         )}/property/${encodeURIComponent(propertyId)}/role`,
         {
            username: '',
            email: '',
            firstName: '',
            lastName: '',
            isEnabled: true,
            propertyId: undefined,
            roles: roles,
            groups: []
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   deleteRole(userId: string, propertyId: number, role: Role): Observable<User> {
      return this.http.delete<User>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(
            userId
         )}/property/${encodeURIComponent(propertyId)}/role/${encodeURIComponent(
            role.id
         )}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   deleteGroup(userId: string, propertyId: number, group: Group): Observable<User> {
      return this.http.delete<User>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(
            userId
         )}/property/${encodeURIComponent(propertyId)}/group/${encodeURIComponent(
            group.id
         )}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   removeUserFromProperty(userId: string, propertyId: number): Observable<boolean> {
      return this.http.delete<boolean>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(
            userId
         )}/property/${encodeURIComponent(propertyId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addUserToProperty(userId: string, propertyId: number): Observable<boolean> {
      return this.http.post<boolean>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(
            userId
         )}/property/${encodeURIComponent(propertyId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   hasAccessToProperty(userId: string, propertyId: number): Observable<boolean> {
      return this.http.get<boolean>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(
            userId
         )}/property/${encodeURIComponent(propertyId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addGroup(userId: string, propertyId: number, groups: Group[]): Observable<User> {
      return this.http.post<User>(
         `${environment.apiEndpoint}/user/${encodeURIComponent(
            userId
         )}/property/${encodeURIComponent(propertyId)}/group`,
         {
            username: '',
            email: '',
            firstName: '',
            lastName: '',
            isEnabled: true,
            propertyId: undefined,
            roles: [],
            groups: groups.map((g) => g.id)
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   queryUser(query: UserQueryRequest): Observable<UserQueryResponse> {
      return this.http.get<UserQueryResponse>(`${environment.apiEndpoint}/user`, {
         headers: this.headers,
         responseType: 'json',
         params: { ...query }
      });
   }
}
