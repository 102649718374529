export class DailyTaskDetail {
   taskDate: string;
   assigned: number;
   completed: number;
   overdue5More: number;
   overdue5Less: number;
   dailyCount: number;
   nonDailyCount: number;
   formCount: number;
   hasFormsAccess: boolean;
   showFormsCell: boolean;

   constructor() {
      this.taskDate = '';
      this.assigned = 0;
      this.completed = 0;
      this.overdue5More = 0;
      this.overdue5Less = 0;
      this.dailyCount = 0;
      this.nonDailyCount = 0;
      this.formCount = 0;
      this.hasFormsAccess = false;
      this.showFormsCell = false;
   }
}
