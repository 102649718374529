<div id="mention_table">
	<div class="tyalls-table-name mb-2">
		<h6 class="tyalls-table-heading">{{ panelHeading }}</h6>
	</div>
</div>

<div class="flex gap-2 flex-wrap pb-3">

	<div class="relative pr-2 pt-2">
		<div (click)="showCommentsView()" class="cursor-pointer button-height">
			<div class="flex justify-content-center">
				Comments
			</div>
			<p-badge [value]="comments.length.toString()" class="absolute top-0 right-0 pl-2"></p-badge>
		</div>
	</div>

	<div class="relative pr-2 pt-2">
		<div class="cursor-pointer button-height flex w-20">
			<div (click)="showSupportingFilesView()" class="flex justify-content-center">
				Files
			</div>
			<app-dropdown-button *ngIf="supportingFiles.length > 1" [isVisible]="isDropdownVisible" (toggleDropdown)="toggleDropdown()"></app-dropdown-button>
			<app-dropdown-menu (setCurrentSupportingFileFromDropdown)="setCurrentSupportingFileFromDropdown($event)" [items]="supportingFiles" [isVisible]="isDropdownVisible"></app-dropdown-menu>
			<p-badge [value]="supportingFiles.length.toString()" class="absolute top-0 right-0 pl-2"></p-badge>
		</div>
	</div>

	<div class="relative pr-2 pt-2">
		<div (click)="showDetailsView()" class="flex justify-content-center cursor-pointer button-height">
			<div>Details</div>
		</div>
	</div>
</div>

<div class="relative pt-2" [hidden]="!commentsView">
	<button (click)="onButtonRowClick('add_comment')" [disabled]="!hasPrimaryDocument" class="p-button-raised p-button-rounded h-8 absolute top-0 right-0" icon="fal fa-comment" pButton pRipple></button>
	<app-task-comments [comments]="comments"></app-task-comments>
</div>

<div [hidden]="!detailsView"  class="pt-2 px-2"
>
	<p-panel header="Instructions" [collapsed]="true"  [toggleable]="true" *ngIf="currentTask.description" >
		<p>
			{{currentTask.description}}
		</p>
	</p-panel>
	<div [class]="detailsView ? 'details-bg' : null" class="mt-3">
		<div *ngFor="let assignment of assignments">
			<div class="flex">
				<div>
					<div [class]="assignment.finishDateTime ? 'details-orange' : 'details-grey'" class="details-icon flex flex-none align-items-center justify-content-center text-white">
						<i class="pi pi-check text-sm"></i>
					</div>
					<div [class]="assignment.finishDateTime ? 'details-border-orange' : 'details-border-grey'" class="mt-1"></div>
				</div>
				<div class="px-2 pb-2">
					<div [class]="assignment.finishDateTime ? 'details-card-orange' : 'details-card-grey'" class="p-3 w-full text-sm font-bold mb-2">
						<p class="white-space-normal">Group: {{ assignment.workflowGroup }}</p>
					</div>
					<div class="xl:flex text-xs px-2 mb-1">
						<div class="mb-2 mr-3" *ngIf="assignment.finishDateTime">
							Reviewed by: <span class="font-bold">{{ assignment.assigneeDisplay }}</span>
						</div>
						<div *ngIf="assignment.finishDateTime">
							Date: <span class="font-bold">{{ assignment.finishDateTime | DateLocalFormat: false }}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div [hidden]="commentsView || detailsView || supportingFiles.length === 0" class="relative">
	<app-pdf-file-panel
		#fileUploadForm
		(goToNextTaskClicked)="({})"
		(goToPreviousClicked)="({})"
		(reviewClicked)="({})"
		(uploadFile)="({})"
		[containerId]="secondaryPdfContainer"
		[currentFile]="currentSecondaryFile"
		[showPanelPadding]="showPanelPadding"
		[currentTask]="currentTask"
		[isPrimary]="false"
	></app-pdf-file-panel>
</div>

<div [hidden]="!supportingFilesView" class="mt-5 flex justify-content-end">
	<h6 [hidden]="!supportingFilesView" class="title-text-supporting mr-3 line-height-3">Upload supporting files</h6>
	<p-fileUpload [hidden]="!supportingFilesView" [disabled]="!hasPrimaryDocument" mode="basic" #subFileUploadForm (uploadHandler)="onUploadSupportDoc($event)" [customUpload]="true" [multiple]="true" accept=".pdf, .jpg, .png, .doc, .docx, .xls, .xlsx, .csv" chooseLabel="Choose file">
		<ng-template pTemplate="toolbar"></ng-template>
		<ng-template pTemplate="content">
			<ul *ngIf="uploadedFiles.length">
				<li *ngFor="let uploadFile of uploadedFiles">
					{{ uploadFile.file.name }} - {{ uploadFile.file.size }} bytes
				</li>
			</ul>
		</ng-template>
	</p-fileUpload>
</div>
