// Old class - pre Ben's update 21/08/2024
// export class Property {
//    id: number;
//    propertyCode?: string;
//    timeZone?: string;
//    name?: string;
//    termsConditions?: boolean;
//    isPhobosEnabled?: boolean;
//
//    constructor() {
//       this.id = 0;
//    }
// }

export class Property {
   id: number;
   propertyCode: string;
   timeZone: string;
   name: string;
   schemaName: string | null;
   termsConditions: boolean;
   isPhobosEnabled: boolean;
   billingId: number | null;

   constructor() {
      this.id = 0;
      this.propertyCode = '';
      this.timeZone = '';
      this.name = '';
      this.schemaName = null;
      this.termsConditions = false;
      this.isPhobosEnabled = false;
      this.billingId = null;
   }
}
