import { Component, OnInit } from '@angular/core';
import { Workflow } from '../../domain/workflow';
import { WorkflowStoreService } from '../../services/stores/workflow-store.service';
import { MessageService, SelectItem } from 'primeng/api';
import { WorkflowRunRecord } from '../../domain/workflow-run-record';
import { RowButton } from '../../panel-components/models/row-button';
import { Location } from '@angular/common';
import { WorkflowService } from '../../services/workflow.service';
import { RunRequest } from '../../services/request/run-request';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { DateTime } from 'luxon';
import { DataStoreService } from '../../services/data-store.service';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { Router } from '@angular/router';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';

@Component({
   selector: 'app-workflow-run',
   templateUrl: './workflow-run.component.html',
   styleUrls: ['./workflow-run.component.scss']
})
export class WorkflowRunComponent implements OnInit {
   workFlowFilters: number[];
   workFlowsLoaded: boolean;
   dropDownValues: SelectItem[];
   selectedWorkFlow: Workflow | undefined;
   runDate: Date | undefined;
   startDate: Date;
   isLaunching: boolean;
   workFlowRunHistory: WorkflowRunRecord[];
   headingButtons: RowButton[];
   unFilteredWorkflows: Workflow[];
   currentProperty: Property | undefined;

   constructor(
      private workflowStore: WorkflowStoreService,
      private _workflowService: WorkflowService,
      private _propertyStore: PropertyStoreService,
      private messageService: MessageService,
      private _location: Location,
      private dataStoreService: DataStoreService,
      private router: Router,
      private workflowTaskStore: WorkflowTasksStoreService,
      private dropDownStore: DropDownStoreService
   ) {
      this.startDate = new Date();
      this.workflowStore.lastWorkflowRunDate.subscribe((value) => {
         const runDate = DateTime.fromISO(value);
         this.runDate = runDate.toJSDate();
      });
      this.isLaunching = false;
      this.runDate = undefined;
      this.workFlowFilters = [];
      this.workFlowsLoaded = false;
      this.dropDownValues = [];
      this.unFilteredWorkflows = [];
      this.selectedWorkFlow = undefined;
      this.workFlowRunHistory = [];
      this.headingButtons = [
         new RowButton(
            'back',
            '',
            'fal fa-arrow-left fa-1x',
            ['p-button-rounded'],
            ['p-col-1', 'p-text-left', 'p-pl-1']
         )
      ];
      this._propertyStore.currentProperty.subscribe((property) => {
         this.currentProperty = property;
      });
      this.workflowStore.workflows.subscribe((workflows) => {
         this.unFilteredWorkflows = workflows.filter((w) => w.isActive);
         this.generateItems();
      });
      this.workflowStore.workflowHistoryRecords.subscribe(
         (workflowHistoryRecords) => {
            this.workFlowRunHistory = workflowHistoryRecords;
         }
      );
   }

   async ngOnInit(): Promise<void> {
      this.updateDropDownState(false);
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   generateItems() {
      this.dropDownValues = [];
      for (const workflow of this.unFilteredWorkflows) {
         if (
            this.workFlowFilters.some((wf) => this.filterWorkflows(wf, workflow)) ||
            this.workFlowFilters.length == 0
         ) {
            const item = {
               label: workflow.name,
               value: workflow
            };
            this.dropDownValues.push(item);
         }
      }
   }

   filterWorkflows(wf: number, workflow: Workflow) {
      const wfType = wf as number;
      return wfType == (workflow.type as number);
   }
   onButtonRowClick(id: string) {
      switch (id) {
         case 'back':
            this._location.back();
            break;
         default:
            break;
      }
   }

   selectedWorkflowChange($event: SelectItem) {
      if ($event.value) {
         this.selectedWorkFlow = $event.value;
      }
   }

   runWorkFlow($event: MouseEvent) {
      $event.preventDefault();
      if (this.selectedWorkFlow && this.runDate != undefined) {
         this.isLaunching = true;
         const workflowRequest = new RunRequest();
         workflowRequest.workflowId = this.selectedWorkFlow?.id;
         workflowRequest.propertyId = this.currentProperty?.id;
         const d = DateTime.fromJSDate(this.runDate);
         workflowRequest.runDate = d.toISODate();
         this._workflowService
            .runWorkflow(workflowRequest)
            .subscribe((workflowRecord) => {
               this.workFlowRunHistory.push(workflowRecord);
               this.messageService.add({
                  severity: 'success',
                  summary: 'Workflow has be scheduled to run',
                  detail: `${
                     this.selectedWorkFlow?.name
                  } has been scheduled to run for ${this.runDate?.toDateString()}`
               });
               const refreshDate = new RefreshDataState();
               refreshDate.UnclaimedTasks = true;
               refreshDate.AllTasksMonthly = true;
               refreshDate.AllPropertyTasks = true;
               refreshDate.WorkflowHistory = true;
               this.dataStoreService.refreshTaskData(refreshDate);
               this.isLaunching = false;
            });
      }
   }

   onCheckboxClick() {
      this.generateItems();
   }

   createForm() {
      if (this.selectedWorkFlow) {
         //form/:id/create
         this.router.navigateByUrl(`/form/${this.selectedWorkFlow.id}/create`);
      }
   }

   protected readonly WorkflowType = WorkflowType;
}
