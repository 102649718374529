<p-dialog
   (close)="this.close($event)"
   (onHide)="this.close($event)"
   [(visible)]="this.isVisible"
   [autoZIndex]="true"
   [baseZIndex]="90000"
   [closable]="true"
   [style]="{ width: '36vw', height: '21vw'  }"
   header="Create Group {{this.selectedGroup}}"
>
   <form #formDir="ngForm" [formGroup]="groupForm">
      <div class="p-fluid p-grid p-pt-3">
         <div class="p-field p-col-12">
            <div class="p-grid">
							<p-dropdown
								[options]="groupedGroupNames"
								formControlName="group"
								placeholder="Select a Group"
								[group]="true">
								<ng-template let-group pTemplate="group">
									<div class="flex align-items-center">
										<span>{{ group.label }}</span>
									</div>
								</ng-template>
							</p-dropdown>
							<p class="pt-4 pb-4">
								If you were not able to find the Group that you would like from the dropdown list,
								please contact <a href= "mailto: support@tyalls.com">support</a> to have your custom Group created.
							</p>
            </div>
         </div>
         <div class="p-col-3 p-col-order-last pt-2">
            <div class="p-field">
               <p-button
								  [disabled]="!group.valid"
                  (onClick)="this.onAddGroup($event)"
                  icon="fal fa-plus"
                  label="Add"
									class="w-10rem submit-button"
               ></p-button>
            </div>
         </div>
      </div>
   </form>
</p-dialog>
