export class RowButton {
   public id: string;
   public text: string;
   public icon: string;
   public buttonCssClasses: string[];
   public divCssClasses: string[];
   public iconPosition: string;
   public isVisible: boolean;

   constructor(
      id: string,
      text: string,
      icon: string,
      buttonCssClasses: string[],
      divCssClasses: string[],
      iconPosition: string = 'left',
      isVisible: boolean = true
   ) {
      this.id = id;
      this.text = text;
      this.icon = icon;
      this.iconPosition = iconPosition;
      this.buttonCssClasses = buttonCssClasses;
      this.divCssClasses = divCssClasses;
      this.isVisible = isVisible;
   }
}
