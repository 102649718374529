import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   Output,
   SimpleChanges
} from '@angular/core';
import { Task } from '../../domain/task';
import { DatePipe } from '@angular/common';
import { Property } from '../../domain/property';
import { WidgetStoreService } from '../../services/stores/widget-store.service';
import { WidgetType } from '../../services/util/widget-type';
import { RowButton } from '../models/row-button';
import { TableParent } from '../parent/table-parent';

@Component({
   selector: 'app-workflow-table',
   templateUrl: './workflow-table.component.html',
   styleUrls: ['./workflow-table.component.scss']
})
export class WorkflowTableComponent extends TableParent implements OnChanges {
   @Input() taskList: Task[];
   selectedTasks: Task[];
   @Input() loading: boolean;
   @Input() currentProperty: Property | null;
   @Output() claimTasksEvent: EventEmitter<Task[]> = new EventEmitter();
   @Input() isClaiming: boolean;
   @Output() renderComplete = new EventEmitter();
   isCurrentlyVisible: boolean;
   headingButtons: RowButton[];
   firstRow: number;
   private readonly WidgetType = WidgetType.UnclaimedTasks;

   constructor(
      datePipe: DatePipe,
      private widgetStore: WidgetStoreService
   ) {
      super(datePipe);
      this.taskList = [];
      this.currentProperty = null;
      this.selectedTasks = [];
      this.loading = false;
      this.isClaiming = false;
      this.isCurrentlyVisible = false;
      this.firstRow = 0;

      this.headingButtons = [
         new RowButton(
            'claim_tasks',
            'Claim Selected',
            'fal fa-plus',
            [
               'p-button-rounded',
               'p-button-success',
               'p-button-outlined',
               'p-mr-2',
               'p-mb-2'
            ],
            ['p-col-12', 'p-text-right']
         )
      ];
   }

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   ngOnChanges(changes: SimpleChanges): void {
      this.firstRow = 0;
      this.widgetStore.currentVisibleWidgetPanel.subscribe((widget) => {
         this.isCurrentlyVisible = widget.type == this.WidgetType;
         this.renderComplete.emit();
      });
   }

   handleTaskType(taskType: number): string {
      switch (taskType) {
         case 0:
            return 'Daily';
         case 1:
            return 'Non-daily';
         case 2:
            return 'Private';
         case 3:
            return 'Contracts';
         case 4:
            return 'Form';
         default:
            return 'Daily';
      }
   }

   getTaskClass(taskType: number): string {
      switch (taskType) {
         case 0:
            return 'schedule-tag-daily';
         case 1:
            return 'schedule-tag-non-daily';
         case 2:
            return 'schedule-tag-private';
         case 3:
            return 'schedule-tag-contracts';
         case 4:
            return 'schedule-tag-form';
         default:
            return 'schedule-tag-daily';
      }
   }

   claimTasks() {
      this.claimTasksEvent.emit(this.selectedTasks);
      this.selectedTasks = [];
   }

   isPanelVisible(): boolean {
      return this.isCurrentlyVisible;
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'claim_tasks':
            this.claimTasks();
            break;
         default:
            break;
      }
   }
}
