<div *ngIf="userPermissions" class="layout-menu pt-4 pl-3">
   <li class="pt-2">
      <div>
         <app-menuitem
            *ngFor="let item of standardMenuItems; let i = index"
            [item]="item"
            [root]="false"
         ></app-menuitem>
      </div>
   </li>

   <li *ngIf="userPermissions?.isSuperAdmin" class="pt-2">
      <div class="menu-heading">
         <span class="menu-item-text">App Admin</span>
      </div>
      <div>
         <app-menuitem
            *ngFor="let item of appAdminItems; let i = index"
            [item]="item"
            [root]="false"
         ></app-menuitem>
      </div>
   </li>

   <li *ngIf="userPermissions.hasAudit" class="pt-2">
      <div class="menu-heading">
         <span class="menu-item-text">Reports</span>
      </div>
      <div>
         <app-menuitem
            *ngFor="let item of reportMenuItems; let i = index"
            [item]="item"
            [root]="false"
         ></app-menuitem>
      </div>
   </li>

   <li *ngIf="userPermissions?.isPropAdmin" class="pt-2">
      <div class="menu-heading">
         <span class="menu-item-text">Admin</span>
      </div>
      <div>
         <app-menuitem
            *ngFor="let item of adminMenuItems; let i = index"
            [item]="item"
            [root]="false"
         ></app-menuitem>
      </div>
   </li>
</div>
