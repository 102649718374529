import { DateTime } from 'luxon';

export class Annotation {
   id: number;
   documentId: number;
   annotationId: string;
   instantJson: string;
   createdBy: number;
   createdDate: DateTime;
   lastModifiedBy: number;
   lastModifiedDate: DateTime;

   constructor() {
      this.id = 0;
      this.documentId = 0;
      this.annotationId = '';
      this.instantJson = '';
      this.createdBy = 0;
      this.lastModifiedBy = 0;
      this.createdDate = DateTime.now();
      this.lastModifiedDate = DateTime.now();
   }
}
