<div *ngIf="this.isCurrentlyVisible" id="mention_table">
   <div class="tyalls-table-name mb-3">
      <h6 class="tyalls-table-heading">Group Configuration<span class="font-light"> - {{currentProperty?.name}}</span></h6>
   </div>
</div>

<div class="mb-3">
   <app-table-heading-row
      (mainButtonClick)="this.onButtonRowClick($event)"
      [buttons]="this.headingButtons"
      [hasButton]="true"
      [isLoading]="false"
      headingText="*Adding groups may add additional cost to your license."
   ></app-table-heading-row>
</div>

<p-table
   #dtc
   [paginator]="true"
   [rows]="20"
   [showCurrentPageReport]="true"
   [value]="groups"
   dataKey="id"
   sortField="createdDate"
   sortMode="multiple"
   styleClass="p-datatable-workflow p-datatable-gridlines p-datatable-striped p-datatable-sm"
>
   <ng-template pTemplate="header">
      <tr>
         <th>Group Name</th>
         <th>Created</th>
				 <th>Updated</th>
         <th></th>
      </tr>
   </ng-template>
   <ng-template let-group pTemplate="body">
      <tr>
         <td>
            {{ group.displayName }}
         </td>
         <td>{{ group.createdDate | DatePropertyTimezone : false :  false }}&nbsp; by <span>{{group.createdBy ?  group.createdBy : 'Tyalls Automation'}}</span></td>
				 <td>{{ group.updatedDate | DatePropertyTimezone : false :  false }}&nbsp; by <span>{{group.updatedBy ?  group.updatedBy : 'Tyalls Automation' }}</span></td>
         <td>
            <div class="flex-container">
               <button
                  class="center p-button-rounded"
                  label="{{ group.isActive ? 'Deactivate' : 'Activate'}}"
                  pButton
                  pRipple
                  type="button"
									(click)="updateGroup(group)"
               ></button>
            </div>
         </td>
      </tr>
   </ng-template>

   <ng-template pTemplate="emptymessage">
      <tr>
         <td colspan="8">No Users Found</td>
      </tr>
   </ng-template>
</p-table>
