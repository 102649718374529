import { Injectable } from '@angular/core';
import { IRefreshable } from './irefreshable';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { PropertyStoreService } from './property-store.service';
import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { DataStoreService } from '../data-store.service';
import { AutoRunSchedule } from '../../domain/auto-run-schedule';
import { AutoRunService } from '../auto-run.service';
import { LoadingState } from '../../domain/loading-state';
import { AutoRunTask } from '../../domain/auto-run-task';

@Injectable({
   providedIn: 'root'
})
export class AutoRunStoreService implements IRefreshable {
   public currentPropertyId: number | undefined;
   private _autoRunSchedules: BehaviorSubject<AutoRunSchedule[]> =
      new BehaviorSubject(new Array<AutoRunSchedule>());
   public readonly autoRunSchedules: Observable<AutoRunSchedule[]> =
      this._autoRunSchedules.asObservable();

   constructor(
      private propertyStore: PropertyStoreService,
      private autoRunService: AutoRunService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService
   ) {
      propertyStore.currentProperty.subscribe((property) => {
         this.currentPropertyId = property.id;
         this.updateData();
      });
      dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (value.AutoRun) {
            this.updateData();
         }
      });
   }

   updateData(): void {
      if (this.currentPropertyId) {
         const loadingState = new LoadingState();
         loadingState.AutoRun = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.autoRunService
            .getSchedulesForProperty(this.currentPropertyId)
            .subscribe((schedules) => {
               const loadingState = new LoadingState();
               loadingState.AutoRun = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
               this._autoRunSchedules.next(schedules);
            });
      }
   }

   updateAutorunStatus(
      status: boolean,
      propertyId: number,
      autoRunTaskId: number
   ): Observable<AutoRunTask> {
      if (this.currentPropertyId != undefined) {
         return this.autoRunService.updateAutoRunTask(
            propertyId,
            autoRunTaskId,
            status
         );
      }
      return new Observable<AutoRunTask>((subscriber: Subscriber<AutoRunTask>) =>
         subscriber.next()
      );
   }

   refreshStore(): void {
      this.updateData();
   }
}
