import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropertyStoreService } from './property-store.service';
import { PropertyService } from '../property.service';
import { User } from '../../domain/user';
import { UserService } from '../user.service';
import { Group } from '../../domain/group';
import { Role } from '../../domain/role';
import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { LoadingState } from '../../domain/loading-state';
import { IRefreshable } from './irefreshable';
import { DataStoreService } from '../data-store.service';

@Injectable({
   providedIn: 'root'
})
export class UserStoreService implements IRefreshable {
   private _users: BehaviorSubject<User[]> = new BehaviorSubject(new Array<User>());

   public readonly users: Observable<User[]> = this._users.asObservable();

   private currentPropertyId: number | undefined;

   constructor(
      private propertyStore: PropertyStoreService,
      private propertyService: PropertyService,
      private userService: UserService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService
   ) {
      propertyStore.currentProperty.subscribe((property) => {
         this.currentPropertyId = property.id;
         this.updateData();
      });
      dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (value.Users || value.Groups) {
            this.updateData();
         }
      });
   }

   updateData() {
      if (this.currentPropertyId) {
         const loadingState = new LoadingState();
         loadingState.Users = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.propertyService.getUsers(this.currentPropertyId).subscribe((users) => {
            const loadingState = new LoadingState();
            loadingState.Users = false;
            this.loadingOverlayStore.setLoadingState(loadingState);
            this._users.next(users);
         });
      }
   }

   updateEnabled(userId: string, value: boolean): Observable<User> {
      return this.userService.updateEnabled(userId, value);
   }

   addRoles(userId: string, roles: Role[]): Observable<User> {
      if (this.currentPropertyId) {
         return this.userService.addRole(userId, this.currentPropertyId, roles);
      }
      return new Observable<User>();
   }

   addGroups(userId: string, groups: Group[]): Observable<User> {
      if (this.currentPropertyId) {
         return this.userService.addGroup(userId, this.currentPropertyId, groups);
      }
      return new Observable<User>();
   }

   deleteRole(userId: string, role: Role): Observable<User> {
      if (this.currentPropertyId) {
         return this.userService.deleteRole(userId, this.currentPropertyId, role);
      }
      return new Observable<User>();
   }

   deleteGroup(userId: string, group: Group): Observable<User> {
      if (this.currentPropertyId) {
         return this.userService.deleteGroup(userId, this.currentPropertyId, group);
      }
      return new Observable<User>();
   }

   refreshStore(): void {
      this.updateData();
   }
}
