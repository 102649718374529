import {
   AfterViewInit,
   ChangeDetectorRef,
   Component,
   OnDestroy,
   OnInit
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

// Packages.
import { Chart, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Classes.
import { DataTaskChart } from '../../../domain/data-task-chart';
import { TaskReportChart } from '../../../domain/task-report-chart';
import { Property } from '../../../domain/property';
import { TaskOverviewChartOptions } from '../../../domain/task-overview-chart-options';

// Services.
import { PropertyStoreService } from '../../../services/stores/property-store.service';

// Data.
import { ReportStoreService } from '../../../services/stores/report-store.service';
import { ReportRequest } from '../../../services/request/report-request';
import { DateTime } from 'luxon';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

@Component({
   selector: 'app-tasks-overview-chart',
   templateUrl: './tasks-overview-chart.component.html',
   styleUrls: ['./tasks-overview-chart.component.scss'],
   animations: [
      trigger('aiResponseAnimation', [
         state(
            'closed',
            style({
               height: '0',
               opacity: 0,
               overflow: 'hidden'
            })
         ),
         state(
            'open',
            style({
               height: '*',
               opacity: 1
            })
         ),
         transition('closed <=> open', [animate('300ms ease-in-out')])
      ])
   ]
})
export class TasksOverviewChartComponent implements OnInit, AfterViewInit, OnDestroy {
   // Classes.
   currentProperty?: Property;
   tasksOverviewForm: FormGroup;

   // Data.
   allTasks: DataTaskChart[];
   data: TaskReportChart;
   selectedTasks: DataTaskChart[];
   taskSelection: boolean = true;
   lastRequest?: ReportRequest;

   // Chart instance.
   chart: Chart | undefined;
   options: ChartOptions<'bar'>;

   // Controls.
   aiResponse: boolean;
   selectedView: any;
   dateRange: string;
   selectedTask: number | null;

   constructor(
      private propStore: PropertyStoreService,
      private cdr: ChangeDetectorRef,
      private _reportStore: ReportStoreService
   ) {
      // Data.
      this.data = new TaskReportChart();
      this.allTasks = [];
      this.selectedTasks = [];
      this.tasksOverviewForm = new FormGroup({
         startDate: new FormControl(new Date(), Validators.required),
         finishDate: new FormControl(new Date(), Validators.required),
         dateRangeOption: new FormControl('', Validators.required)
      });
      this.propStore.currentProperty.subscribe((prop: any) => {
         this.currentProperty = prop;
      });

      // Controls.
      this.aiResponse = false;
      this.selectedView = '';
      this.dateRange = '';
      this.selectedTask = null;

      // Configure chart options.
      this.options = new TaskOverviewChartOptions().options;
   }

   ngOnInit() {
      this.updateChartData();
   }

   ngAfterViewInit() {
      this.initializeChart();
   }

   ngOnDestroy() {
      if (this.chart) {
         this.chart.destroy(); // Destroy the existing chart instance
      }
   }

   initializeChart() {
      const ctx = document.getElementById('tasksOverviewChart') as HTMLCanvasElement;
      if (ctx) {
         // Destroy the previous chart instance if it exists
         if (this.chart) {
            this.chart.destroy();
         }

         // Create a new chart instance
         this.chart = new Chart(ctx, {
            type: 'bar',
            data: this.data,
            options: this.options
         });
      }
   }

   updateChartData() {
      const labels = this.selectedTasks.map((task) => task.name);
      const data = this.selectedTasks.map((task) => task.daysToComplete);

      // Update chart data
      this.data = {
         labels: labels,
         datasets: [
            {
               label: 'Days to complete',
               backgroundColor: '#ffd8b1',
               borderColor: '#ff9e43',
               borderWidth: 2,
               data: data
            }
         ]
      };

      // If the chart exists, update it; otherwise, initialize it
      if (this.chart) {
         this.chart.data = this.data;
         this.chart.update();
      } else {
         this.initializeChart();
      }

      // Adjust the canvas height dynamically when data changes
      const ctx = document.getElementById('tasksOverviewChart') as HTMLCanvasElement;
      if (ctx) {
         // Ensure the canvas height is at least a minimum value even if only one task is present
         const minHeight = 60; // Set a minimum height per task
         const calculatedHeight = this.selectedTasks.length * 60; // Adjust multiplier as needed
         ctx.style.height = `${Math.max(calculatedHeight, minHeight)}px`;
      }

      // Trigger change detection to ensure chart updates
      this.cdr.detectChanges();
   }

   showTaskSelection() {
      this.taskSelection = !this.taskSelection;
   }

   showAiResponse() {
      this.aiResponse = !this.aiResponse;
   }

   dragStart(event: DragEvent, view: any, from: string) {
      event.dataTransfer?.setData('task', JSON.stringify({ view, from }));
   }

   drop(event: DragEvent, to: string) {
      event.preventDefault();
      const data = event.dataTransfer?.getData('task');
      if (data) {
         const { view, from } = JSON.parse(data);

         if (from === 'allTasks' && to === 'selectedTasks') {
            this.selectedTasks.push(view);
            this.allTasks = this.allTasks.filter((t) => t.name !== view.name);
         } else if (from === 'selectedTasks' && to === 'allTasks') {
            this.allTasks.push(view);
            this.selectedTasks = this.selectedTasks.filter(
               (t) => t.name !== view.name
            );
         }

         this.updateChartData();
      }
   }

   allowDrop(event: DragEvent) {
      event.preventDefault();
   }

   addAllTasks() {
      this.selectedTasks = [...this.selectedTasks, ...this.allTasks];
      this.allTasks = [];
      this.updateChartData();
   }

   resetChart() {
      this.allTasks = [...this.allTasks, ...this.selectedTasks];
      this.selectedTasks = [];
      this.updateChartData();
   }

   onTaskChange(event: any) {
      this.selectedTask = event.value.task_id;
      this.getChartData();
   }

   onDateRangeChange(range: any) {
      this.dateRange = range;
      this.getChartData();
   }

   getChartData() {
      this.allTasks = [];
      this.selectedTasks = [];
      this.updateChartData();
      this.lastRequest = new ReportRequest();
      switch (this.dateRange) {
         case 'current_month':
            this.lastRequest.start = DateTime.local().startOf('month').toISODate();
            this.lastRequest.finish = DateTime.now().toISODate();
            break;
         case 'last_month':
            this.lastRequest.start = DateTime.now()
               .minus({ months: 1 })
               .startOf('month')
               .toISODate();
            this.lastRequest.finish = DateTime.now()
               .minus({ months: 1 })
               .endOf('month')
               .toISODate();
            break;
         case 'last_week':
            this.lastRequest.start = DateTime.now()
               .minus({ weeks: 1 })
               .startOf('week')
               .toISODate();
            this.lastRequest.finish = DateTime.now()
               .minus({ weeks: 1 })
               .endOf('week')
               .toISODate();
            break;
         case 'today':
         default:
            this.lastRequest.start = DateTime.now().startOf('day').toISODate();
            this.lastRequest.finish = DateTime.now().endOf('day').toISODate();
            break;
      }

      const propId = this.currentProperty?.id ? this.currentProperty.id : 0;

      this._reportStore
         .runTaskQuery(propId, this.lastRequest)
         .subscribe((response) => {
            this.allTasks = response;
         });
   }
}
