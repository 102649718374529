<div *ngIf="tasks.length > 0; else noTasks" class="grid p-2">
   <div
      *ngFor="let task of tasks | taskSort: this.sortParameters"
      class="col-12 md:col-6 p-3 relative"
   >
      <div class="absolute flex gap-1 top-0 right-0 mr-2">
         <div *ngIf="task.hasFile" class="status-tag text-white">
            <i class="fal fa-check green fa-xl"></i>
         </div>

         <div *ngIf="this.hasUploadFile(task)" class="file-tag text-white">
            <div class="orange">Matched!</div>
         </div>

         <div class="text-white schedule-tag-daily">
            <div [className]="this.getWorkFlowClass(task)">
               {{ this.getTaskText(task) }}
            </div>
         </div>
      </div>

      <div class="card task-card cursor-pointer">
         <div (click)="goToTask(task, $event)" class="flex w-fit">
            <h6 class="text-white">
               <span class="opacity-70 font-light">Task: </span>
               <span>{{ task.name }}</span>
            </h6>
         </div>

         <div class="cursor-pointer" (click)="goToTask(task, $event)">
            <h6 class="text-white">
               <span class="opacity-70 font-light">Assigned to: </span>
               <span *ngIf="displayAssignee(task)" class="assigned-to-text">
                  {{ task.assignedDisplayName }}
               </span>
            </h6>
         </div>

         <div *ngIf="taskUploadFiles.length > 0">
            <h6
               *ngIf="hasUploadFile(task)"
               [pTooltip]="getFileName(task)"
               class="text-white"
            >
               <span class="opacity-70 font-light">File Name:</span>
               <span>{{ getFileName(task) }}</span>
            </h6>
         </div>
         <div class="flex justify-content-end mb-2">
            <div *ngIf="!task.hasFile">
               <div *ngIf="this.taskUploadFiles.length > 0; else inComplete" class="">
                  <div (click)="openDialog(task)" class="edit-button">Edit files</div>
               </div>
               <ng-template #inComplete>
                  <input
                     #fileUpload
                     (change)="onUpload($event, task.processId)"
                     class="file-input"
                     type="file"
                  />
                  <div (click)="fileUpload.click()" class="upload-button">
                     Upload file
                  </div>
               </ng-template>
            </div>
         </div>
      </div>
   </div>
</div>

<ng-template #noTasks>
   <div class="grid">
      <div class="col-12 md-4 lg-4 mt-4 elements-center py-8">
         <h5>No Tasks Found</h5>
      </div>
   </div>
</ng-template>
