import { Injectable } from '@angular/core';
import { PropertyService } from '../property.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Property } from '../../domain/property';
import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { IRefreshable } from './irefreshable';
import { LoadingState } from '../../domain/loading-state';
import { Role } from '../../domain/role';
import { datadogRum } from '@datadog/browser-rum';
import { AuthService, IdToken } from '@auth0/auth0-angular';

@Injectable({
   providedIn: 'root'
})
export class PropertyStoreService implements IRefreshable {
   private LastPropertyStorageKey: string = 'LAST_SELECTED_PROPERTY';

   private _properties: BehaviorSubject<Property[]> = new BehaviorSubject(
      new Array<Property>()
   );
   public readonly properties: Observable<Property[]> =
      this._properties.asObservable();

   private _roles: BehaviorSubject<Role[]> = new BehaviorSubject(new Array<Role>());
   public readonly roles: Observable<Role[]> = this._roles.asObservable();

   private _currentProperty: ReplaySubject<Property> = new ReplaySubject();

   public readonly currentProperty: Observable<Property> =
      this._currentProperty.asObservable();

   private _isPropertyChangeEnabled: ReplaySubject<boolean> = new ReplaySubject();
   public readonly IsPropertyChangeEnabled: Observable<boolean> =
      this._isPropertyChangeEnabled.asObservable();

   private currentPropertyId: number;

   constructor(
      private propertyService: PropertyService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      public passedAuth: AuthService
   ) {
      this.currentPropertyId = 0;
      this.getData();
   }

   public setCurrentProperty(value: Property) {
      if (value && this.currentPropertyId != value.id) {
         if (this.passedAuth.isAuthenticated$) {
            this.passedAuth.idTokenClaims$.subscribe(
               (c: IdToken | undefined | null) => {
                  if (c?.email && c?.sid) {
                     datadogRum.setUser({
                        id: c.sid,
                        email: c.email,
                        property: value.id
                     });
                  }
               }
            );
         }
         this.currentPropertyId = value.id;
         localStorage.setItem(this.LastPropertyStorageKey, JSON.stringify(value));
         this._currentProperty.next(value);
         this.propertyService
            .getRoles(this.currentPropertyId)
            .subscribe((roles: Role[]) => {
               this._roles.next(roles);
            });
      }
   }

   public setIsPropertyChangeEnabled(value: boolean) {
      this.properties.subscribe((props) => {
         // Only allow the user the change the property if they have more than one
         if (props.length > 1) {
            this._isPropertyChangeEnabled.next(value);
         } else {
            this._isPropertyChangeEnabled.next(false);
         }
      });
   }

   refreshStore(): void {
      this.getData();
   }

   private getData() {
      const loadingState = new LoadingState();
      loadingState.Properties = true;
      this.loadingOverlayStore.setLoadingState(loadingState);
      this.propertyService.get().subscribe((response: Property[]) => {
         this._properties.next(response);
         const lastProperty = localStorage.getItem(this.LastPropertyStorageKey);
         if (lastProperty) {
            const currentProp = JSON.parse(lastProperty);
            const updateProp = response.find((p) => {
               return p.id === currentProp.id;
            });
            if (updateProp) {
               this.setCurrentProperty(updateProp);
            }
         } else {
            if (response.length > 0) {
               this.setCurrentProperty(response[0]);
            }
         }
         loadingState.Properties = false;
         this.loadingOverlayStore.setLoadingState(loadingState);
      });
   }
}
