import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UploadFile } from '../../domain/uploadfile';
import { FileUpload } from 'primeng/fileupload';

@Component({
   selector: 'app-upload-workflow-file',
   templateUrl: './upload-workflow-file.component.html',
   styleUrls: ['./upload-workflow-file.component.scss']
})
export class UploadWorkflowFileComponent {
   @ViewChild('fileUploadForm')
   uploadForm: FileUpload | undefined;

   @Input()
   uploadedFiles: UploadFile[] = [];

   @Output()
   uploadAction = new EventEmitter<{ files: UploadFile[]; action: string }>();

   constructor() {}

   public clearUploadForm() {
      this.uploadForm?.clear();
   }

   // @Trent this is the old function I have combined into none new function.
   // onUpload($event: any) {
   // 	// console.log(`Uploaded files selected ${JSON.stringify($event)}`);
   // 	for (const file of $event.files) {
   // 		this.uploadedFiles.push(new UploadFile(file));
   // 	}
   // 	//This is silly TODO: Fix this and make it one event
   // 	this.uploadedFilesChange.emit(this.uploadedFiles);
   // 	this.uploadFile.emit();
   //
   // 	console.log(this.uploadedFiles);

   onUpload($event: any) {
      const files: UploadFile[] = [];
      for (const file of $event.files) {
         const uploadFile = new UploadFile(file);
         files.push(uploadFile);
         this.uploadedFiles.push(uploadFile);
      }

      this.uploadAction.emit({ files, action: 'upload' });
   }
}
