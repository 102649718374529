import {
   Component,
   EventEmitter,
   Input,
   OnChanges,
   Output,
   SimpleChanges,
   ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { GroupService } from '../../services/group.service';
import { GroupsDropdown } from '../../domain/data/groups-dropdown';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { DataStoreService } from '../../services/data-store.service';
import { SelectItem, SelectItemGroup } from 'primeng/api';
import { Group } from '../../domain/group';

@Component({
   selector: 'app-add-group-dialog',
   templateUrl: './add-group-dialog.component.html',
   styleUrls: ['./add-group-dialog.component.scss'],
   providers: [GroupsDropdown]
})
export class AddGroupDialogComponent implements OnChanges {
   @Input()
   isVisible: boolean;

   @Input()
   currentGroups: Group[];

   @Output() addGroupsDialogClose = new EventEmitter();

   groupForm: FormGroup;
   currentProperty: Property | undefined;
   selectedGroup: string | undefined;

   groupedGroupNames: SelectItemGroup[];

   constructor(
      private cdr: ChangeDetectorRef,
      private propertyStore: PropertyStoreService,
      private groupService: GroupService,
      private groupsDropdown: GroupsDropdown,
      private dataStoreService: DataStoreService
   ) {
      this.currentGroups = [];
      this.isVisible = false;
      this.groupForm = new FormGroup({
         group: new FormControl([], [Validators.required, Validators.minLength(1)])
      });
      this.currentProperty = undefined;
      this.propertyStore.currentProperty.subscribe((prop) => {
         this.currentProperty = prop;
      });
      this.groupedGroupNames = [];
   }

   get group() {
      return this.groupForm.get('group')!;
   }

   public close($event?: Event) {
      if ($event) $event.preventDefault();
      this.isVisible = false;
      this.addGroupsDialogClose.emit();
      this.cdr.detectChanges(); // Ensure UI updates after closing
   }

   public openModal() {
      this.isVisible = false; // Close it first
      this.cdr.detectChanges(); // Force UI update
      setTimeout(() => {
         this.groupForm.reset(); // Reset form to prevent old values from persisting
         this.isVisible = true; // Now open it again
         this.cdr.detectChanges(); // Ensure UI detects the changes
      }, 100);
   }

   public async onAddGroup($event: MouseEvent) {
      $event.preventDefault();
      console.log(this.group.value);
      if (this.currentProperty && this.group.valid) {
         this.groupService
            .addGroup(this.currentProperty.id, this.group.value)
            .subscribe(() => {
               const refreshDataStore = new RefreshDataState();
               refreshDataStore.Groups = true;
               this.dataStoreService.refreshTaskData(refreshDataStore);
               this.close();
            });
      }
   }

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   ngOnChanges(changes: SimpleChanges): void {
      if (changes['currentGroups']) {
         this.groupedGroupNames = [];
         for (const group of this.groupsDropdown.data) {
            const groupNameItems: SelectItem[] = [];
            for (const groupName of group.groupNames) {
               const foundGroup = this.currentGroups.find(
                  (group) =>
                     group.displayName.toLowerCase() === groupName.toLowerCase()
               );
               if (!foundGroup) {
                  groupNameItems.push({
                     value: groupName,
                     label: groupName
                  });
               }
            }
            const groupName = group.name
               .replaceAll('_', ' ')
               .replace(
                  /\w\S*/g,
                  (text) =>
                     text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
               );
            const groupSelection: SelectItemGroup = {
               label: groupName,
               value: groupName,
               items: groupNameItems
            };
            this.groupedGroupNames.push(groupSelection);
         }
      }
   }
}
