import { ChartOptions } from 'chart.js';

export class TaskOverviewChartOptions {
   options: ChartOptions<'bar'>;

   constructor() {
      this.options = {
         maintainAspectRatio: false,
         indexAxis: 'y',
         aspectRatio: 0.8,
         animation: {
            duration: 500
         },
         plugins: {
            tooltip: {
               enabled: false
            },
            legend: {
               position: 'bottom',
               labels: {
                  color: '#444444'
               }
            },
            datalabels: {
               anchor: 'center',
               align: 'center',
               color: '#e36f02',
               font: {
                  weight: 'normal'
               },
               formatter: (value: number) => `${value} days`
            }
         },
         scales: {
            x: {
               stacked: true,
               ticks: {
                  color: '#444444'
               },
               grid: {
                  color: '#d3d3d3',
                  drawBorder: false
               }
            },
            y: {
               stacked: true,
               ticks: {
                  color: '#555555'
               },
               grid: {
                  color: '#d3d3d3',
                  drawBorder: false
               }
            }
         },
         datasets: {
            bar: {
               barThickness: 30
            }
         }
      };
   }
}
