import { UploadFile } from './uploadfile';
import { Task } from './task';

export class TaskUploadFile {
   task?: Task;
   file?: UploadFile;

   constructor(task?: Task, file?: UploadFile) {
      this.task = task;
      this.file = file;
   }
}
