import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class UpdateServiceWorkerService {
   static readonly updateIntervalMinutes = 2.3;

   constructor(public updates: SwUpdate) {
      if (updates.isEnabled) {
         interval(UpdateServiceWorkerService.updateIntervalMinutes * 60000).subscribe(
            () =>
               updates
                  .checkForUpdate()
                  .then(() => console.log('checking for updates'))
         );
      }
   }

   public checkForUpdates(): void {
      this.updates.versionUpdates.subscribe((event) => {
         if (event.type === 'VERSION_READY') {
            this.promptUser();
         }
      });
   }

   private promptUser(): void {
      console.log('updating to new version');
      this.updates.activateUpdate().then(() => document.location.reload());
   }
}
