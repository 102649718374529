import { Component, OnInit } from '@angular/core';
import { FormTaskService } from '../../../services/form-task.service';
import { PropertyStoreService } from '../../../services/stores/property-store.service';
import { Property } from '../../../domain/property';
import { ActivatedRoute, Router } from '@angular/router';
import { PhobosTaskInstance } from '../../../domain/phobos-task-instance';
import { WorkflowFile } from '../../../domain/workflow-file';
import { Task } from '../../../domain/task';
import { Group } from '../../../domain/group';
import { DataStoreService } from '../../../services/data-store.service';
import { RefreshDataState } from '../../../domain/refresh-data-state';

@Component({
   selector: 'app-form-create',
   templateUrl: './form-create.component.html',
   styleUrls: ['./form-create.component.css']
})
export class FormCreateComponent implements OnInit {
   currentProperty: Property | undefined;
   formTaskInstance: PhobosTaskInstance | undefined;
   mainPdfContainer: string;
   primaryFile: WorkflowFile;
   currentTask: Task;
   isLoading: boolean;
   hasError: boolean;
   selectedGroup: Group | undefined;

   constructor(
      private formTaskService: FormTaskService,
      private _propertyStore: PropertyStoreService,
      private _route: ActivatedRoute,
      private router: Router,
      private dataStoreService: DataStoreService
   ) {
      this.mainPdfContainer = 'form-pdf-container';
      this.isLoading = true;
      this.hasError = false;
      this.primaryFile = new WorkflowFile();
      this.currentTask = new Task();
   }

   complete($event: MouseEvent) {
      $event.preventDefault();
      if (this.formTaskInstance && this.selectedGroup && this.currentProperty) {
         this.isLoading = true;
         this.formTaskService
            .assignFormTaskInstance(
               this.formTaskInstance?.id,
               this.selectedGroup?.id,
               this.currentProperty?.id
            )
            .subscribe(() => {
               const refreshDate = new RefreshDataState();
               refreshDate.ClaimedTasks = false;
               refreshDate.UnclaimedTasks = true;
               refreshDate.AllPropertyTasks = false;
               refreshDate.MyTasksMonthly = false;
               refreshDate.AllTasksMonthly = false;
               this.dataStoreService.refreshTaskData(refreshDate);
               this._propertyStore.setIsPropertyChangeEnabled(true);
               this.router.navigate(['/dashboard']);
            });
      }
   }

   groupSelected($event: Group) {
      this.selectedGroup = $event;
   }

   ngOnInit(): void {
      this.isLoading = true;
      this.hasError = false;
      this._propertyStore.setIsPropertyChangeEnabled(false);
      this._route.params.subscribe((params: any) => {
         if (params.id) {
            this._propertyStore.currentProperty.subscribe((property) => {
               this.currentProperty = property;
               this.formTaskService
                  .createNewFormTaskInstance(params.id, this.currentProperty.id)
                  .subscribe(
                     (ft: any) => {
                        this.formTaskInstance = ft;
                        this.primaryFile = new WorkflowFile();
                        this.primaryFile.storageId = ft.documentId;
                        this.primaryFile.documentId = ft.id;
                        this.primaryFile.isPrimary = true;
                        this.primaryFile.createdDate = new Date(ft.createdDate);
                        this.isLoading = false;
                        this.hasError = false;
                     },
                     () => {
                        this.formTaskInstance = undefined;
                        this.primaryFile = new WorkflowFile();
                        this.primaryFile.storageId = undefined;
                        this.primaryFile.documentId = undefined;
                        this.primaryFile.isPrimary = true;
                        this.primaryFile.createdDate = undefined;
                        this.isLoading = false;
                        this.hasError = true;
                     }
                  );
            });
         }
      });
   }
}
