<div class="p-grid">
	<div class="sm-10 md-10 lg-6">
<!--		<div class="font-bold text-lg -mt-2" *ngIf="this.isDropDownHidden">{{this.selectedProperty?.name}}</div>-->
		<div *ngIf="!this.isDropDownHidden">
		<p-dropdown
			*ngIf="!this.isDropDownHidden"
			(onChange)="selectedPropertyChange($event)"
			[(ngModel)]="this.selectedProperty"
			[disabled]="this.isDropDownDisabled"
			[options]="this.propertiesItems"
			[showClear]="false"
			name="selectedProperty"
			placeholder="Select a Property"
			[pTooltip]="this.isDropDownDisabled ? 'To switch properties, please go back a step' : undefined"
			tooltipPosition="top"
		>
			<ng-template pTemplate="selectedItem">
				<div *ngIf="selectedProperty">
					<div>{{ selectedProperty.name | trimString }}</div>
				</div>
			</ng-template>
			<ng-template let-property pTemplate="item">
				<div>
					<div>{{ property.name | trimString }}</div>
				</div>
			</ng-template>
		</p-dropdown>
		</div>
	</div>
</div>

