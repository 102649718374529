import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { RefreshDataState } from '../domain/refresh-data-state';

@Injectable({
   providedIn: 'root'
})
export class DataStoreService {
   public refreshTaskDataSubject: Subject<RefreshDataState> = new Subject();

   constructor() {}

   public refreshTaskData(state: RefreshDataState) {
      this.refreshTaskDataSubject.next(state);
   }
}
