<div class="card">
   <app-top-heading
      (createNew)="createClicked()"
      heading="Supporting Documents"
   ></app-top-heading>
   <p-table
      #dt
      [(selection)]="selectedDocument"
      [filterDelay]="0"
      [paginator]="false"
      [rowHover]="true"
      [rows]="3"
      [value]="documents"
      dataKey="id"
      styleClass="p-datatable-documents"
   >
      <ng-template pTemplate="header">
         <tr>
            <th>
               <div class="p-d-flex p-jc-between p-ai-center">Name</div>
            </th>
            <th>
               <div class="p-d-flex p-jc-between p-ai-center">Date</div>
            </th>
            <th>
               <div class="p-d-flex p-jc-between p-ai-center">Username</div>
            </th>
            <th style="width: 8rem"></th>
         </tr>
      </ng-template>
      <ng-template let-document pTemplate="body">
         <tr class="p-selectable-row">
            <td>
               <span class="p-column-title">Name</span>
               {{ document.name }}
            </td>
            <td>
               <span class="p-column-title">Date</span>
               {{ document.date | date: 'MM/dd/yyyy' }}
            </td>
            <td>
               <span class="p-column-title">Username</span>
               {{ document.uploaded_by }}
            </td>
            <td style="text-align: center">
               <button
                  class="p-button-secondary"
                  icon="pi pi-cog"
                  pButton
                  type="button"
               ></button>
            </td>
         </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
         <tr>
            <td colspan="8">No documents found.</td>
         </tr>
      </ng-template>
   </p-table>
</div>
