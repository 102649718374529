import {
   Component,
   effect,
   EventEmitter,
   Input,
   input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges,
   ViewChild
} from '@angular/core';
import { Task } from '../../domain/task';
import { UploadFile } from '../../domain/uploadfile';
import { WorkflowFile } from '../../domain/workflow-file';
import PSPDFKit, { ToolbarItem } from 'pspdfkit';
import { Annotation } from '../../domain/annotation';
import { TaskService } from '../../services/task.service';
import { UploadWorkflowFileComponent } from '../upload-workflow-file/upload-workflow-file.component';
import { environment } from '../../../environments/environment';
import * as jose from 'jose';
import DataUtils from '../../util/data-utils';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { UserPermissions } from '../../domain/security/user-permissions';
import { PermissionsService } from '../../services/permissions.service';
import { PhobosTaskInstance } from '../../domain/phobos-task-instance';
import { Router } from '@angular/router';
import { LocationStoreService } from '../../services/stores/location-store.service';
import { FormTaskService } from '../../services/form-task.service';
import { Location } from '@angular/common';

@Component({
   selector: 'app-pdf-file-panel',
   templateUrl: './pdf-file-panel.component.html',
   styleUrls: ['./pdf-file-panel.component.scss']
})
export class PdfFilePanelComponent implements OnChanges, OnInit {
   @ViewChild('fileUploadForm')
   uploadForm: UploadWorkflowFileComponent | undefined;

   @Input() hasFile: boolean;
   @Input() isLoading: boolean;
   @Input() isPrimary: boolean;
   @Input() currentTask: Task;
   @Input() containerId: string;
   @Input() currentFile: any;
   @Input() showPanelPadding: string;
   @Input() pdfPanelInFocus: boolean;
   @Input() flexCss: string;
   @Input() phobosTaskInstance: PhobosTaskInstance;

   @Output() goToNextTaskClicked = new EventEmitter();
   @Output() goToPreviousClicked = new EventEmitter();
   @Output() reviewClicked = new EventEmitter();
   @Output() uploadFile = new EventEmitter();
   @Output() groupChange = new EventEmitter();
   @Output() assignWorkflow = new EventEmitter();
   @Output() completeWorkflow = new EventEmitter();
   @Output() resendWorkflowCompletion = new EventEmitter();

   uploadedFiles: UploadFile[] = [];
   userPermissions: UserPermissions;
   taskListParams: any;
   isSubmitButtonDisabled = input(false);

   constructor(
      private taskService: TaskService,
      public permissionService: PermissionsService,
      private router: Router,
      private locationStore: LocationStoreService,
      private formTaskService: FormTaskService,
      private _location: Location
   ) {
      this.isPrimary = false;
      this.currentTask = new Task();
      this.currentFile = new WorkflowFile();
      this.hasFile = true;
      this.isLoading = false;
      this.containerId = '';
      this.userPermissions = new UserPermissions();
      this.showPanelPadding = '';
      this.pdfPanelInFocus = true;
      this.flexCss = '';
      this.phobosTaskInstance = new PhobosTaskInstance();
      effect(() => {
         console.log(this.isSubmitButtonDisabled());
      });
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      const storedParams = localStorage.getItem('tasklist_page');
      if (storedParams != null) {
         try {
            this.taskListParams = JSON.parse(storedParams);
         } catch (error) {
            console.error('Error parsing stored params:', error);
         }
      }
   }

   // Method to navigate back.
   backButton(): void {
      this._location.back();
   }

   uploadWorkflowFile() {
      this.uploadFile.emit({ event: null, file: this.uploadedFiles });
   }

   processFileForUi(file: WorkflowFile) {
      if (file.documentId) {
         this.currentFile = file;
         const filteredToolBarItems = [
            'zoom-out',
            'zoom-in',
            'zoom-mode',
            'export-pdf',
            'pager',
            'pan'
         ];
         if (file.isPrimary) {
            filteredToolBarItems.push(
               ...['rectangle', 'arrow', 'note', 'highlighter', 'ink-eraser']
            );
         } else {
            filteredToolBarItems.push('custom');
         }
         const downloadButton = {
            type: 'custom',
            id: 'download-source',
            iconClassName: 'fa-solid fa-cloud-arrow-down',
            title: 'Download',
            onPress: () => {
               if (file.data) {
                  DataUtils.downloadBase64File(file);
               } else if (file.documentId && file.id) {
                  this.formTaskService
                     .getSupportingFileSourceByFormTaskInstance(
                        file.documentId,
                        file.id
                     )
                     .subscribe((f) => {
                        DataUtils.downloadBase64File(f);
                     });
               }
            }
         } as ToolbarItem;
         const customButtons = [downloadButton];

         PSPDFKit.unload(`#${this.containerId}`);
         if (file.storageId) {
            this.loadFileFromServer(file, filteredToolBarItems, customButtons);
         } else {
            this.loadFileFromBase64String(file, filteredToolBarItems, customButtons);
         }
      }
   }

   loadFileFromBase64String(
      file: WorkflowFile,
      filteredToolBarItems: Array<string>,
      customButtons: Array<object>
   ) {
      const annotationData = [];
      if (file.isPrimary) {
         for (const annotation of file.annotations) {
            annotationData.push(JSON.parse(annotation.instantJson));
         }
      }
      PSPDFKit.load({
         serverUrl: environment.PSPDFKit.serverUrl,
         baseUrl: location.protocol + '//' + location.host + '/assets/',
         document: `data:${this.currentFile.mimeType};base64,${this.currentFile.data}`,
         container: `#${this.containerId}`,
         licenseKey: environment.PSPDFKit.key,
         instantJSON: {
            annotations: annotationData,
            format: 'https://pspdfkit.com/instant-json/v1'
         },
         initialViewState: new PSPDFKit.ViewState({
            zoom: -2
         })
      }).then((instance) => {
         // @ts-expect-error expected error
         const items = instance.toolbarItems.concat(customButtons);
         const annotationPresets = PSPDFKit.defaultAnnotationPresets;

         annotationPresets.rectangle = {
            blendMode: 'normal',
            opacity: 100,
            color: PSPDFKit.Color.BLACK,
            strokeColor: PSPDFKit.Color.BLACK,
            lineWidth: 10
         };

         annotationPresets.arrow = {
            blendMode: 'normal',
            opacity: 100,
            color: PSPDFKit.Color.BLACK,
            strokeColor: PSPDFKit.Color.BLACK,
            lineWidth: 10
         };

         annotationPresets.highlighter = {
            blendMode: 'normal',
            color: PSPDFKit.Color.YELLOW,
            strokeColor: PSPDFKit.Color.YELLOW,
            opacity: 0.3,
            lineWidth: 10
         };

         instance.setAnnotationPresets(annotationPresets);
         instance.setToolbarItems(
            items.filter((item: { type: string }) =>
               filteredToolBarItems.includes(item.type)
            )
         );
         if (this.userPermissions.forms.create) {
            instance.setToolbarItems((items) => [
               ...items,
               { type: 'form-creator' } as unknown as ToolbarItem
            ]);
         }
         instance.addEventListener('annotations.update', (createdAnnotations) => {
            this.processAnnotations(createdAnnotations);
         });
         instance.addEventListener('annotations.create', (createdAnnotations) => {
            this.processAnnotations(createdAnnotations);
         });
      });
   }

   async loadFileFromServer(
      file: WorkflowFile,
      filteredToolBarItems: Array<string>,
      customButtons: Array<object>
   ) {
      if (file.storageId) {
         let readOnly = false;
         if (this.phobosTaskInstance.completedDate) {
            readOnly = true;
         } else {
            readOnly = false;
         }

         const token = await this.prepareJwt(file.storageId);
         PSPDFKit.load({
            serverUrl: environment.PSPDFKit.serverUrl,
            baseUrl: location.protocol + '//' + location.host + '/assets/',
            container: `#${this.containerId}`,
            licenseKey: environment.PSPDFKit.key,
            documentId: file.storageId,
            authPayload: { jwt: token },
            initialViewState: new PSPDFKit.ViewState({
               zoom: -2,
               readOnly: readOnly
            }),
            instant: true,
            autoSaveMode: PSPDFKit.AutoSaveMode.IMMEDIATE
         })
            .then((instance) => {
               // @ts-expect-error expected error
               const items = instance.toolbarItems.concat(customButtons);
               const annotationPresets = PSPDFKit.defaultAnnotationPresets;
               annotationPresets.rectangle = {
                  blendMode: 'normal',
                  opacity: 100,
                  color: PSPDFKit.Color.BLACK,
                  strokeColor: PSPDFKit.Color.BLACK,
                  lineWidth: 10
               };
               annotationPresets.arrow = {
                  blendMode: 'normal',
                  opacity: 100,
                  color: PSPDFKit.Color.BLACK,
                  strokeColor: PSPDFKit.Color.BLACK,
                  lineWidth: 10
               };
               annotationPresets.highlighter = {
                  blendMode: 'normal',
                  color: PSPDFKit.Color.YELLOW,
                  strokeColor: PSPDFKit.Color.YELLOW,
                  opacity: 0.3,
                  lineWidth: 10
               };

               instance.setAnnotationPresets(annotationPresets);
               instance.setToolbarItems(
                  items.filter((item: { type: string }) =>
                     filteredToolBarItems.includes(item.type)
                  )
               );
               if (this.userPermissions.forms.create) {
                  instance.setToolbarItems((items) => [
                     ...items,
                     { type: 'form-creator' } as unknown as ToolbarItem
                  ]);
               }
               console.log('PSPDFKit server loaded');
            })
            .catch(function (error) {
               console.error(error.message);
            });
      }
   }

   async prepareJwt(documentId: string) {
      const jwtKey = environment.PSPDFKit.serverPem;
      const claims = {
         document_id: documentId,
         permissions: ['read-document', 'write', 'download']
      };

      const privateKey = await jose.importPKCS8(jwtKey, 'RS256');
      return await new jose.SignJWT(claims)
         .setProtectedHeader({ alg: 'RS256' })
         .setExpirationTime('3d')
         .sign(privateKey);
   }

   processAnnotations(createdAnnotations: any): void {
      for (const pdfAnnotation of createdAnnotations) {
         const annotation = new Annotation();
         if (this.currentFile.documentId) {
            annotation.documentId = this.currentFile.documentId;
         }
         annotation.instantJson = JSON.stringify(
            PSPDFKit.Annotations.toSerializableObject(pdfAnnotation)
         );
         annotation.annotationId = pdfAnnotation.id;

         this.taskService
            .addAnnotation(annotation, this.currentTask.processId)
            .subscribe(() => {});
      }
   }

   onReview() {
      this.reviewClicked.emit();
   }

   goToNextTask() {
      this.uploadedFiles = [];
      this.uploadForm?.clearUploadForm();
      this.goToNextTaskClicked.emit();
   }

   goToPreviousTask() {
      this.uploadedFiles = [];
      this.uploadForm?.clearUploadForm();
      this.goToPreviousClicked.emit();
   }

   isReviewableForm() {
      if (
         this.currentFile != null &&
         this.currentFile.documentId != undefined &&
         this.currentTask.isAssignedToUser &&
         this.currentTask.type == WorkflowType.Form
      ) {
         return (
            this.currentTask.isComplete === false ||
            !this.phobosTaskInstance.completedDate
         );
      }
      return false;
   }

   isReviewable() {
      if (
         this.currentFile != null &&
         this.currentFile.documentId != undefined &&
         this.currentTask.isAssignedToUser &&
         this.currentTask.type != WorkflowType.Form
      ) {
         return !this.currentTask.isComplete;
      }
      return false;
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (changes.currentFile && changes.currentFile.currentValue) {
         this.processFileForUi(changes.currentFile.currentValue);
      }
   }

   onAssign($event: any) {
      $event.preventDefault();
      this.assignWorkflow.emit($event);
   }

   onComplete($event: any) {
      $event.preventDefault();
      this.completeWorkflow.emit($event);
   }

   relayGroupChange($event: any) {
      this.groupChange.emit($event);
   }

   onResend($event: any) {
      $event.preventDefault();
      this.resendWorkflowCompletion.emit($event);
   }

   protected readonly WorkflowType = WorkflowType;
}
