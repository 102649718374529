<div *ngIf="this.isPanelVisible()" id="workflow_table">
	<div class="tyalls-table-name header-spacing-bottom">
		<h6 class="tyalls-table-heading">Unclaimed Tasks<span class="font-light"> - {{currentProperty?.name}}</span></h6>
	</div>

	<div class="header-spacing-bottom">
		<app-table-heading-row
			(mainButtonClick)="this.onButtonRowClick($event)"
			[buttons]="this.headingButtons"
			[hasButton]="true"
			[isLoading]="this.isClaiming"
			headingText="Select Tasks To Claim"
		></app-table-heading-row>
	</div>

	<p-table
		#dtc
		[(first)]="firstRow"
		[(selection)]="selectedTasks"
		[loading]="loading"
		[paginator]="true"
		[rows]="20"
		[showCurrentPageReport]="true"
		[value]="taskList"
		dataKey="processId"
		styleClass="header-spaceing-top p-datatable-tyalls p-datatable-gridlines p-datatable-striped p-datatable-sm"
	>
		<ng-template pTemplate="header">
			<tr>
				<th class="small-col">
					<p-tableHeaderCheckbox></p-tableHeaderCheckbox>
				</th>
				<th pSortableColumn="name">
					Workflow Name
					<p-sortIcon field="name"></p-sortIcon>
				</th>

				<th class="text-center" pSortableColumn="name">Type</th>
				<th pSortableColumn="assignedDisplayName">
					Group Name
					<p-sortIcon field="assignedDisplayName"></p-sortIcon>
				</th>
				<th class="date-col" pSortableColumn="dueDate">
					Due Date
					<p-sortIcon field="dueDate"></p-sortIcon>
				</th>
			</tr>
		</ng-template>

		<ng-template let-task pTemplate="body">
			<tr>
				<td class="small-col">
					<p-tableCheckbox [value]="task"></p-tableCheckbox>
				</td>
				<td class="workflow-badge-row">
					<span class="p-column-title">Name</span>

					{{ task.name }}
				</td>
				<td>
					<div class="flex justify-content-center">
						<div [ngClass]="[getTaskClass(task.type)]">
							{{ handleTaskType(task.type) }}
						</div>
					</div>
				</td>
				<td>
					<span class="p-column-title">Group Name</span>
					{{ task.assignedDisplayName }}
				</td>
				<td class="date-col">
					<span class="p-column-title">Due Date</span>
					{{ this.formatDate(task.dueDate) }}
				</td>
			</tr>
		</ng-template>



		<ng-template pTemplate="emptymessage">
			<tr>
				<td colspan="8">No tasks found</td>
			</tr>
		</ng-template>
	</p-table>
</div>
