import { Injectable } from '@angular/core';
import { Base } from './base';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Group } from '../domain/group';
import { Observable } from 'rxjs';
import { UpdateGroupRequest } from './request/update-group-request';

@Injectable({
   providedIn: 'root'
})
export class GroupService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   getGroups(propId: number): Observable<Group[]> {
      return this.http.get<Group[]>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/group`
      );
   }

   addGroup(
      propertyId: number,
      selectedGroup: string | undefined
   ): Observable<Group> {
      console.log(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propertyId)}/group`
      );
      return this.http.post<Group>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(
            propertyId
         )}/group`,
         {
            Name: selectedGroup
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   updateGroup(
      propertyId: number,
      group: Group,
      request: UpdateGroupRequest
   ): Observable<number> {
      return this.http.put<number>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(
            propertyId
         )}/group/${encodeURIComponent(group.id)}`,
         request,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }
}
