<div class="flex justify-content-between button-row">
   <div *ngFor="let button of buttons" class="{{ button.divCssClasses.join(' ') }}">
      <p-button
         (click)="this.onClick($event, button.id)"
         class="{{ button.buttonCssClasses.join(' ') }}"
         icon="{{ button.icon }}"
         label="{{ button.text }}"
      ></p-button>
   </div>
</div>
