import { Task } from '../task';
import { WorkflowType } from '../enums/workflow-type';

export class WorkflowTypeUtils {
   static GetClass(task: Task) {
      switch (task.type) {
         case WorkflowType.Daily:
            return 'workflow-daily-badge';
         case WorkflowType.NonDaily:
            return 'workflow-non-daily-badge';
         case WorkflowType.Form:
            return 'form-standard-badge';
         default:
            return 'workflow-other-badge';
      }
   }

   static GetTileClass(task: Task) {
      switch (task.type) {
         case WorkflowType.Daily:
            return 'workflow-daily-tile';
         case WorkflowType.NonDaily:
            return 'workflow-non-daily-tile';
         default:
            return 'workflow-other-tile';
      }
   }

   static GetToolTipText(task: Task) {
      switch (task.type) {
         case WorkflowType.Daily:
            return 'Daily';
         case WorkflowType.NonDaily:
            return 'Non Daily';
         case WorkflowType.Form:
            return 'Form';
         default:
            return 'Other';
      }
   }

   static GetTaskText(task: Task) {
      switch (task.type) {
         case WorkflowType.Daily:
            return 'Daily';
         case WorkflowType.NonDaily:
            return 'Non Daily';
         case WorkflowType.Form:
            return 'Form';
         default:
            return 'Other';
      }
   }
}
