import { Component } from '@angular/core';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { PhobosAiRunLog } from '../../domain/phobos-ai-run-log';
import { PropertyService } from '../../services/property.service';
import { DateTime } from 'luxon';

@Component({
   selector: 'app-ai-daily-panel',
   standalone: false,
   templateUrl: './ai-daily-panel.component.html',
   styleUrl: './ai-daily-panel.component.css'
})
export class AiDailyPanelComponent {
   aiRunLogs: PhobosAiRunLog[];
   constructor(
      private propStore: PropertyStoreService,
      private propService: PropertyService
   ) {
      this.aiRunLogs = [];
      propStore.currentProperty.subscribe((value) => {
         propService.getAiRunLog(value.id).subscribe((value) => {
            this.aiRunLogs = value;
         });
      });
   }

   taskDate() {
      return DateTime.now();
   }

   totalCompletedTaskInstances(): number {
      let completedCount = 0;
      for (const aiLog of this.aiRunLogs) {
         if (aiLog.completed) {
            completedCount++;
         }
      }
      return completedCount;
   }

   totalProcessedTaskInstances(): number {
      let processedCount = 0;
      for (const aiLog of this.aiRunLogs) {
         if (aiLog.processed) {
            processedCount++;
         }
      }
      return processedCount;
   }
}
