import { Injectable } from '@angular/core';
import { Base } from './base';
import { HttpClient } from '@angular/common/http';
import { ReportRequest } from './request/report-request';
import { environment } from '../../environments/environment';
import { AuditReportRecord } from '../domain/audit-report-record';
import { PortfolioReportRecord } from '../domain/portfolio-report-record';
import { DataTaskChart } from '../domain/data-task-chart';
import { DataStaffChart } from '../domain/data-staff-chart';

@Injectable({
   providedIn: 'root'
})
export class ReportService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   getAuditReport(propId: number, request: ReportRequest) {
      return this.http.post<AuditReportRecord[]>(
         `${environment.apiEndpoint}/report/audit/property/${encodeURIComponent(
            propId
         )}`,
         request,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getFormAuditReport(propId: number, request: ReportRequest) {
      return this.http.post<AuditReportRecord[]>(
         `${
            environment.apiEndpoint
         }/report/audit/form/property/${encodeURIComponent(propId)}`,
         request,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getPortfolioReport(request: ReportRequest) {
      return this.http.post<PortfolioReportRecord[]>(
         `${environment.apiEndpoint}/report/portfolio`,
         request,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getTaskReport(propId: number, request: ReportRequest) {
      return this.http.post<DataTaskChart[]>(
         `${environment.apiEndpoint}/report/task/property/${encodeURIComponent(
            propId
         )}`,
         request,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getStaffReport(propId: number, request: ReportRequest) {
      return this.http.post<DataStaffChart[]>(
         `${environment.apiEndpoint}/report/staff/property/${encodeURIComponent(
            propId
         )}`,
         request,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }
}
