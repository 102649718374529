import * as mime from 'mime-types';
import { WorkflowFile } from '../domain/workflow-file';

export default class DataUtils {
   static base64ToArrayBuffer(base64: string) {
      const binary_string = window.atob(base64);
      const len = binary_string.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
         bytes[i] = binary_string.charCodeAt(i);
      }
      return bytes.buffer;
   }
   static downloadBase64File(file: WorkflowFile) {
      const dataByteArray = DataUtils.base64ToArrayBuffer(file.data);
      if (file.fileName != null) {
         const mimeType = mime.lookup(file.fileName);
         if (mimeType != false) {
            const blob = new Blob([dataByteArray], { type: mimeType });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
         }
      }
   }
}
