export class SortParameters {
   propertyName: string = '';
   displayName: string;
   ascending: boolean;
   id: number;

   constructor(
      propertyName: string,
      ascending: boolean,
      displayName: string,
      id: number = 0
   ) {
      this.propertyName = propertyName;
      this.ascending = ascending;
      this.displayName = displayName;
      this.id = id;
   }
}
