<div class="flex justify-content-between w-full content-center pl-2 pr-1">
   <div class="mt-3 pr-5">
      <h6>{{ this.headingText }}</h6>
   </div>
   <div class="sm-2 md-2 lg-2 col-order-last button-alignment mt-1">
      <div *ngIf="this.isLoading; then thenBlock; else elseBlock"></div>
      <ng-template #thenBlock>
         <p-progressSpinner
            [style]="{ width: '35px', height: '35px' }"
            animationDuration=".5s"
            fill="#EEEEEE"
            strokeWidth="8"
            styleClass="custom-spinner"
         ></p-progressSpinner>
      </ng-template>

      <ng-template #elseBlock>
         <div *ngFor="let button of buttons">
            <button
               pButton
               (click)="this.onClick($event, button.id)"
               *ngIf="button.isVisible && button.iconPosition === 'right'"
               icon="{{ button.icon }}"
               iconPos="right"
               label="{{ button.text }}"
            ></button>
            <button
               pButton
               (click)="this.onClick($event, button.id)"
               *ngIf="button.isVisible && button.iconPosition === 'left'"
               icon="{{ button.icon }}"
               iconPos="left"
               label="{{ button.text }}"
            ></button>
         </div>
      </ng-template>
   </div>
</div>
