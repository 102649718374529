import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
   selector: 'app-top-heading',
   templateUrl: './top-heading.component.html',
   styleUrls: ['./top-heading.component.css']
})
export class TopHeadingComponent {
   @Output()
   createNew = new EventEmitter();

   @Input()
   hasButton: boolean;

   constructor() {
      this.hasButton = false;
   }

   _heading = '';

   @Input()
   get heading(): string {
      return this._heading;
   }

   set heading(heading: string) {
      this._heading = (heading && heading.trim()) || '<no heading set>';
   }

   onCreateClick($event: MouseEvent) {
      $event.preventDefault();
      this.createNew.emit();
   }
}
