import { Component } from '@angular/core';

@Component({
   selector: 'app-team-snapshot',
   templateUrl: './team-snapshot.component.html',
   styleUrls: ['./team-snapshot.component.css']
})
export class TeamSnapshotComponent {
   constructor() {}
}
