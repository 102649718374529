import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class MenuService {
   private menuSource = new Subject<string>();
   menuSource$ = this.menuSource.asObservable();
   private resetSource = new Subject();
   resetSource$ = this.resetSource.asObservable();

   onMenuStateChange(key: string) {
      this.menuSource.next(key);
   }
}
