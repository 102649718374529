import { Component } from '@angular/core';

@Component({
   selector: 'app-audit-basket',
   templateUrl: './audit-basket.component.html',
   styleUrls: ['./audit-basket.component.scss']
})
export class AuditBasketComponent {
   constructor() {}
}
