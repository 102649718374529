import { WorkflowType } from './enums/workflow-type';

export class Task {
   processId: number;
   taskId?: number | undefined;
   name: string;
   workFlowName?: string;
   description?: string;
   dueDate: string;
   type?: WorkflowType;
   isComplete?: boolean;
   isAssignedToUser?: boolean;
   assignedTo?: string;
   assignedDisplayName?: string;
   launchedBy?: string;
   hasFile: boolean;
   nextTask?: Task;
   prevTask?: Task;

   constructor() {
      this.processId = 0;
      this.taskId = 0;
      this.name = '';
      this.workFlowName = '';
      this.dueDate = '';
      this.type = undefined;
      this.isComplete = false;
      this.isAssignedToUser = false;
      this.assignedTo = '';
      this.assignedDisplayName = '';
      this.launchedBy = '';
      this.hasFile = false;
      this.nextTask = undefined;
      this.prevTask = undefined;
   }

   public IsDaily(): boolean {
      return this.type === WorkflowType.Daily;
   }
}
