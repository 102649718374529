import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { LoadingState } from '../../domain/loading-state';

@Injectable({
   providedIn: 'root'
})
export class LoadingOverlayStoreService {
   private _loadingState: BehaviorSubject<LoadingState> = new BehaviorSubject(
      new LoadingState()
   );

   public readonly LoadingState: Observable<LoadingState> =
      this._loadingState.asObservable();

   private _isLoadingOverlayEnabled: ReplaySubject<boolean> = new ReplaySubject();

   public readonly IsLoadingOverlayEnabled: Observable<boolean> =
      this._isLoadingOverlayEnabled.asObservable();

   private _processesLoading = new Map<string, boolean>();

   constructor() {
      this.setIsLoadingOverlayEnabled(false);
   }

   public setIsLoadingOverlayEnabledForProcess(processName: string, value: boolean) {
      if (value) {
         this._processesLoading.set(processName, true);
         this._isLoadingOverlayEnabled.next(true);
      } else {
         this._processesLoading.set(processName, false);
         let loadingState = false;

         for (const [, isLoading] of this._processesLoading) {
            if (isLoading) {
               loadingState = true;
            }
         }
         this.setIsLoadingOverlayEnabled(loadingState);
      }
   }

   public setLoadingState(state: LoadingState) {
      const currentState = this._loadingState.value;
      const nextState = { ...currentState, ...state };
      this._loadingState.next(nextState);
   }

   private setIsLoadingOverlayEnabled(value: boolean) {
      this._isLoadingOverlayEnabled.next(value);
   }
}
