import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { AppComponent } from '../../app.component';
import { DOCUMENT } from '@angular/common';
import { AuthService } from '@auth0/auth0-angular';

@Component({
   selector: 'app-topbar',
   templateUrl: './top-bar.component.html',
   styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
   @Output() menuToggle = new EventEmitter();
   console = console;
   authService: AuthService;

   constructor(
      @Inject(DOCUMENT) public document: Document,
      public auth: AuthService,
      public app: AppComponent
   ) {
      this.authService = auth;
   }

   onClickMenuToggle($event: { preventDefault: () => void }) {
      $event.preventDefault();
      this.menuToggle.emit();
   }

   signOut($event: { preventDefault: () => void }) {
      $event.preventDefault();
      localStorage.clear();
      this.authService.logout({
         logoutParams: {
            returnTo: document.location.origin
         }
      });
   }
}
