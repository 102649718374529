<div class="pb-5 pt-5">
	<p-dropdown
		(onChange)="selectedPropertyChange($event)"
		[(ngModel)]="selectedProperty"
		[options]="properties"
		[showClear]="false"
		name="selectedProperty"
		placeholder="Select a Property"
	>
		<ng-template pTemplate="selectedItem">
			<div *ngIf="selectedProperty">
				<div>
					{{ selectedProperty.name | trimString }}
				</div>
			</div>
		</ng-template>

		<ng-template let-property pTemplate="item">
			<div>
				<div>
					{{ property.name | trimString }}
				</div>
			</div>
		</ng-template>
	</p-dropdown>
</div>

