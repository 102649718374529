<div *ngIf="this.isCurrentlyVisible" id="mention_dashboard_table">
   <div class="tyalls-table-name mb-3">
      <h6 class="tyalls-table-heading">Mentions</h6>
   </div>

   <app-table-heading-row
      (mainButtonClick)="this.onButtonRowClick($event)"
      [buttons]="this.headingButtons"
      [hasButton]="true"
      [isLoading]="false"
      headingText="You can create a Mention by selecting team member names when you create a new comment on the Review page"
   ></app-table-heading-row>

   <p-table
      #dtc
      [(first)]="firstRow"
      [(selection)]="selectedMentions"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      [value]="mentions"
      dataKey="id"
      sortField="createdDate"
      sortMode="multiple"
      styleClass="mt-3 p-datatable-workflow p-datatable-gridlines p-datatable-striped p-datatable-sm"
   >
      <ng-template pTemplate="header">
         <tr>
            <th class="small-col">
               <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th class="date-col" pSortableColumn="createdDate">
               Date Created
               <p-sortIcon
                  field="createdDate"
                  pTooltip="Hold Ctrl Left click to sort by more than one column"
               ></p-sortIcon>
            </th>
            <th class="date-col" pSortableColumn="dueDate">
               Due Date
               <p-sortIcon
                  field="dueDate"
                  pTooltip="Hold Ctrl Left click to sort by more than one column"
               ></p-sortIcon>
            </th>
            <th pSortableColumn="workflowName">
               Workflow Name
               <p-sortIcon
                  field="workflowName"
                  pTooltip="Hold Ctrl Left click to sort by more than one column"
               ></p-sortIcon>
            </th>
            <th>Comment</th>
            <th>Action</th>
            <th class="date-col" pSortableColumn="readDate">
               Read Date
               <p-sortIcon
                  field="readDate"
                  pTooltip="Hold Ctrl Left click to sort by more than one column"
               ></p-sortIcon>
            </th>
         </tr>
      </ng-template>
      <ng-template let-mention pTemplate="body">
         <tr>
            <td class="small-col">
               <p-tableCheckbox [value]="mention"></p-tableCheckbox>
            </td>
            <td>
               <span class="p-column-title">Date Created</span>
               {{ this.formatDate(mention.createdDate) }}
            </td>
            <td>
               <span class="p-column-title">Due Date</span>
               {{ this.formatDate(mention.dueDate) }}
            </td>
            <td>
               <span class="p-column-title">Workflow Name</span>
               {{ mention.workflowName }}
            </td>
            <td>
               <span class="p-column-title">Comment</span>
               {{ mention.commentText }}
            </td>
            <td class="button-col">
               <button
                  (click)="goToMention(mention, $event)"
                  class="p-button-rounded"
                  label="View"
                  pButton
                  pRipple
                  type="button"
               ></button>
            </td>
            <td>
               <span class="p-column-title">Read Date</span>
               {{ this.formatDate(mention.readDate) }}
            </td>
         </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
         <tr>
            <td colspan="8">No Mentions Found</td>
         </tr>
      </ng-template>
   </p-table>
</div>
