import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { TasksComponent } from './components/tasks/tasks.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TasksDetailComponent } from './components/tasks-detail/tasks-detail.component';
import { TasksDetailPhobosComponent } from './components/tasks-detail-phobos/tasks-detail-phobos.component';
import { AuditBasketComponent } from './components/audit-basket/audit-basket.component';
import { AuditBasketListComponent } from './components/audit-basket-list/audit-basket-list.component';
import { WorkflowRunComponent } from './components/workflow-run/workflow-run.component';
import { TaskManagementComponent } from './components/tasks-admin/task-management/task-management.component';
import { AuditReportComponent } from './components/reporting/audit-report/audit-report.component';
import { PortfolioReportComponent } from './components/reporting/portfolio-report/portfolio-report.component';
import { TaskboardComponent } from './components/taskboard/taskboard.component';
import { DailyTaskListComponent } from './components/daily-task-list/daily-task-list.component';
import { PropertyUsersComponent } from './components/prop-admin/property-users/property-users.component';
import { PropertyGroupsComponent } from './components/prop-admin/property-groups/property-groups.component';
import { FormCreatorComponent } from './components/app-admin/form-creator/form-creator.component';
import { FormCreateComponent } from './components/form/form-create/form-create.component';
import { CreatePageComponent } from './components/onboarding/create-page/create-page.component';
import { TasksOverviewChartComponent } from './components/reporting/tasks-overview-chart/tasks-overview-chart.component';
import { StaffOverviewChartComponent } from './components/reporting/staff-overview-chart/staff-overview-chart.component';
import { GroupsOverviewChartComponent } from './components/reporting/groups-overview-chart/groups-overview-chart.component';
import { UserAdminComponent } from './components/app-admin/user-admin/user-admin.component';

const routes: Routes = [
   {
      path: 'create',
      component: CreatePageComponent
   },
   {
      path: 'dashboard',
      component: DashboardComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'taskboard',
      component: TaskboardComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'taskboard/:date',
      component: TaskboardComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'tasklist',
      component: DailyTaskListComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'tasks',
      component: TasksComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'tasks/:id',
      component: TasksDetailComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'form/:id/create',
      component: FormCreateComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'task/v2/instance/:id',
      component: TasksDetailPhobosComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'audit',
      component: AuditBasketListComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'audit/:id',
      component: AuditBasketComponent,
      canActivate: [AuthGuard]
   },
   {
      path: '',
      redirectTo: '/dashboard',
      pathMatch: 'full'
   },
   {
      path: 'workflow/launch',
      component: WorkflowRunComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'workflow/tasks',
      component: TaskManagementComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'report/audit',
      component: AuditReportComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'report/portfolio',
      component: PortfolioReportComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'property/admin/users',
      component: PropertyUsersComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'property/admin/groups',
      component: PropertyGroupsComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'admin/form',
      component: FormCreatorComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'admin/user',
      component: UserAdminComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'report/tasks',
      component: TasksOverviewChartComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'report/groups',
      component: GroupsOverviewChartComponent,
      canActivate: [AuthGuard]
   },
   {
      path: 'report/staff',
      component: StaffOverviewChartComponent,
      canActivate: [AuthGuard]
   }
];

@NgModule({
   imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
   exports: [RouterModule]
})
export class AppRoutingModule {}
