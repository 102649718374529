import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Widget } from '../../panel-components/models/widget';
import { WidgetType } from '../util/widget-type';
import { MentionStoreService } from './mention-store.service';
import { FollowUpStoreService } from './follow-up-store.service';
import { WorkflowTasksStoreService } from './workflow-tasks-store.service';
import { Task } from '../../domain/task';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { TaskUtils } from '../../util/task-utils';
import { PropertyStoreService } from './property-store.service';
import { Property } from '../../domain/property';

@Injectable({
   providedIn: 'root'
})
export class WidgetStoreService {
   private LastWidgetPanelStorageKey: string = 'LAST_VISIBLE_WIDGET_PANEL';

   private _widgets: BehaviorSubject<Widget[]> = new BehaviorSubject(
      new Array<Widget>()
   );

   public readonly widgets: Observable<Widget[]> = this._widgets.asObservable();

   private _currentVisible: ReplaySubject<Widget> = new ReplaySubject();

   public readonly currentVisibleWidgetPanel: Observable<Widget> =
      this._currentVisible.asObservable();

   private selectedProperty: Property | undefined;

   constructor(
      private mentionStore: MentionStoreService,
      private followUpStore: FollowUpStoreService,
      private workflowTasksStore: WorkflowTasksStoreService,
      private router: Router,
      private propStore: PropertyStoreService
   ) {
      propStore.currentProperty.subscribe((value) => {
         this.selectedProperty = value;
      });
      const claimedTasksWidget = new Widget(
         WidgetType.ClaimedTasks,
         'Outstanding Tasks',
         40,
         true,
         'fal fa-check',
         this.onTaskWidgetClick.bind(this),
         'claimed-task-widget'
      );
      const unclaimedTasksWidget = new Widget(
         WidgetType.UnclaimedTasks,
         'Unclaimed Tasks',
         40,
         true,
         'fal fa-tasks',
         this.onClickWidget.bind(this),
         'unclaimed-task-widget'
      );
      const mentionsWidget = new Widget(
         WidgetType.Mention,
         'Unread Mentions',
         10,
         true,
         'fal fa-comments',
         this.onClickWidget.bind(this),
         'mention-widget'
      );
      const followUpsWidget = new Widget(
         WidgetType.FollowUp,
         'Follow Ups',
         6,
         true,
         'fal fa-bell',
         this.onClickWidget.bind(this),
         'follow-up-widget'
      );
      this._widgets.next([
         unclaimedTasksWidget,
         followUpsWidget,
         mentionsWidget,
         claimedTasksWidget
      ]);
      mentionStore.mentions.subscribe((values) => {
         const unReadMentions = values.filter((m) => {
            return !m.readDate;
         });
         this.onWidgetUpdate(
            WidgetType.Mention,
            unReadMentions.length,
            unReadMentions
         );
      });
      followUpStore.followUps.subscribe((value) => {
         this.onWidgetUpdate(WidgetType.FollowUp, value.length, value);
      });
      workflowTasksStore.unclaimedTasks.subscribe((value) => {
         const taskData = value;
         taskData.sort((a: Task, b: Task) => {
            const aDate = DateTime.fromISO(a.dueDate).startOf('day');
            const bDate = DateTime.fromISO(b.dueDate).startOf('day');
            if (aDate > bDate) {
               return -1;
            }
            if (aDate < bDate) {
               return 1;
            }
            return 0;
         });
         this.onWidgetUpdate(WidgetType.UnclaimedTasks, value.length, taskData);
      });
      workflowTasksStore.claimedTasks.subscribe((value) => {
         const taskData = value;
         taskData.sort((a: Task, b: Task) => {
            const aDate = DateTime.fromISO(a.dueDate).startOf('day');
            const bDate = DateTime.fromISO(b.dueDate).startOf('day');
            if (aDate > bDate) {
               return -1;
            }
            if (aDate < bDate) {
               return 1;
            }
            return 0;
         });
         for (let i = 0; i < taskData.length; i++) {
            const currentTask = taskData[i];
            if (i - 1 >= 0) {
               currentTask.prevTask = taskData[i - 1];
            }
            if (i + 1 <= taskData.length) {
               currentTask.nextTask = taskData[i + 1];
            }
         }
         this.onWidgetUpdate(WidgetType.ClaimedTasks, value.length, value);
      });
      const lastProperty = localStorage.getItem(this.LastWidgetPanelStorageKey);
      if (lastProperty) {
         this.setCurrentVisibleWidgetPanel(JSON.parse(lastProperty));
      } else {
         this.setCurrentVisibleWidgetPanel(unclaimedTasksWidget);
      }
   }

   onClickWidget(widget: Widget) {
      this.setCurrentVisibleWidgetPanel(widget);
   }

   async onTaskWidgetClick(widget: Widget) {
      if (this.selectedProperty) {
         const firstTask = widget.data[0];
         await TaskUtils.goToTask(firstTask, this.router, this.selectedProperty);
      }
   }

   onWidgetUpdate(type: WidgetType, count: number, data: any[]): void {
      const widgetsCurrent = this._widgets.value;
      for (const widget of widgetsCurrent) {
         if (widget.type == type) {
            widget.count = count;
            widget.data = data;
         }
      }

      this._widgets.next(widgetsCurrent);
   }

   setCurrentVisibleWidgetPanel(value: Widget) {
      localStorage.setItem(this.LastWidgetPanelStorageKey, JSON.stringify(value));
      this._currentVisible.next(value);
   }
}
