export class TaskReportChart {
   labels: string[];
   datasets: Dataset[];

   constructor() {
      this.labels = [];
      this.datasets = [new Dataset()];
   }
}

export class Dataset {
   label: string;
   backgroundColor: string;
   borderColor: string;
   borderWidth: number;
   data: number[];

   constructor() {
      this.label = 'Hours to complete';
      this.backgroundColor = '#ffd8b1';
      this.borderColor = '#ff9e43';
      this.borderWidth = 2;
      this.data = [];
   }
}
