import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { WidgetType } from '../../services/util/widget-type';
import { DateTime } from 'luxon';
import { Router } from '@angular/router';
import { RowButton } from '../models/row-button';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { Task } from '../../domain/task';
import { WidgetStoreService } from '../../services/stores/widget-store.service';

@Component({
   selector: 'app-claimed-tasks-table',
   templateUrl: './claimed-tasks-table.component.html',
   styleUrls: ['./claimed-tasks-table.component.scss']
})
export class ClaimedTasksTableComponent implements OnInit {
   claimedTasks: Task[];
   selectedTasks: Task[];
   isCurrentlyVisible: boolean;
   headingButtons: RowButton[];
   firstRow: number;
   @Output() renderComplete = new EventEmitter();
   private readonly WidgetType = WidgetType.ClaimedTasks;

   constructor(
      private widgetStore: WidgetStoreService,
      private workflowTasksStore: WorkflowTasksStoreService,
      private router: Router
   ) {
      this.firstRow = 0;
      this.claimedTasks = [];
      this.selectedTasks = [];
      this.isCurrentlyVisible = false;
      this.headingButtons = [
         new RowButton(
            'go_to_tasks',
            'Tasks',
            'fal fa-chevron-double-right',
            [
               'p-button-rounded',
               'p-button-success',
               'p-button-outlined',
               'p-mr-2',
               'p-mb-2'
            ],
            ['p-col-12', 'p-text-right'],
            'right'
         )
      ];
   }

   ngOnInit(): void {
      this.workflowTasksStore.claimedTasks.subscribe((m) => {
         this.firstRow = 0;
         this.claimedTasks = m.sort((a: Task, b: Task) => {
            const aDate = DateTime.fromISO(a.dueDate).startOf('day');
            const bDate = DateTime.fromISO(b.dueDate).startOf('day');
            if (aDate < bDate) {
               return -1;
            }
            if (aDate > bDate) {
               return 1;
            }
            return 0;
         });
         for (let i = 0; i < this.claimedTasks.length; i++) {
            const currentTask = this.claimedTasks[i];
            if (i - 1 >= 0) {
               currentTask.prevTask = this.claimedTasks[i - 1];
            }
            if (i + 1 <= this.claimedTasks.length) {
               currentTask.nextTask = this.claimedTasks[i + 1];
            }
         }
      });
      this.widgetStore.currentVisibleWidgetPanel.subscribe((widget) => {
         this.isCurrentlyVisible = widget.type == this.WidgetType;
      });
   }

   formatDate(formatDate: DateTime): string {
      if (formatDate != null || formatDate != undefined) {
         return formatDate.toLocaleString(DateTime.DATE_MED);
      } else {
         return '';
      }
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'go_to_tasks':
            this.goToTasks();
            break;
         default:
            break;
      }
   }

   async goToTasks() {
      const firstTask = this.claimedTasks[0];
      await this.router.navigateByUrl('/tasks/' + firstTask.processId, {
         state: { task: firstTask }
      });
   }
}
