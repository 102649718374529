import { Task } from '../../domain/task';
import { WorkflowTypeUtils } from '../../domain/util/workflow-type-utils';
import { DatePipe } from '@angular/common';

export class TableParent {
   private datePipe: DatePipe;

   constructor(datePipe: DatePipe) {
      this.datePipe = datePipe;
   }

   formatDate(currentDate: Date) {
      return this.datePipe.transform(currentDate, 'yyyy-MM-dd');
   }

   getWorkFlowClass(task: Task) {
      return 'workflow-badge ' + WorkflowTypeUtils.GetClass(task);
   }

   getToolTipText(task: Task) {
      return WorkflowTypeUtils.GetToolTipText(task);
   }
}
