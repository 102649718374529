import { WorkflowType } from './enums/workflow-type';

export class FollowUp {
   id: number;
   followUpDate: string | null = null;
   processId: number;
   createdDate: string;
   isComplete: boolean;
   workflowName: string;
   type?: WorkflowType;

   constructor() {
      this.id = 0;
      this.workflowName = '';
      this.createdDate = '';
      this.isComplete = false;
      this.processId = 0;
      this.type = WorkflowType.Daily;
   }
}
