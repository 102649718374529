import { DailyTaskDetail } from './daily-task-detail';

export class DayCell {
   id: number;
   start: string;
   extendedProps: DailyTaskDetail;
   className: string;

   constructor(data: DailyTaskDetail) {
      try {
         this.id = Math.round(1000);
         this.start = data.taskDate;
         this.extendedProps = data;
         if (data.assigned > 0 && data.completed == data.assigned) {
            (this.extendedProps as any).className = 'day-cell-complete';
            this.className = 'day-cell-complete';
         } else if (data.overdue5Less > 0 || data.overdue5Less > 0) {
            (this.extendedProps as any).className = 'day-cell-overdue';
            this.className = 'day-cell-overdue';
         } else if (data.assigned > 0 && data.completed < data.assigned) {
            (this.extendedProps as any).className = 'day-cell-normal';
            this.className = 'day-cell-normal';
         } else {
            (this.extendedProps as any).className = 'day-cell';
            this.className = 'day-cell';
         }
      } catch (error) {
         this.id = 0;
         this.start = '';
         this.extendedProps = new DailyTaskDetail();
         this.className = '';
      }
   }
}
