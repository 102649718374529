import { ReportRequest } from '../services/request/report-request';
import { AuditReportRecord } from './audit-report-record';
import { DateTime } from 'luxon';

export class AuditReportHistory {
   request: ReportRequest;
   propertyId: number;
   results: AuditReportRecord[];
   runDateTime: DateTime;

   constructor(request: ReportRequest, propertyId: number) {
      this.request = request;
      this.propertyId = propertyId;
      this.results = [];
      this.runDateTime = DateTime.now();
   }
}
