import { Task } from '../../domain/task';
import { Router } from '@angular/router';
import { ITaskComponent } from '../interfaces/i-task-component';
import { TaskUtils } from '../../util/task-utils';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';

export abstract class TaskParent implements ITaskComponent {
   currentTask: Task;
   selectedProperty: Property | undefined;
   abstract changeCurrentTask(newTask: Task): void;

   protected constructor(
      protected childRouter: Router,
      protected childPropStore: PropertyStoreService
   ) {
      this.currentTask = new Task();
      childPropStore.currentProperty.subscribe((value) => {
         this.selectedProperty = value;
      });
   }

   async goToNextTask() {
      if (this.currentTask.nextTask && this.selectedProperty) {
         await TaskUtils.goToTask(
            this.currentTask.nextTask,
            this.childRouter,
            this.selectedProperty
         );
         this.changeCurrentTask(this.currentTask.nextTask);
      }
   }

   async goToPreviousTask() {
      if (this.currentTask.prevTask && this.selectedProperty) {
         await TaskUtils.goToTask(
            this.currentTask.prevTask,
            this.childRouter,
            this.selectedProperty
         );
         this.changeCurrentTask(this.currentTask.prevTask);
      }
   }
}
