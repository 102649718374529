import { Injectable } from '@angular/core';
import { PropertyService } from '../property.service';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { FollowUp } from '../../domain/follow-up';
import { PropertyStoreService } from './property-store.service';
import { IRefreshable } from './irefreshable';
import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { LoadingState } from '../../domain/loading-state';
import { DataStoreService } from '../data-store.service';

@Injectable({
   providedIn: 'root'
})
export class FollowUpStoreService implements IRefreshable {
   private _followUps: BehaviorSubject<FollowUp[]> = new BehaviorSubject(
      new Array<FollowUp>()
   );

   public readonly followUps: Observable<FollowUp[]> = this._followUps.asObservable();

   private currentPropertyId: number | undefined;

   constructor(
      private propertyStore: PropertyStoreService,
      private propertyService: PropertyService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService
   ) {
      propertyStore.currentProperty.subscribe((property) => {
         this.currentPropertyId = property.id;
         const loadingState = new LoadingState();
         loadingState.Mentions = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         propertyService.getFollowUp(property.id).subscribe((followUps) => {
            this._followUps.next(followUps);
            loadingState.Mentions = false;
            this.loadingOverlayStore.setLoadingState(loadingState);
         });
      });
      dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (value.FollowUps) {
            this.refreshStore();
         }
      });
   }

   completeFollowUps(followUpIds: number[]): Observable<number> {
      if (this.currentPropertyId != undefined) {
         console.log(`Refreshing follow ups for ${this.currentPropertyId}`);
         return this.propertyService.completeFollowUps(
            this.currentPropertyId,
            followUpIds
         );
      }
      return new Observable<number>((subscriber: Subscriber<number>) =>
         subscriber.next(0)
      );
   }

   refreshStore(): void {
      if (this.currentPropertyId != undefined) {
         const loadingState = new LoadingState();
         loadingState.Mentions = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         this.propertyService
            .getFollowUp(this.currentPropertyId)
            .subscribe((followUps) => {
               this._followUps.next(followUps);
               loadingState.Mentions = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }
}
