import { Injectable } from '@angular/core';
import { ReportService } from '../report.service';
import { ReportRequest } from '../request/report-request';
import { AuditReportHistory } from '../../domain/audit-report-history';
import { AuditReportRecord } from '../../domain/audit-report-record';
import { Observable, ReplaySubject } from 'rxjs';
import { DateTime } from 'luxon';
import { DataTaskChart } from '../../domain/data-task-chart';
import { DataStaffChart } from '../../domain/data-staff-chart';

@Injectable({
   providedIn: 'root'
})
export class ReportStoreService {
   private replayBufferLimit: number = 1;
   private _auditReportHistory: ReplaySubject<AuditReportHistory> = new ReplaySubject(
      this.replayBufferLimit
   );

   public readonly auditReportHistory: Observable<AuditReportHistory> =
      this._auditReportHistory.asObservable();

   constructor(private reportService: ReportService) {}

   runAuditQuery(
      propertyId: number,
      request: ReportRequest
   ): Observable<AuditReportRecord[]> {
      return new Observable((observer) => {
         const history = new AuditReportHistory(request, propertyId);
         return this.reportService
            .getAuditReport(propertyId, request)
            .subscribe((records) => {
               history.results = records;
               history.runDateTime = DateTime.now();
               observer.next(records);
               observer.complete();
               this._auditReportHistory.next(history);
            });
      });
   }

   runFormAuditQuery(
      propertyId: number,
      request: ReportRequest
   ): Observable<AuditReportRecord[]> {
      return new Observable((observer) => {
         const history = new AuditReportHistory(request, propertyId);
         return this.reportService
            .getFormAuditReport(propertyId, request)
            .subscribe((records) => {
               history.results = records;
               history.runDateTime = DateTime.now();
               observer.next(records);
               observer.complete();
               this._auditReportHistory.next(history);
            });
      });
   }

   runTaskQuery(
      propertyId: number,
      request: ReportRequest
   ): Observable<DataTaskChart[]> {
      return new Observable((observer) => {
         return this.reportService
            .getTaskReport(propertyId, request)
            .subscribe((records) => {
               observer.next(records);
               observer.complete();
            });
      });
   }

   runStaffQuery(
      propertyId: number,
      request: ReportRequest
   ): Observable<DataStaffChart[]> {
      return new Observable((observer) => {
         return this.reportService
            .getStaffReport(propertyId, request)
            .subscribe((records) => {
               observer.next(records);
               observer.complete();
            });
      });
   }
}
