import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RowButton } from '../models/row-button';

@Component({
   selector: 'app-button-row',
   templateUrl: './button-row.component.html',
   styleUrls: ['./button-row.component.scss']
})
export class ButtonRowComponent {
   @Output()
   buttonClick = new EventEmitter();

   @Input()
   buttons: RowButton[];

   constructor() {
      this.buttons = [];
   }

   onClick($event: MouseEvent, id: string) {
      this.buttonClick.emit(id);
   }
}
