<div class="card">
   <div class="p-grid">
      <div class="p-col-12">
         <app-pdf-file-panel
            *ngIf="!hasError"
            [containerId]="this.mainPdfContainer"
            [currentFile]="this.primaryFile"
            [currentTask]="this.currentTask"
            [hasFile]="this.primaryFile.documentId !== undefined"
            [isLoading]="this.isLoading"
            [isPrimary]="true"
         ></app-pdf-file-panel>
         <app-error-panel *ngIf="hasError"></app-error-panel>
      </div>
      <div class="p-col-11 p-offset-1" *ngIf="!hasError">
         <div class="p-grid">
            <div class="col-3">
               <app-group-drop-down
                  label="Please Select a Group to Assign the Form to"
                  (groupChange)="groupSelected($event)"
               ></app-group-drop-down>
            </div>
            <div class="col-3">
               <button
                  (click)="complete($event)"
                  class="p-button-raised p-mr-2 p-mb-2"
                  label="Create"
                  pButton
                  pRipple
                  type="button"
               ></button>
            </div>
         </div>
      </div>
   </div>
</div>
