import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

// Packages.
import { Chart, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

// Classes.
import { Property } from '../../../domain/property';
import { GroupsByTask } from '../../../domain/groups-by-task';
import { GroupsByTaskTable } from '../../../domain/groups-by-task-table';

// Services.
import { PropertyStoreService } from '../../../services/stores/property-store.service';

// Dummy data.
import { DailyRateCheck } from '../../../_dummy_data/daily-rate-check';
import { DailyRateCheckPrevious } from '../../../_dummy_data/daily-rate-check-previous';
import { CarParkOccupancy } from '../../../_dummy_data/car-park-occupancy';
import { CarParkOccupancyPrevious } from '../../../_dummy_data/car-park-occupancy-previous';
import { GroupsOverviewChartOptions } from '../../../domain/groups-overview-chart-options';

@Component({
   selector: 'app-groups-overview-chart',
   templateUrl: './groups-overview-chart.component.html',
   styleUrls: ['./groups-overview-chart.component.scss'],
   animations: [
      trigger('aiResponseAnimation', [
         state(
            'closed',
            style({
               height: '0',
               opacity: 0,
               overflow: 'hidden'
            })
         ),
         state(
            'open',
            style({
               height: '*',
               opacity: 1
            })
         ),
         transition('closed <=> open', [animate('300ms ease-in-out')])
      ])
   ]
})
export class GroupsOverviewChartComponent implements OnInit {
   @ViewChild('chartCanvas') private chartCanvas!: ElementRef;

   // Classes.
   currentProperty: Property | undefined;
   groupsForm: FormGroup;
   previousForm: FormGroup;

   // Dummy data.
   data: GroupsByTask;
   selectedTasks: GroupsByTask;
   tableData: GroupsByTaskTable;

   // Chart instance.
   options: ChartOptions<'bar'>;
   chart: Chart | undefined;

   // Controls.
   aiResponse: boolean = false;
   selectedView: string;
   groupViewOptions: {
      task_id: number;
      title: string;
   }[];
   dateRange: string;
   selectedTask: number | null;
   previous: boolean;

   constructor(private propStore: PropertyStoreService) {
      // Dummy data.
      this.data = new GroupsByTask();

      // Data
      this.selectedTasks = new GroupsByTask();
      this.previous = false;
      this.tableData = new GroupsByTaskTable();
      this.groupsForm = new FormGroup({
         startDate: new FormControl(new Date(), Validators.required),
         finishDate: new FormControl(new Date(), Validators.required),
         dateRangeOption: new FormControl('', Validators.required)
      });
      this.previousForm = new FormGroup({
         dateRangeOption: new FormControl('')
      });
      this.propStore.currentProperty.subscribe((prop: any) => {
         this.currentProperty = prop;
      });

      // Controls.
      this.selectedView = '';
      this.groupViewOptions = [
         { task_id: 0, title: 'Daily rate check' },
         { task_id: 1, title: 'Car park occupancy' }
      ];
      this.dateRange = '';
      this.selectedTask = null;

      // Configure chart options.
      this.options = new GroupsOverviewChartOptions().options;
   }

   ngOnInit() {
      this.initializeChart();
   }

   initializeChart() {
      if (this.chartCanvas) {
         const ctx = this.chartCanvas.nativeElement.getContext('2d');
         if (ctx) {
            this.chart = new Chart(ctx, {
               type: 'bar',
               data: this.data,
               options: this.options
            });
         } else {
            console.error('Chart context not found');
         }
      }
   }

   onTaskChange(event: any) {
      this.selectedTask = event.value.task_id;
      this.previous = false;
      this.getChartData();
   }

   onDateRangeChange(range: any) {
      this.dateRange = range;
      this.getChartData();
   }

   onPreviousChange() {
      this.previous = !this.previous;
      this.getChartData();
   }

   setTableData(data: any) {
      // Initialize the parseTableData object
      const parseTableData = {
         data: [] as {
            group: string;
            timeToComplete: number;
            timeToCompletePrevious: string | number; // Allow both number and string types
            total: number;
         }[]
      };

      // Get current and previous datasets
      const currentData = data.datasets[0].data;
      const previousData = data.datasets[1]?.data || [];

      // Map through the current dataset to populate the data
      currentData.forEach(
         (currentItem: { x: string; y: number[] }, index: number) => {
            const group = currentItem.x;
            const timeToComplete = parseFloat(
               (currentItem.y[1] - currentItem.y[0]).toFixed(1)
            ); // Current period duration

            // Find corresponding previous data item by index
            const previousItem = previousData[index];
            let timeToCompletePrevious: string | number = previousItem
               ? parseFloat((previousItem.y[1] - previousItem.y[0]).toFixed(1))
               : 0;

            // Replace 0 with a dash for timeToCompletePrevious if applicable
            if (timeToCompletePrevious === 0) {
               timeToCompletePrevious = '-';
            }

            // Calculate variance only if timeToCompletePrevious is a number
            const total =
               typeof timeToCompletePrevious === 'number'
                  ? parseFloat((timeToComplete - timeToCompletePrevious).toFixed(1))
                  : timeToComplete;

            // Push the data to parseTableData
            parseTableData.data.push({
               group,
               timeToComplete,
               timeToCompletePrevious,
               total
            });
         }
      );

      console.log(parseTableData);
      this.tableData = parseTableData;
   }

   getChartData() {
      // Test logic - strip this out and add proper DB query.
      if (
         this.selectedTask === 0 &&
         this.dateRange === 'current_month' &&
         !this.previous
      ) {
         this.data = new DailyRateCheck();
         this.setTableData(this.data);
      }
      if (
         this.selectedTask === 0 &&
         this.dateRange === 'current_month' &&
         this.previous
      ) {
         this.data = new DailyRateCheckPrevious();
         this.setTableData(this.data);
      }
      if (
         this.selectedTask === 1 &&
         this.dateRange === 'current_month' &&
         !this.previous
      ) {
         this.data = new CarParkOccupancy();
         this.setTableData(this.data);
      }
      if (
         this.selectedTask === 1 &&
         this.dateRange === 'current_month' &&
         this.previous
      ) {
         this.data = new CarParkOccupancyPrevious();
         this.setTableData(this.data);
      }
   }

   showAiResponse() {
      this.aiResponse = !this.aiResponse;
   }
}
