import { WidgetType } from '../../services/util/widget-type';

export class Widget {
   type: WidgetType;
   backGroundColourClass: string;
   text: string;
   data: any[];
   count: number;
   icon: string;
   isPanelVisible: boolean;
   isCountVisible: boolean;
   onClickCallback: (n: Widget) => any;

   constructor(
      type: WidgetType,
      text: string,
      count: number,
      isCountVisible: boolean,
      icon: string,
      onClickCallback: (n: Widget) => any,
      backGroundColourClass: string
   ) {
      this.onClickCallback = onClickCallback;
      this.type = type;
      this.text = text;
      this.count = count;
      this.icon = icon;
      this.isPanelVisible = false;
      this.backGroundColourClass = backGroundColourClass;
      this.data = [];
      this.isCountVisible = isCountVisible;
   }

   onClick(event: MouseEvent) {
      event.preventDefault();
      this.onClickCallback(this);
   }
}
