<div *ngIf="this.isLoading" class="progress-spinner">
   <p-progressSpinner></p-progressSpinner>
</div>

<div
   [class]="'layout-menu-dim layout-topbar-light'"
   [ngClass]="{
      'p-ripple-disabled': false,
      'layout-static': isStatic(),
      'layout-static-active': this.isMenuActive && isStatic(),
      'layout-mobile-active': this.isMobileActive()
   }"
   class="layout-wrapper"
>
   <app-topbar (menuToggle)="this.onMenuToggle()"></app-topbar>

   <div (click)="onMenuClick($event)" class="menu-wrapper">
      <div class="layout-menu-container">
         <app-menu></app-menu>
      </div>
   </div>

   <div class="layout-main">
      <div class="layout-content">
         <p-toast position="top-right"></p-toast>
				 <p-confirmDialog></p-confirmDialog>
         <div class="p-fluid p-grid">
            <div class="sm-12 md-12 lg-12 pt-4">
               <router-outlet></router-outlet>
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="staticMenuMobileActive" class="layout-mask modal-in"></div>
   <p-dialog
      [(visible)]="showTermsAndConditions"
      [closable]="false"
      [style]="{ width: '50vw' }"
      header="Terms And Conditions"
   >
      <p>
         Terms And Conditions have not been signed for your property. Please contact
         support as soon as possible.
      </p>
      <ng-template pTemplate="footer">
         <p-button
					 (click)="this.updateTermsAndConditions()"
            label="Ok"
            styleClass="p-button-text"
         ></p-button>
      </ng-template>
   </p-dialog>
</div>
