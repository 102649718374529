import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class DropDownStoreService {
   private _dropDownState: BehaviorSubject<boolean>;

   constructor() {
      this._dropDownState = new BehaviorSubject<boolean>(false); // Initial state: false
   }

   get dropDownState$(): Observable<boolean> {
      return this._dropDownState.asObservable();
   }

   setDropDownState(value: boolean): void {
      this._dropDownState.next(value);
   }
}
