<div class="flex justify-content-between h-25rem w-30rem">
   <p-dropdown
      [(ngModel)]="this.currentUploadFile"
      [options]="this.taskUploadFiles"
      [showClear]="false"
      optionLabel="file.file.name"
      placeholder="Select a File"
   >
      <ng-template pTemplate="selectedItem">
         <div *ngIf="currentUploadFile">
            <div>{{ getFileName(currentUploadFile) }}</div>

            <div class="p-col-1 p-offset-1 p-col-order-last">
               <i class="far fa-check green-tick"></i>
            </div>
         </div>
      </ng-template>
      <ng-template let-taskUploadFile pTemplate="item">
         <div>{{ getFileName(taskUploadFile) }}</div>

         <div class="p-col-1 p-offset-1 p-col-order-last">
            <i *ngIf="hasTask(taskUploadFile)" class="far fa-check gray-tick"></i>
         </div>
      </ng-template>
   </p-dropdown>

   <div>
      <button
         (click)="onClose()"
         icon="fal fa-save"
         iconPos="left"
         label="Save"
         pButton
         type="button"
      ></button>
   </div>
</div>
