import {
   AfterViewInit,
   Component,
   Inject,
   Input,
   OnInit,
   Renderer2
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { MenuService } from './services/menu.service';
import { PropertyStoreService } from './services/stores/property-store.service';
import { UpdateServiceWorkerService } from './services/update-service-worker.service';
import { RowButton } from './panel-components/models/row-button';
import { DOCUMENT, Location } from '@angular/common';
import { Router } from '@angular/router';
import { MenuStoreService } from './services/stores/menu-store.service';
import { LoadingOverlayStoreService } from './services/stores/loading-overlay-store.service';
import { PropertyService } from './services/property.service';
import { Property } from './domain/property';
import { UserStoreService } from './services/stores/user-store.service';
import { GroupStoreService } from './services/stores/group-store.service';
import { productFruits } from 'product-fruits';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../environments/environment';

declare global {
   interface Window {
      productFruits: any;
   }
}

@Component({
   selector: 'app-root',
   templateUrl: './app.component.html',
   styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
   @Input() AuthService: any;

   topbarMenuActive: boolean | undefined;
   overlayMenuActive: boolean | undefined;
   staticMenuMobileActive: boolean | undefined;
   menuClick: boolean | undefined;
   topbarItemClick: boolean | undefined;
   activeTopbarItem: any;
   documentClickListener: (() => void) | undefined;
   configActive: boolean | undefined;
   configClick: boolean | undefined;
   rightPanelActive: boolean | undefined;
   rightPanelClick: boolean | undefined;
   isMenuActive = true;
   menuHoverActive = false;
   searchClick = false;
   search = false;
   isPropertyDropdownReadOnly: boolean;
   headingButtons: RowButton[];
   isLoading: boolean;
   showTermsAndConditions: boolean;
   currentProperty: Property | undefined;

   constructor(
      public renderer: Renderer2,
      private menuService: MenuService,
      private primengConfig: PrimeNGConfig,
      private propStore: PropertyStoreService,
      private propService: PropertyService,
      private userStore: UserStoreService,
      private group: GroupStoreService,
      private sw: UpdateServiceWorkerService,
      private _location: Location,
      public _router: Router,
      private menuStore: MenuStoreService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private auth: AuthService,
      @Inject(DOCUMENT) private _document: Document
   ) {
      this.getAuth0User();
      this.showTermsAndConditions = false;
      this.menuClick = false;
      this.isPropertyDropdownReadOnly = false;
      this.isLoading = true;

      loadingOverlayStore.LoadingState.subscribe((state) => {
         this.isLoading = state.Properties;
      });

      if (this.isMobile() || this.isTablet()) {
         this.isMenuActive = false;
      }
      this.sw.checkForUpdates();
      this.headingButtons = [
         new RowButton(
            'back',
            '',
            'fal fa-arrow-left fa-1x',
            ['p-button-rounded'],
            ['p-col-1', 'p-text-left', 'p-pl-1']
         )
      ];

      this.menuStore.menuState.subscribe((value) => {
         this.isMenuActive = value;
      });
   }

   ngOnInit(): void {
      this.propStore.currentProperty.subscribe((currentProp) => {
         this.currentProperty = currentProp;
         if (!this.currentProperty.termsConditions) {
            this.showTermsAndConditions = true;
         }
      });
      this.loadPf();
   }

   getAuth0User = () => {
      this.auth.user$.subscribe((user: any) => {
         return user.nickname;
      });
   };

   // Detect browser language.
   loadPf = () => {
      let initLanguage: any = null;

      switch (navigator.language) {
         case 'fr':
            initLanguage = 'fr';
            break;
         case 'fr-FR':
            initLanguage = 'fr';
            break;
         case 'fr-fr':
            initLanguage = 'fr';
            break;
         case 'ja':
            initLanguage = 'ja';
            break;
         case 'ja-JA':
            initLanguage = 'ja';
            break;
         case 'ja-ja':
            initLanguage = 'ja';
            break;
         default:
            initLanguage = 'en';
      }

      this.auth.user$.subscribe((user: any) => {
         if (user) {
            const first = user?.metaData?.first_name;
            const last = user?.metaData?.last_name;

            const welcome_name = user?.metaData?.first_name
               ? `${first} ${last}`
               : user?.email;
            productFruits.init(
               environment.productFruit.workspaceCode,
               initLanguage,
               {
                  username: user?.email,
                  firstname: welcome_name,
                  role: user?.metaData?.has_been_through_signup,
                  signUpAt: user?.created_date
               },
               { disableLocationChangeDetection: false }
            );
         }
      });
   };

   ngAfterViewInit() {
      this.documentClickListener = this.renderer.listen('body', 'click', () => {
         if (!this.topbarItemClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
         }

         if (this.configActive && !this.configClick) {
            this.configActive = false;
         }

         if (!this.rightPanelClick) {
            this.rightPanelActive = false;
         }

         if (!this.menuClick) {
            if (this.overlayMenuActive) {
               this.overlayMenuActive = false;
            }
            if (this.staticMenuMobileActive) {
               this.staticMenuMobileActive = false;
            }

            this.menuHoverActive = false;
            this.unblockBodyScroll();
         }

         if (!this.searchClick) {
            this.search = false;
         }

         this.searchClick = false;
         this.configClick = false;
         this.topbarItemClick = false;
         this.menuClick = false;
         this.rightPanelClick = false;
      });
      if (this.isMobile() || this.isTablet()) {
         this.isMenuActive = false;
      }
   }

   isMobileActive() {
      if (this.isMobile() || this.isTablet()) {
         return this.isMenuActive;
      }
      return false;
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'back':
            this._location.back();
            break;
         default:
            break;
      }
   }

   onMenuClick($event: any) {
      this.menuClick = true;
      $event.preventDefault();
   }

   onTopbarItemClick(
      event: { preventDefault: () => void },
      item: { className: string }
   ) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === item) {
         this.activeTopbarItem = null;
      } else {
         this.activeTopbarItem = item;
      }

      if (item.className === 'search-item topbar-item') {
         this.search = !this.search;
         this.searchClick = !this.searchClick;
      }

      event.preventDefault();
   }

   onTopbarSubItemClick(event: { preventDefault: () => void }) {
      event.preventDefault();
   }

   onMenuToggle() {
      this.menuStore.setMenuState(!this.isMenuActive, true);
   }

   isStatic() {
      return this.isDesktop();
   }

   isTablet() {
      const width = window.innerWidth;
      return width <= 1024 && width > 640;
   }

   isDesktop() {
      return window.innerWidth > 1024;
   }

   isMobile() {
      return window.innerWidth <= 640;
   }

   unblockBodyScroll(): void {
      if (document.body.classList) {
         document.body.classList.remove('blocked-scroll');
      } else {
         document.body.className = document.body.className.replace(
            new RegExp(
               '(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)',
               'gi'
            ),
            ' '
         );
      }
   }

   updateTermsAndConditions() {
      this.showTermsAndConditions = false;
   }
}
