<div *ngIf="this.isCurrentlyVisible" id="follow_up_table">
   <div class="tyalls-table-name mb-3">
      <h6 class="tyalls-table-heading">Follow Ups</h6>
   </div>

   <app-table-heading-row
      (mainButtonClick)="this.onButtonRowClick($event)"
      [buttons]="this.headingButtons"
      [hasButton]="true"
      [isLoading]="false"
      headingText="You can create Follow Ups by selecting a Follow Up date when you create a new comment on the Review page"
   ></app-table-heading-row>

   <p-table
      #dtc
      [(first)]="firstRow"
      [(selection)]="selectedFollowUps"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      [value]="followUps"
      dataKey="id"
      sortField="createdDate"
      styleClass="mt-3 p-datatable-workflow p-datatable-gridlines p-datatable-striped p-datatable-sm"
   >
      <ng-template pTemplate="header">
         <tr>
            <th class="small-col">
               <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th class="date-col" pSortableColumn="createdDate">
               Date Created
               <p-sortIcon field="createdDate"></p-sortIcon>
            </th>
            <th pSortableColumn="workflowName">
               Workflow Name
               <p-sortIcon field="workflowName"></p-sortIcon>
            </th>
            <th class="date-col" pSortableColumn="followUpDate">
               Due Date
               <p-sortIcon field="followUpDate"></p-sortIcon>
            </th>
            <th>Action</th>
            <th></th>
         </tr>
      </ng-template>
      <ng-template let-followup pTemplate="body">
         <tr>
            <td class="small-col">
               <p-tableCheckbox [value]="followup"></p-tableCheckbox>
            </td>
            <td>
               <span class="p-column-title">Date Created</span>
               {{ this.formatDate(followup.createdDate) }}
            </td>
            <td>
               <span class="p-column-title">Workflow Name</span>
               {{ followup.workflowName }}
            </td>
            <td class="date-col">
               <span class="p-column-title">Due Date</span>
               {{ this.formatDate(followup.followUpDate) }}
            </td>
            <td class="action-col">
               <button
                  (click)="this.goToTask(followup, $event)"
                  class="p-button-rounded"
                  label="View"
                  pButton
                  pRipple
                  type="button"
               ></button>
            </td>
         </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
         <tr>
            <td colspan="8">No follow ups found</td>
         </tr>
      </ng-template>
   </p-table>
</div>
