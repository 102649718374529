import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Task } from '../../domain/task';
import { UploadFile } from '../../domain/uploadfile';
import { TaskService } from '../../services/task.service';
import { Router } from '@angular/router';
import { WorkflowTypeUtils } from '../../domain/util/workflow-type-utils';
import { TaskUploadFile } from '../../domain/task-upload-file';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SwapUploadFileDialogComponent } from '../../dialogs/swap-upload-file-dialog/swap-upload-file-dialog.component';
import { SortParameters } from '../../pipes/domain/sort-parameters';
import { CommonComponent } from '../../components/common/common-component';
import { TaskUtils } from '../../util/task-utils';
import { Property } from '../../domain/property';

@Component({
   selector: 'app-task-list',
   templateUrl: './task-list.component.html',
   styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent extends CommonComponent implements OnDestroy {
   @Input()
   tasks: Task[];
   @Input()
   taskUploadFiles: TaskUploadFile[];
   @Input()
   sortParameters: SortParameters;
   @Input()
   currentProperty?: Property;

   @Output()
   taskUploadFilesChange = new EventEmitter<TaskUploadFile[]>();

   isLoading: boolean;
   ref: DynamicDialogRef | undefined;

   uploadedFiles: UploadFile[] = [];

   constructor(
      private taskService: TaskService,
      private messageService: MessageService,
      private router: Router,
      public dialogService: DialogService
   ) {
      super();
      this.tasks = [];
      this.isLoading = false;
      this.taskUploadFiles = [];
      this.sortParameters = new SortParameters('name', true, 'Workflow Name');
   }

   onUpload($event: any, processId: number) {
      if ($event.target) {
         for (const file of $event.target.files) {
            this.taskService.uploadWorkflowFile(processId, file).subscribe(() => {
               this.messageService.add({
                  severity: 'success',
                  summary: 'File Uploaded for Task',
                  detail: `${processId} file has been uploaded`
               });
               //Remove file upload icon
               this.tasks.map((f) => {
                  if (f.processId == processId) {
                     f.hasFile = true;
                  }
               });
            });
         }
      }
   }

   async goToTask(taskData: Task, $event: MouseEvent) {
      $event.preventDefault();
      if (this.currentProperty) {
         await TaskUtils.goToTask(taskData, this.router, this.currentProperty);
      }
   }

   getWorkFlowClass(task: Task) {
      return WorkflowTypeUtils.GetClass(task);
   }

   getTaskText(task: Task) {
      return WorkflowTypeUtils.GetTaskText(task);
   }

   hasUploadFile(task: Task) {
      let hasFile = false;
      for (const uploadFile of this.taskUploadFiles) {
         if (task.taskId == uploadFile.task?.taskId) {
            hasFile = true;
         }
      }
      return hasFile;
   }

   getFileName(task: Task) {
      let fileName: string = '';
      for (const uploadFile of this.taskUploadFiles) {
         if (task.taskId == uploadFile.task?.taskId) {
            if (uploadFile.file) {
               fileName = uploadFile.file.file.name;
            }
         }
      }
      return fileName;
   }

   openDialog(task: Task) {
      this.ref = this.dialogService.open(SwapUploadFileDialogComponent, {
         header: 'Choose a File',
         width: '30%',
         contentStyle: { 'max-height': '500px', overflow: 'auto' },
         baseZIndex: 10000,
         data: {
            currentTask: task,
            taskUploadFiles: this.taskUploadFiles
         }
      });
      this.ref.onClose.subscribe((file: UploadFile) => {
         const updatedUploadFiles = this.taskUploadFiles;

         const uploadedFileRecord = updatedUploadFiles.findIndex(
            (f) => f.file?.file.name == file.file.name
         );
         const currentMatchedFile = updatedUploadFiles.findIndex(
            (f) => f.task?.taskId == task.taskId
         );
         if (currentMatchedFile != -1) {
            const oldFile = updatedUploadFiles[uploadedFileRecord].file;
            updatedUploadFiles[currentMatchedFile].file = file;
            updatedUploadFiles[uploadedFileRecord].file = oldFile;
         } else {
            updatedUploadFiles[uploadedFileRecord].task = task;
         }

         this.taskUploadFilesChange.emit(updatedUploadFiles);
      });
   }

   displayAssignee(task: Task) {
      if (!task.isComplete) {
         if (task.assignedTo) {
            return true;
         }
      }
      return false;
   }

   ngOnDestroy(): void {
      if (this.ref) {
         this.ref.close();
      }
   }
}
