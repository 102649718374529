import { MentionRequest } from './mention-request';

export class CommentRequest {
   createdUserId: number;
   text: string;
   documentId: number;
   mentions: MentionRequest[];

   constructor() {
      this.createdUserId = 0;
      this.text = '';
      this.documentId = 0;
      this.mentions = [];
   }
}
