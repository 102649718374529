import { DateTime } from 'luxon';

export class BaseParent {
   formatDate(currentDate: string): string {
      if (currentDate != null || currentDate != undefined) {
         const passedDate = DateTime.fromISO(currentDate);
         return passedDate.toLocaleString(DateTime.DATE_MED);
      } else {
         return '';
      }
   }
}
