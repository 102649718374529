<div class="flex justify-content-end p-2 choose-button-padding mt-8">
	<h6 class="font-600 title-text text-padding mr-3">Upload primary file</h6>

	<div>
		<p-fileUpload
			mode="basic"
			(uploadHandler)="onUpload($event)"
			[customUpload]="true"
			[multiple]="false"
			accept=".pdf, .jpg, .png, .doc, .docx, .xls, .xlsx, .csv"
			chooseLabel="Choose"
			name="workflowFiles[]"
		>
			<ng-template pTemplate="toolbar"></ng-template>
			<ng-template pTemplate="content">
				<ul *ngIf="uploadedFiles.length">
					<li *ngFor="let uploadFile of uploadedFiles">
						{{ uploadFile.file.name }} - {{ uploadFile.file.size }} bytes
					</li>
				</ul>
			</ng-template>
		</p-fileUpload>
	</div>
</div>

