export class RefreshDataState {
   public ClaimedTasks: boolean;
   public UnclaimedTasks: boolean;

   public FollowUps: boolean;
   public Mentions: boolean;

   public AllTasksMonthly: boolean;
   public MyTasksMonthly: boolean;

   public MyTasks: boolean;

   public AllPropertyTasks: boolean;

   public WorkflowHistory: boolean;

   public Users: boolean;
   public Groups: boolean;
   public AutoRun: boolean;

   public PhobosTaskTemplates: boolean;

   constructor() {
      this.ClaimedTasks = false;
      this.UnclaimedTasks = false;
      this.FollowUps = false;
      this.Mentions = false;
      this.AllTasksMonthly = false;
      this.MyTasksMonthly = false;
      this.MyTasks = false;
      this.AllPropertyTasks = false;
      this.WorkflowHistory = false;
      this.Users = false;
      this.Groups = false;
      this.AutoRun = false;
      this.PhobosTaskTemplates = false;
   }
}
