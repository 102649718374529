import { DropDownGroup } from './drop-down-group';

export class GroupsDropdown {
   data: DropDownGroup[] = [];

   constructor() {
      this.data = [
         {
            name: 'food_and_beverage',
            groupNames: [
               'Banquets Group',
               'Conference & Events Group',
               'F&B Director Group',
               'F&B Management Group',
               'F&B Supervisor Group',
               'Restaurant Group',
               'Room Service Group',
               'Outlet 1 Group',
               'Outlet 2 Group',
               'Outlet 3 Group',
               'Outlet 4 Group',
               'Outlet 5 Group',
               'Bars Group'
            ]
         },
         {
            name: 'finance',
            groupNames: [
               'Finance Director Group',
               'Finance Lead Group',
               'Finance Group',
               'Income Audit Group',
               'Financial Accountants Group',
               'Finance Assistant Group'
            ]
         },
         {
            name: 'excom',
            groupNames: [
               'Executive Assistant Group',
               'General Manager Group',
               'Hotel Manager Group',
               'ExCom Group',
               'Rooms Division Manager Group',
               'Operations Manager Group'
            ]
         },
         {
            name: 'human_resources',
            groupNames: [
               'HR Management Group',
               'HR Group',
               'HR Recruitment Group',
               'HR Services Group',
               'HR Assistant Group'
            ]
         },
         {
            name: 'it',
            groupNames: ['IT Group', 'IT Systems Group', 'IT Management Group']
         },
         {
            name: 'kitchen',
            groupNames: ['Kitchen Group', 'Purchasing Group', 'Store Person Group']
         },
         {
            name: 'maintenance',
            groupNames: ['Engineering Group', 'Maintenance Group']
         },
         {
            name: 'night_audit',
            groupNames: ['Night Manager Group', 'Night Audit Group']
         },
         {
            name: 'other',
            groupNames: [
               'Leisure Group',
               'External Auditor Group',
               'Recreation Group',
               'Spa Group'
            ]
         },
         {
            name: 'owner',
            groupNames: ['Asset Manager Group', 'Owner Group']
         },
         {
            name: 'reservations',
            groupNames: [
               'Reservations Manager Group',
               'Revenue Management Group',
               'Reservations Group'
            ]
         },
         {
            name: 'rooms',
            groupNames: [
               'Director of Rooms Group',
               'Rooms Management Group',
               'Concierge Group',
               'Front Office Group',
               'Housekeeping Group',
               'Housekeeping Mgmt Group',
               'Operations Group',
               'Reception Group'
            ]
         },
         {
            name: 'sales',
            groupNames: ['Commercial Group', 'S&M Group', 'Sales Group']
         },
         {
            name: 'corporate',
            groupNames: [
               'Corporate Finance',
               'Corporate HR',
               'Corporate IT',
               'Corporate S&M',
               'Corporate Admin',
               'Corporate Operations',
               'Corporate Procurement',
               'Corporate Revenue'
            ]
         }
      ];
   }
}
