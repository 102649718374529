import { Component, EventEmitter, Output } from '@angular/core';
import { UserStoreService } from '../../services/stores/user-store.service';
import { User } from '../../domain/user';

@Component({
   selector: 'app-user-drop-down',
   templateUrl: './user-drop-down.component.html',
   styleUrls: ['./user-drop-down.component.scss']
})
export class UserDropDownComponent {
   users: User[];
   selectedUser: User | undefined;
   @Output() userChange: EventEmitter<User> = new EventEmitter();
   constructor(private userStore: UserStoreService) {
      this.users = [];
      userStore.users.subscribe((users) => {
         this.users = users.filter((user) => {
            return user.isEnabled;
         });
      });
   }

   selectedUserChange($event: { value: User }) {
      if ($event.value) {
         this.userChange.emit($event.value);
      }
   }
}
