<div class="p-grid">
   <div class="p-col p-col-order-first">
      <h6>{{ _heading }}</h6>
   </div>
   <div *ngIf="this.hasButton" class="p-col-1 p-pr-6 p-col-order-last">
      <button
         (click)="onCreateClick($event)"
         class="p-button-rounded p-button-success p-button-outlined p-mr-2 p-mb-2"
         icon="fal fa-plus"
         pButton
         pRipple
         type="button"
      ></button>
   </div>
</div>
