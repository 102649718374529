import { Component, EventEmitter, Output, Input } from '@angular/core';

@Component({
   selector: 'app-dropdown-button',
   templateUrl: './dropdown-button.component.html',
   styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent {
   @Output() toggleDropdown: EventEmitter<void> = new EventEmitter();
   @Input() isVisible: any;

   constructor() {}

   toggle() {
      this.toggleDropdown.emit();
   }
}
