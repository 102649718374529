import { Injectable } from '@angular/core';
import { ReportService } from '../report.service';
import { Observable, ReplaySubject } from 'rxjs';
import { DateTime } from 'luxon';
import { PortfolioReportRecord } from '../../domain/portfolio-report-record';
import { PortfolioReportHistory } from '../../domain/portfolio-report-history';
import { ReportRequest } from '../request/report-request';

@Injectable({
   providedIn: 'root'
})
export class PortfolioReportStoreService {
   private replayBufferLimit: number = 1;
   private _portfolioReportHistory: ReplaySubject<PortfolioReportHistory> =
      new ReplaySubject(this.replayBufferLimit);

   public readonly portfolioReportHistory: Observable<PortfolioReportHistory> =
      this._portfolioReportHistory.asObservable();

   constructor(private reportService: ReportService) {}

   runReportQuery(request: ReportRequest): Observable<PortfolioReportRecord[]> {
      return new Observable((observer) => {
         const history = new PortfolioReportHistory(request);
         return this.reportService
            .getPortfolioReport(request)
            .subscribe((records) => {
               history.results = records;
               history.runDateTime = DateTime.now();
               observer.next(records);
               observer.complete();
               this._portfolioReportHistory.next(history);
            });
      });
   }
}
