import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';

// Packages.
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart, ChartOptions, registerables } from 'chart.js';
import { DateTime } from 'luxon';

Chart.register(...registerables);
Chart.register(ChartDataLabels);

// Classes.
import { Property } from '../../../domain/property';
import { StaffReportChart } from '../../../domain/staff-report-chart';
import { DataStaffChart } from '../../../domain/data-staff-chart';
import { StaffReportChartOptions } from '../../../domain/staff-report-chart-options';

// Services.
import { PropertyStoreService } from '../../../services/stores/property-store.service';

// Data.
import { ReportStoreService } from '../../../services/stores/report-store.service';
import { ReportRequest } from '../../../services/request/report-request';

@Component({
   selector: 'app-staff-overview-chart',
   templateUrl: './staff-overview-chart.component.html',
   styleUrls: ['./staff-overview-chart.component.scss'],
   animations: [
      trigger('aiResponseAnimation', [
         state(
            'closed',
            style({
               height: '0',
               opacity: 0,
               overflow: 'hidden'
            })
         ),
         state(
            'open',
            style({
               height: '*',
               opacity: 1
            })
         ),
         transition('closed <=> open', [animate('300ms ease-in-out')])
      ])
   ]
})
export class StaffOverviewChartComponent implements OnInit, OnDestroy {
   // Classes.
   currentProperty: Property | undefined;
   tasksOverviewForm: FormGroup;
   chartData: StaffReportChart = {
      labels: [],
      datasets: []
   };

   // Data.
   data: DataStaffChart[];
   selectedData: any[] = [];
   claim: any[] = [];
   lastRequest?: ReportRequest;

   // Chart instance.
   chart: Chart | undefined;
   options: ChartOptions<'bar'>;

   // Controls.
   aiResponse: boolean = false;
   taskSelection: boolean = true;
   selectedView: any;
   taskViewOptions: {
      task_id: number;
      title: string;
   }[];
   dateRange: string;
   selectedTask: number | null;

   constructor(
      private propStore: PropertyStoreService,
      private _reportStore: ReportStoreService
   ) {
      // Classes.
      this.currentProperty = undefined;

      // Data.
      this.data = [];
      this.claim = [...this.data];

      this.selectedView = '';
      this.tasksOverviewForm = new FormGroup({
         startDate: new FormControl(new Date(), Validators.required),
         finishDate: new FormControl(new Date(), Validators.required),
         dateRangeOption: new FormControl('', Validators.required)
      });
      this.propStore.currentProperty.subscribe((prop: any) => {
         this.currentProperty = prop;
      });
      this.taskViewOptions = [
         { task_id: 0, title: 'Daily rate check' },
         { task_id: 1, title: 'Car park occupancy' }
      ];

      // Controls.
      this.selectedView = '';
      this.taskViewOptions = [
         { task_id: 0, title: 'Daily rate check' },
         { task_id: 1, title: 'Car park occupancy' }
      ];
      this.dateRange = '';
      this.selectedTask = null;

      // Configure chart options.
      this.options = new StaffReportChartOptions().options;
   }

   ngOnInit() {
      this.createChart();
   }

   ngOnDestroy() {
      if (this.chart) {
         this.chart.destroy(); // Destroy the existing chart instance
      }
   }

   // Method to initialize the chart
   createChart(): void {
      const ctx = document.getElementById('staffChart') as HTMLCanvasElement;

      this.chart = new Chart(ctx, {
         type: 'bar',
         data: this.chartData,
         options: this.options
      });
   }

   updateChart(): void {
      const labels = this.selectedData.map((task) => task.fullName);
      const dataToClaim = this.selectedData.map((task) => task.avgTimeToClaim);
      const dataToReview = this.selectedData.map((task) => task.avgTimeToComplete);

      this.chartData = {
         labels: labels,
         datasets: [
            {
               label: 'Hours to claim',
               backgroundColor: '#b1b5ff',
               borderColor: '#575cf4',
               borderWidth: 2,
               data: dataToClaim
            },
            {
               label: 'Hours to review',
               backgroundColor: '#ffd8b1',
               borderColor: '#ff8a1c',
               borderWidth: 2,
               data: dataToReview
            }
         ]
      };

      // If chart already exists, update it; otherwise create a new one
      if (this.chart) {
         this.chart.data = this.chartData;
         this.chart.update(); // Update the chart with new data
      } else {
         this.createChart(); // Create chart if it doesn't exist yet
      }
   }

   showTaskSelection() {
      this.taskSelection = !this.taskSelection;
   }

   showAiResponse() {
      this.aiResponse = !this.aiResponse;
   }

   dragStart(event: DragEvent, view: any, from: string) {
      event.dataTransfer?.setData('task', JSON.stringify({ view, from }));
   }

   drop(event: DragEvent, to: string) {
      event.preventDefault();
      const data = event.dataTransfer?.getData('task');
      if (data) {
         const { view, from } = JSON.parse(data);

         if (from === 'allStaff' && to === 'selectedData') {
            this.selectedData.push(view);
            this.data = this.data.filter((t: any) => t.fullName !== view.fullName);
         } else if (from === 'selectedData' && to === 'allStaff') {
            this.data.push(view);
            this.selectedData = this.selectedData.filter(
               (t) => t.fullName !== view.fullName
            );
         }

         this.updateChart();
      }
   }

   allowDrop(event: DragEvent) {
      event.preventDefault();
   }

   addAllStaff() {
      this.selectedData = [...this.selectedData, ...this.data];
      this.data = [];
      this.updateChart();
   }

   resetChart() {
      this.data = [...this.selectedData, ...this.data];
      this.selectedData = [];
      this.updateChart();
   }

   onTaskChange(event: any) {
      this.selectedTask = event.value.task_id;
      this.getChartData();
   }

   onDateRangeChange(range: any) {
      this.dateRange = range;
      this.getChartData();
   }

   getChartData() {
      this.data = [];
      this.selectedData = [];
      this.updateChart();
      this.lastRequest = new ReportRequest();
      switch (this.dateRange) {
         case 'current_month':
            this.lastRequest.start = DateTime.local().startOf('month').toISODate();
            this.lastRequest.finish = DateTime.now().toISODate();
            break;
         case 'last_month':
            this.lastRequest.start = DateTime.now()
               .minus({ months: 1 })
               .startOf('month')
               .toISODate();
            this.lastRequest.finish = DateTime.now()
               .minus({ months: 1 })
               .endOf('month')
               .toISODate();
            break;
         case 'last_week':
            this.lastRequest.start = DateTime.now()
               .minus({ weeks: 1 })
               .startOf('week')
               .toISODate();
            this.lastRequest.finish = DateTime.now()
               .minus({ weeks: 1 })
               .endOf('week')
               .toISODate();
            break;
         case 'today':
         default:
            this.lastRequest.start = DateTime.now().startOf('day').toISODate();
            this.lastRequest.finish = DateTime.now().endOf('day').toISODate();
            break;
      }

      const propId = this.currentProperty?.id ? this.currentProperty.id : 0;

      this._reportStore
         .runStaffQuery(propId, this.lastRequest)
         .subscribe((response) => {
            this.data = response;
         });
   }
}
