import { Base } from './base';

export class Task extends Base {
   forms: boolean;

   constructor() {
      super('task');
      this.forms = false;
   }

   public updatePermissions(permissions: string): void {
      super.updatePermissions(permissions);
   }
}
