import {
   ChangeDetectorRef,
   Component,
   EventEmitter,
   Input,
   OnChanges,
   OnInit,
   Output,
   SimpleChanges,
   ViewChild
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, EventClickArg } from '@fullcalendar/core';
import { DayCell } from '../../domain/day-cell';
import { DateTime } from 'luxon';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { PermissionsService } from '../../services/permissions.service';
import { UserPermissions } from '../../domain/security/user-permissions';
import dayGridPlugin from '@fullcalendar/daygrid';

@Component({
   selector: 'app-task-calendar',
   templateUrl: './task-calendar.component.html',
   styleUrls: ['./task-calendar.component.scss']
})
export class TaskCalendarComponent implements OnInit, OnChanges {
   options: CalendarOptions;

   @Input() data: DayCell[];

   @Input() showForms: boolean;

   @Output() dateChange: EventEmitter<boolean> = new EventEmitter();

   @Output() dateSelect: EventEmitter<DateTime> = new EventEmitter();

   @ViewChild('calendar') calendarComponent: FullCalendarComponent | undefined;

   @Input()
   currentDate: DateTime;

   userPermissions: UserPermissions;

   constructor(
      public permissionService: PermissionsService,
      private cd: ChangeDetectorRef
   ) {
      this.userPermissions = new UserPermissions();
      this.currentDate = DateTime.now();
      this.options = {
         initialView: 'dayGridMonth',
         initialDate: this.currentDate.toJSDate(),
         plugins: [dayGridPlugin, interactionPlugin]
      };
      this.data = [];
      this.showForms = false;
   }

   ngOnChanges(changes: SimpleChanges): void {
      if (this.data != undefined) {
         this.data = Object.assign([], this.data);
         this.cd.detectChanges();
         for (const value of this.data) {
            value.extendedProps.hasFormsAccess = this.userPermissions.forms.read;
            value.extendedProps.showFormsCell = this.showForms;
         }
         if (this.calendarComponent) {
            this.calendarComponent
               .getApi()
               .getEventSources()
               .map((es) => es.refetch());
         }
      }
      const newDate = changes['currentDate'];
      if (!newDate?.firstChange && newDate?.currentValue) {
         this.calendarComponent?.getApi().gotoDate(newDate.currentValue.toJSDate());
      }
   }

   updateData(info: any, successCallback: any): void {
      try {
         if (this.data && this.data.length > 0) {
            successCallback(this.data);
         }
      } catch (e) {
         console.error(e);
      }
   }

   getEventsByMonthBefore(ev: MouseEvent) {
      ev.preventDefault();
      this.calendarComponent?.getApi().prev();
      this.dateChange.emit(false);
   }

   getEventsByMonthAfter(ev: MouseEvent) {
      ev.preventDefault();

      this.calendarComponent?.getApi().next();

      this.dateChange.emit(true);
   }

   onDateClicked(event: DateClickArg) {
      event.jsEvent.preventDefault();

      const clickedDate = DateTime.fromJSDate(event.date);
      if (this.currentDate != clickedDate) {
         this.currentDate = clickedDate;
         this.dateSelect.emit(clickedDate);
      }
   }

   onEventClicked(event: EventClickArg) {
      event.jsEvent.preventDefault();
      const clickedDate = DateTime.fromISO(event.event.extendedProps.taskDate);
      if (this.currentDate != clickedDate) {
         this.currentDate = clickedDate;
         this.dateSelect.emit(clickedDate);
      }
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this.options = {
         initialDate: this.currentDate.toJSDate(),
         plugins: [dayGridPlugin, interactionPlugin],
         views: {
            dayGridMonth: {
               dateClick: this.onDateClicked.bind(this),
               eventClick: this.onEventClicked.bind(this)
            }
         },
         initialView: 'dayGridMonth',
         headerToolbar: {
            left: 'prev',
            center: 'title',
            right: 'next'
         },
         customButtons: {
            prev: {
               text: '<',
               click: this.getEventsByMonthBefore.bind(this)
            },
            next: {
               text: '>',
               click: this.getEventsByMonthAfter.bind(this)
            }
         },
         events: this.updateData.bind(this),
         eventContent: function (arg: {
            event: {
               extendedProps: {
                  overdue: number;
                  className: string;
                  dailyCount: number;
                  nonDailyCount: number;
                  formCount: number;
                  hasFormsAccess: boolean;
                  showFormsCell: boolean;
               };
            };
         }) {
            const dayDiv = document.createElement('div');
            const icon = document.createElement('div');
            switch (arg.event.extendedProps.className) {
               case 'day-cell-overdue':
                  icon.innerHTML =
                     '<i class="fa fa-3x fa-exclamation-triangle"  pBadge value="' +
                     arg.event.extendedProps.overdue +
                     '"  severity="success" aria-hidden="true"></i>';
                  break;
               case 'day-cell-normal':
                  icon.innerHTML =
                     '<i class="fa fa-3x fa-cogs"  pBadge value="' +
                     arg.event.extendedProps.overdue +
                     '"  severity="success" aria-hidden="true"></i>';
                  break;
               case 'day-cell-complete':
                  icon.innerHTML =
                     '<i class="fa fa-3x fa-check" style="" aria-hidden="true"></i>';
                  break;
               case 'day-cell':
               default:
                  break;
            }
            dayDiv.append(icon);
            if (arg.event.extendedProps.className !== 'day-cell') {
               const tableDiv = document.createElement('div');
               tableDiv.className = 'daily-stats-table';
               const table = document.createElement('table');

               const dailies = document.createElement('td');
               const dailyBadge = document.createElement('span');
               dailyBadge.className = 'workflow-dot workflow-dot-daily';
               dailyBadge.innerHTML = `${arg.event.extendedProps.dailyCount}`;
               dailies.append(dailyBadge);

               const noDailies = document.createElement('td');
               const nonDailyBadge = document.createElement('span');
               nonDailyBadge.className = 'workflow-dot workflow-dot-non-daily';
               nonDailyBadge.innerHTML = `${arg.event.extendedProps.nonDailyCount}`;
               noDailies.append(nonDailyBadge);
               const forms = document.createElement('td');
               if (
                  arg.event.extendedProps.hasFormsAccess &&
                  arg.event.extendedProps.showFormsCell
               ) {
                  const formsBadge = document.createElement('span');
                  formsBadge.className = 'workflow-dot form-dot';
                  formsBadge.innerHTML = `${arg.event.extendedProps.formCount}`;
                  forms.append(formsBadge);
               }

               const row1 = document.createElement('tr');
               row1.append(dailies);
               row1.append(noDailies);
               if (arg.event.extendedProps.hasFormsAccess) {
                  row1.append(forms);
               }
               table.append(row1);

               tableDiv.append(table);
               dayDiv.append(tableDiv);
            }
            const arrayOfDomNodes = [dayDiv];
            return { domNodes: arrayOfDomNodes };
         }
      };
   }
}
