import { ChartOptions } from 'chart.js';

export class GroupsOverviewChartOptions {
   options: ChartOptions<'bar'>;

   constructor() {
      this.options = {
         responsive: true,
         layout: {
            padding: {
               top: 20, // Adjust the top padding as needed
               bottom: 20
            }
         },
         plugins: {
            legend: {
               display: true,
               position: 'bottom'
            },
            tooltip: {
               callbacks: {
                  label: function (context: any) {
                     const dataPoint = context.raw;
                     const duration = dataPoint.y[1] - dataPoint.y[0];
                     return `Stage: ${duration.toFixed(1)} hours`;
                  }
               }
            },
            datalabels: {
               display: true,
               align: 'end',
               anchor: 'end',
               formatter: (value: any) => {
                  const difference = value.y[1] - value.y[0];
                  return `${difference.toFixed(1)}`;
               },
               color: (context: any) => {
                  return context.dataset.borderColor;
               },
               font: {
                  weight: 'bold',
                  size: 12
               }
            }
         },
         scales: {
            x: {
               beginAtZero: true
               // categoryPercentage: 0.8, // Adjust the width of the bars within each category
               // barPercentage: 0.9 // Adjust the width of the bars themselves
            },
            y: {
               beginAtZero: true
            }
         }
      };
   }
}
