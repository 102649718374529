import { Component, OnInit } from '@angular/core';
import { Property } from '../../domain/property';
import { DateTime } from 'luxon';
import { DayCell } from '../../domain/day-cell';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { Location } from '@angular/common';
import { RowButton } from '../../panel-components/models/row-button';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskListType } from '../../domain/enums/task-list-type';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { LoadingOverlayStoreService } from '../../services/stores/loading-overlay-store.service';
import { DataStoreService } from '../../services/data-store.service';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { PermissionsService } from '../../services/permissions.service';
import { UserPermissions } from '../../domain/security/user-permissions';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';

@Component({
   selector: 'app-tasks',
   templateUrl: './tasks.component.html',
   styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit {
   selectedDate: DateTime;
   isLoadingTasks: boolean;
   calendarViewData: DayCell[];
   myDailyTasks: DayCell[];
   allTasks: DayCell[];
   calendarCurrentMonth: DateTime;
   currentProperty: Property | null;
   isLoadingAssignedTasks: boolean;
   headingButtons: RowButton[];
   currentlySelectedType: TaskListType | undefined;
   isLoadingAllTasks: boolean;
   isLoading: boolean;
   userPermissions: UserPermissions;
   toggleFormsCells: boolean;

   constructor(
      private propStore: PropertyStoreService,
      private workFlowTasksStore: WorkflowTasksStoreService,
      private _location: Location,
      private _route: ActivatedRoute,
      private _router: Router,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService,
      public permissionService: PermissionsService,
      private dropDownStore: DropDownStoreService
   ) {
      this.selectedDate = DateTime.now();
      this.userPermissions = new UserPermissions();
      this.isLoadingTasks = false;
      this.isLoadingAllTasks = false;
      this.isLoading = true;
      this.toggleFormsCells = false;
      this.myDailyTasks = [];
      this.allTasks = [];
      this.calendarViewData = [];
      this.calendarCurrentMonth = DateTime.local(
         DateTime.now().year,
         DateTime.now().month,
         1
      );
      this.currentProperty = null;
      this.isLoadingAssignedTasks = false;
      this.headingButtons = [
         new RowButton(
            'back',
            '',
            'fal fa-arrow-left fa-1x',
            ['p-button-rounded'],
            ['p-col-1', 'p-text-left', 'p-pl-1']
         )
      ];
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this._route.queryParams.subscribe((params: any) => {
         if (params.type) {
            this.toggleData(Number(params.type));
         } else {
            this.toggleData(1);
            this.updateQueryParameters();
         }
         if (params.selectedMonth) {
            this.calendarCurrentMonth = DateTime.fromISO(params.selectedMonth);
         } else {
            this.updateQueryParameters();
         }
         this.propStore.setIsPropertyChangeEnabled(true);
         this.propStore.currentProperty.subscribe((value) => {
            if (this.currentlySelectedType) {
               this.toggleData(this.currentlySelectedType);
            }
            this.onChangeProperty(value);
         });
         this.getTasks();
      });
      this.updateDropDownState(false);
      this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
         this.isLoading = !(!loadingState.MyAssigned && !loadingState.AllTasks);
      });
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   getTasks() {
      this.workFlowTasksStore.myDailyTasksBySelectedDate.subscribe((data) => {
         this.myDailyTasks = data.map((m) => new DayCell(m));
         if (this.currentlySelectedType) {
            this.toggleData(this.currentlySelectedType);
         }
         this.isLoadingTasks = false;
      });

      this.workFlowTasksStore.allDailyTasksBySelectedDate.subscribe((data) => {
         this.allTasks = data.map((m) => new DayCell(m));
         if (this.currentlySelectedType) {
            this.toggleData(this.currentlySelectedType);
         }
         this.isLoadingAllTasks = false;
      });
   }

   onChangeDate(addMonth: boolean) {
      if (addMonth) {
         this.calendarCurrentMonth = this.calendarCurrentMonth.plus({
            months: 1
         });
      } else {
         this.calendarCurrentMonth = this.calendarCurrentMonth.minus({
            months: 1
         });
      }
      this.calendarCurrentMonth = this.calendarCurrentMonth.set({
         day: 1
      });
      this.updateQueryParameters();
      const refreshDataStore = new RefreshDataState();
      this.workFlowTasksStore.setMyTasksDate(this.calendarCurrentMonth.toJSDate());
      refreshDataStore.MyTasksMonthly = true;
      refreshDataStore.AllTasksMonthly = true;
      this.dataStoreService.refreshTaskData(refreshDataStore);
   }

   updateQueryParameters() {
      this._router.navigate(['.'], {
         relativeTo: this._route,
         queryParams: {
            selectedMonth: this.calendarCurrentMonth.toISODate(),
            type: this.currentlySelectedType
         }
      });
   }

   onChangeProperty(property: Property) {
      if (this.currentProperty?.id !== property.id) {
         this.currentProperty = property;
      }
   }

   async onDateSelect(selectedDate: DateTime) {
      this.selectedDate = selectedDate;
      await this._router.navigate(['tasklist'], {
         queryParams: {
            selecteddate: this.selectedDate.toISODate(),
            listType: this.currentlySelectedType
         }
      });
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'back':
            this._location.back();
            break;
         default:
            break;
      }
   }

   onMenuRowButtonClick($event: MouseEvent, type: number) {
      this.toggleData(type);
      this.updateQueryParameters();
   }

   toggleData(type: number) {
      switch (type) {
         case 1:
            this.calendarViewData = this.myDailyTasks;
            this.currentlySelectedType = TaskListType.AssignedTasks;
            this.toggleFormsCells = true;
            break;
         case 3:
            this.currentlySelectedType = TaskListType.AllTasks;
            this.calendarViewData = this.allTasks;
            this.toggleFormsCells = this.userPermissions.forms.admin;
            break;
         default:
            console.log('Unknown type');
            break;
      }
   }
}
