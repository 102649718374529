import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { SearchService } from '../../services/search.service';
import { Property } from '../../domain/property';
import { Task } from '../../domain/task';

import { PhobosTaskInstance } from '../../domain/phobos-task-instance';

@Component({
   selector: 'app-search-results',
   templateUrl: './search-results.component.html',
   styleUrl: './search-results.component.scss'
})
export class SearchResultsComponent {
   searchTerm: string = '';
   isLoading: boolean = false;
   tasks: Task[] = [];
   constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private propStore: PropertyStoreService,
      private searchService: SearchService
   ) {
      this.activatedRoute.queryParams.subscribe((params: any) => {
         if (params.searchTerm) {
            this.searchTerm = params.searchTerm;
            this.getTasks();
         }
      });
   }

   private getTasks() {
      this.isLoading = true;
      this.propStore.currentProperty.subscribe((prop: Property) => {
         this.tasks = [];
         if (prop) {
            this.searchService
               .getTasksBySearchTerm(this.searchTerm, prop.id)
               .subscribe({
                  next: (foundTasks: PhobosTaskInstance[]) => {
                     this.isLoading = false;
                     this.tasks = foundTasks.map((pi) => {
                        const result = new Task();
                        result.processId = pi.id;
                        result.type = pi.type;
                        result.name = pi.name;
                        result.description = pi.description;
                        result.isAssignedToUser = pi.isAssignedToUser;
                        result.dueDate = pi.createdDate;
                        result.isComplete = this.isTaskComplete(pi);
                        return result;
                     });
                  },
                  error: () => {
                     this.isLoading = false;
                  }
               });
         }
      });
   }

   private isTaskComplete(task: PhobosTaskInstance): boolean {
      return task.completedDate !== null && task.completedDate !== undefined;
   }

   updateQueryParameters() {
      if (this.searchTerm) {
         this.router.navigate(['.'], {
            relativeTo: this.activatedRoute,
            queryParams: {
               searchTerm: this.searchTerm
            }
         });
      }
   }
   search() {
      this.updateQueryParameters();
      this.getTasks();
   }
}
