import { Component, EventEmitter, Output } from '@angular/core';

@Component({
   selector: 'app-round-button-right',
   templateUrl: './round-button-right.component.html',
   styleUrl: './round-button-right.component.scss'
})
export class RoundButtonRightComponent {
   @Output() RightClick: EventEmitter<any> = new EventEmitter();

   constructor() {}

   rightClick() {
      this.RightClick.emit();
   }
}
