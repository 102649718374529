import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyService } from '../../services/property.service';
import { TaskService } from '../../services/task.service';
import { RowButton } from '../../panel-components/models/row-button';
import { DateTime } from 'luxon';
import { User } from '../../domain/user';
import { UserStoreService } from '../../services/stores/user-store.service';
import { WorkflowFile } from '../../domain/workflow-file';
import { FollowUp } from '../../domain/follow-up';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { CommentRequest } from '../../services/request/comment-request';
import { MentionRequest } from '../../services/request/mention-request';
import { MessageService } from 'primeng/api';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { FormTaskService } from '../../services/form-task.service';

@Component({
   selector: 'app-add-coment-dialog',
   templateUrl: './add-comment-dialog.component.html',
   styleUrls: ['./add-comment-dialog.component.scss']
})
export class AddCommentDialogComponent {
   @Input()
   isVisible: boolean;
   @Input()
   taskType: WorkflowType;
   @Input()
   currentFile: WorkflowFile;
   @Input()
   processId: number;
   @Output() commentDialogClose = new EventEmitter();

   buttons: RowButton[];

   followUpDate: Date | undefined;
   selectedUsers: User[];
   propertyUsers: User[];
   filteredUsers: User[];
   commentText: string;
   currentProperty: Property;

   constructor(
      private propertyService: PropertyService,
      private taskService: TaskService,
      private userStore: UserStoreService,
      private propertyStore: PropertyStoreService,
      private messageService: MessageService,
      private formTaskService: FormTaskService
   ) {
      this.taskType = WorkflowType.Daily;
      this.isVisible = false;
      this.processId = 0;
      this.currentFile = new WorkflowFile();
      this.currentProperty = new Property();
      this.selectedUsers = [];
      this.propertyUsers = [];
      this.filteredUsers = [];
      this.commentText = '';
      this.buttons = [
         new RowButton(
            'close',
            'close',
            'fal fa-times',
            ['p-mr-2', 'p-mb-2'],
            ['p-col-5', 'p-text-left']
         ),
         new RowButton(
            'save_comment',
            'Save',
            'fal fa-plus',
            ['p-mr-2', 'p-mb-2'],
            ['p-col-5', 'p-text-right']
         )
      ];
      this.userStore.users.subscribe((users) => {
         this.propertyUsers = users;
      });
      this.propertyStore.currentProperty.subscribe((prop) => {
         this.currentProperty = prop;
      });
   }

   onSave(): void {
      if (this.currentFile.documentId != null) {
         const comment = new CommentRequest();
         comment.text = this.commentText;
         comment.documentId = this.currentFile.documentId;
         comment.mentions = new Array<MentionRequest>();
         for (const user of this.selectedUsers) {
            const newMention = new MentionRequest();
            newMention.userId = user.id;
            comment.mentions.push(newMention);
         }
         switch (this.taskType) {
            case WorkflowType.Form:
               this.formTaskService
                  .addCommentForFormTaskInstance(comment, this.processId)
                  .subscribe(
                     () => {
                        this.completeComment();
                     },
                     () => {
                        this.onUploadError();
                     }
                  );
               break;
            default:
               this.taskService.addComment(comment, this.processId).subscribe(
                  () => {
                     this.completeComment();
                  },
                  () => {
                     this.onUploadError();
                  }
               );
               break;
         }
      }
   }

   onUploadError() {
      this.messageService.add({
         severity: 'error',
         summary: 'Unable to add comment for workflow',
         detail: `Please try again and if issues persist please contact support`
      });
      this.close();
   }
   completeComment() {
      if (this.followUpDate != null) {
         const followUp = new FollowUp();
         followUp.followUpDate = DateTime.fromJSDate(this.followUpDate).toISODate();
         followUp.createdDate = DateTime.now().toISODate();
         followUp.processId = this.processId;
         switch (this.taskType) {
            case WorkflowType.Form:
               this.formTaskService
                  .addFollowUp(this.currentProperty.id, followUp)
                  .subscribe(
                     () => {
                        this.messageService.add({
                           severity: 'success',
                           summary: 'Comment/Follow Up Added',
                           detail: `Added new comment and followup for ${followUp.followUpDate}`
                        });
                        this.followUpDate = undefined;
                        this.close();
                     },
                     () => {
                        this.messageService.add({
                           severity: 'error',
                           summary: 'Unable to add followup for workflow',
                           detail: `Please try again and if issues persist please contact support`
                        });
                        this.close();
                     }
                  );
               break;
            default:
               this.propertyService
                  .addFollowUp(this.currentProperty.id, followUp)
                  .subscribe(
                     () => {
                        this.messageService.add({
                           severity: 'success',
                           summary: 'Comment/Follow Up Added',
                           detail: `Added new comment and followup for ${followUp.followUpDate}`
                        });
                        this.followUpDate = undefined;
                        this.close();
                     },
                     () => {
                        this.messageService.add({
                           severity: 'error',
                           summary: 'Unable to add followup for workflow',
                           detail: `Please try again and if issues persist please contact support`
                        });
                        this.close();
                     }
                  );
               break;
         }
      } else {
         this.messageService.add({
            severity: 'success',
            summary: 'Comment Added',
            detail: `Added new comment`
         });
         this.close();
      }
      this.commentText = '';
      this.selectedUsers = [];
   }

   close(): void {
      this.isVisible = false;
      this.commentDialogClose.emit();
   }

   // onButtonRowClick(id: string) {
   // 	switch (id) {
   // 		case 'close':
   // 			this.close();
   // 			break;
   // 		case 'save_comment':
   // 			this.onSave();
   // 			break;
   // 		default:
   // 			break;
   // 	}
   // }

   filterUsers($event: any) {
      const filtered: User[] = [];
      const query = $event.query;

      for (let i = 0; i < this.propertyUsers.length; i++) {
         const user = this.propertyUsers[i];
         if (user.fullName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(user);
         }
      }

      this.filteredUsers = filtered;
   }
}
