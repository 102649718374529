<p-dialog
   [(visible)]="this.isVisible"
   [autoZIndex]="true"
   [baseZIndex]="90000"
   [closable]="false"
   header="Complete Form*"
>
   <div class="form-height mx-auto">
      <div *ngIf="this.isLoading" class="progress-spinner">
         <p-progressSpinner></p-progressSpinner>
      </div>

      <div>
         <div class="mt-2">
            <p-autoComplete
               (completeMethod)="filterUsers($event)"
               [(ngModel)]="selectedUsers"
               [autoZIndex]="true"
               [baseZIndex]="100000"
               [dropdown]="true"
               [multiple]="true"
               [suggestions]="filteredUsers"
               field="fullName"
               inputId="sendUsers"
            >
            </p-autoComplete>
         </div>

         <div class="mt-4">
            <app-button-row
               (buttonClick)="this.onButtonRowClick($event)"
               [buttons]="this.buttons"
            ></app-button-row>
         </div>

         <p class="text-sm instructions-margin">
            *To send this completed form and the audit records by email, please select
            the users you wish to send to below or leave the selection blank and click
            on complete if no further action is required for this form
         </p>
      </div>
   </div>
</p-dialog>
