<div *ngIf="this.isLoading" class="progress-spinner">
	<p-progressSpinner></p-progressSpinner>
</div>

<app-add-user-dialog
	[isVisible]="isAddUserDialogVisible"
	[currentProperty]="currentProperty"
	(addUserDialogClose)="onAddUserDialogClose()"
	(userAdded)="onUserAdded()"
></app-add-user-dialog>

<div *ngIf="this.isCurrentlyVisible" id="user_table">
   <div class="tyalls-table-name mb-3">
      <h6 class="tyalls-table-heading">User Access<span class="font-light"> - {{currentProperty?.name}}</span></h6>
   </div>

   <div class="mb-4">
		 <div class="flex justify-content-end gap-4 mt-4">
			 <div class="p-field">
				 <button
					 pButton
					 label="Add User"
					 icon="fal fa-plus"
					 (click)="onButtonRowClick('add_user')"
				 ></button>
			 </div>
			 <div class="p-field">
				 <button
					 pButton
					 label="Download Users"
					 icon="fal fa-download"
					 (click)="onButtonRowClick('download_users')"
				 ></button>
			 </div>
		 </div>

   </div>

   <p-table
      #dtc
      [(first)]="firstRow"
      [(selection)]="selectedUsers"
      [paginator]="true"
      [rows]="20"
      [showCurrentPageReport]="true"
      [value]="users"
      dataKey="id"
      sortField="createdDate"
      sortMode="multiple"
      styleClass="p-datatable-workflow p-datatable-gridlines p-datatable-striped p-datatable-sm"
   >
      <ng-template pTemplate="header">
         <tr>
            <th class="date-col" pSortableColumn="email">
               Username
               <p-sortIcon
                  field="email"
                  pTooltip="Hold Ctrl Left click to sort by more than one column"
               ></p-sortIcon>
            </th>
            <th class="date-col" pSortableColumn="firstName">
               First Name
               <p-sortIcon
                  field="firstName"
                  pTooltip="Hold Ctrl Left click to sort by more than one column"
               ></p-sortIcon>
            </th>
            <th pSortableColumn="lastName">
               Last Name
               <p-sortIcon
                  field="lastName"
                  pTooltip="Hold Ctrl Left click to sort by more than one column"
               ></p-sortIcon>
            </th>
            <th>Last Seen</th>
            <th>Groups</th>
            <th *ngIf="this.userPermissions.role.read">Roles</th>
						 <th *ngIf="currentProperty?.isPhobosEnabled" pSortableColumn="outstandingTaskCount">
							 Tasks O/S
							 <p-sortIcon
								 field="outstandingTaskCount"
								 pTooltip="Hold Ctrl Left click to sort by more than one column"
							 ></p-sortIcon>
						 </th>
            <th>Action</th>
         </tr>
      </ng-template>
      <ng-template let-user pTemplate="body">
         <tr>
            <td>
               <span class="p-column-title">Username</span>
               {{ user.username }}
            </td>
            <td>
               <span class="p-column-title">First Name</span>
               {{ user.firstName }}
            </td>
            <td>
               <span class="p-column-title">Last Name</span>
               {{ user.lastName }}
            </td>
            <td>
               <span class="p-column-title">Last Seen</span>
							{{ user.lastSeen | relativeTime }}
            </td>
            <td>
               <span class="p-column-title">Groups</span>
               <div class="grid">
                  <div class="p-col-11 p-offset-1">
										<ng-container *ngFor="let group of user.groups">
											 <p-chip
													(onRemove)="removeGroup(group, user.authId)"
													*ngIf="group.isActive"
													[removable]="true"
													label="{{
														 group.displayName
													}}"
													styleClass="role-chip"
											 ></p-chip>
										</ng-container>
                  </div>

                  <button
                     (click)="openDialog(user, false, true, $event)"
                     class="p-button-rounded p-button-sm role-button w-7rem"
                     pButton
                     type="button"
                  >
                     Add Group
                  </button>
               </div>
            </td>
            <td *ngIf="this.userPermissions.role.read">
               <span class="p-column-title">Roles</span>
               <div class="grid">
                  <div class="p-col-11 p-offset-1">
                     <p-chip
                        (onRemove)="removeRole(role, user.authId)"
                        *ngFor="let role of user.roles"
                        [removable]="this.userPermissions.role.delete"
                        label="{{ role.name.replace('_', ' ') | toCamelCase }}"
                        styleClass="role-chip"
                     ></p-chip>
                  </div>
                  <div class="p-col-11 p-offset-1">
                     <button
                        (click)="openDialog(user, true, false, $event)"
                        *ngIf="this.userPermissions.role.create"
                        class="p-button-rounded p-button-sm role-button"
                        pButton
                        type="button"
                     >
                        Add Role
                     </button>
                  </div>
               </div>
            </td>
					 <td *ngIf="currentProperty?.isPhobosEnabled">
						 <span class="p-column-title">Tasks O/S</span>

						 <button
							 *ngIf="user.outstandingTaskCount > 0"
							 (click)="confirmDeclaimTasks(user)"
							 class="p-button-rounded p-button-sm role-button w-7rem mt-2"
							 label="De-Claim {{ user.outstandingTaskCount }} Tasks"
							 pButton
							 type="button"
						 >
						 </button>
					 </td>
            <td class="button-col">
               <button
                  (click)="toggleAccess(user, !user.isEnabled)"
                  *ngIf="userPermissions.user.delete"
                  class="p-button-rounded p-button-sm"
                  label="{{ user.isEnabled ? 'Remove Access' : 'Enabled User' }}"
                  pButton
                  pRipple
                  type="button"
               ></button>
            </td>
         </tr>
      </ng-template>

      <ng-template pTemplate="emptymessage">
         <tr>
            <td colspan="8">
							<ng-container *ngIf="isLoading; else noUsers">
								Loading...
							</ng-container>
							<ng-template #noUsers>
								<span *ngIf="isLoading">Loading...</span>
								<span *ngIf="hasNoUsers()">No Users Found</span>
							</ng-template>
						</td>
         </tr>
      </ng-template>
   </p-table>

   <p-overlayPanel #roleOverlay>
      <ng-template pTemplate>
         <div class="flex p-2">
            <div class="mr-3">
               <p-dropdown
                  [(ngModel)]="selectedRole"
                  [options]="roles"
                  optionLabel="name"
                  placeholder="Select a Role"
               >
                  <ng-template pTemplate="selectedItem">
                     <div
                        *ngIf="selectedRole"
                        class="country-item country-item-value"
                     >
                        <div>
                           {{
                              selectedRole.name.replaceAll('_', ' ').toLowerCase()
                                 | toCamelCase
                           }}
                        </div>
                     </div>
                  </ng-template>
                  <ng-template let-role pTemplate="item">
                     <div>
                        <div>
                           {{
                              role.name.replaceAll('_', ' ').toLowerCase()
                                 | toCamelCase
                           }}
                        </div>
                     </div>
                  </ng-template>
               </p-dropdown>
            </div>
            <div class="flex">
               <button
                  (click)="addRole()"
                  class="p-button-raised p-button-rounded p-button-sm mr-3"
                  label="Add"
                  pButton
                  type="button"
               ></button>

               <button
                  (click)="roleOverlay.hide()"
                  class="p-button-rounded p-button-danger p-button-sm"
                  label="Close"
                  pButton
                  type="button"
               ></button>
            </div>
         </div>
      </ng-template>
   </p-overlayPanel>

	<p-overlayPanel #groupOverlay>
		<ng-template pTemplate>
			<div class="flex p-2">
				<div class="mr-3">
					<p-dropdown
						[(ngModel)]="selectedGroup"
						[options]="availableGroups"
						optionLabel="name"
						optionDisabled="isDisable"
						placeholder="Select a review group"
					>
						<ng-template pTemplate="selectedItem">
							<div *ngIf="selectedGroup" class="country-item country-item-value">
								<div>{{ selectedGroup.displayName }}</div>
							</div>
						</ng-template>
						<ng-template let-group pTemplate="item">
							<div>
								<div>{{ group.displayName }}</div>
							</div>
						</ng-template>
					</p-dropdown>
				</div>
				<div class="flex">
					<button
						(click)="addGroup()"
						class="p-button-raised p-button-rounded p-button-sm mr-3"
						label="Add"
						pButton
						type="button"
					></button>
					<button
						(click)="groupOverlay.hide()"
						class="p-button-rounded p-button-danger p-button-sm"
						label="Close"
						pButton
						type="button"
					></button>
				</div>
			</div>
		</ng-template>
	</p-overlayPanel>

</div>
