<div class="grid justify-between bg-white widget-row-card mx-0">
   <div *ngFor="let widget of this.widgets" class="col-12 sm:col-6 lg:col-3">
      <div class="flex justify-content-center">
         <div class="py-2 px-2 relative cursor-pointer md:w-18rem w-full">
            <div
               (click)="widget.onClick($event)"
               class="card overview-box-2 {{
                  widget.backGroundColourClass
               }} widget-card"
            >
               <div class="flex justify-content-center flex-wrap w-full">
                  <h6 class="widget-text mr-1">{{ widget.text }}</h6>
               </div>
               <i
                  *ngIf="widget.isCountVisible"
                  class="absolute z-10 top-0 right-0 widget-icon"
                  >{{ widget.count }}</i
               >
            </div>
         </div>
      </div>
   </div>
</div>
