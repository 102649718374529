import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Task } from '../domain/task';
import { Base } from './base';
import { DailyTaskDetail } from '../domain/daily-task-detail';
import { DateTime } from 'luxon';
import { WorkflowFile } from '../domain/workflow-file';
import { Comment } from '../domain/comment';
import { Observable } from 'rxjs';
import { CommentRequest } from './request/comment-request';
import { Annotation } from '../domain/annotation';
import { Assignment } from '../domain/assignment';
import { FormTaskAssignment } from '../domain/form-task-assignment';
import { PhobosTaskTemplate } from '../domain/phobos-task-template';
import { CreateTaskRequest } from './request/create-task-request';
import { UpdateTaskRequest } from './request/update-task-request';

@Injectable({
   providedIn: 'root'
})
export class TaskService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   getAssignedTasks(
      startDate: DateTime,
      endDate: DateTime,
      propId: number,
      incompleteOnly: boolean
   ): Observable<Task[]> {
      const startDateString = startDate.toISODate();
      const finishDateString = endDate.toISODate();
      if (startDateString == null || finishDateString === null) {
         throw new Error('Invalid date');
      }
      return this.http.get<Task[]>(`${environment.apiEndpoint}/task/user`, {
         params: {
            propertyId: encodeURIComponent(propId),
            type: 2,
            start: startDateString,
            end: finishDateString,
            incompleteOnly: incompleteOnly
         }
      });
   }

   getAllPropertyTasks(
      startDate: DateTime,
      endDate: DateTime,
      propId: number,
      incompleteOnly: boolean
   ): Observable<Task[]> {
      const startDateString = startDate.toISODate();
      const finishDateString = endDate.toISODate();
      if (startDateString == null || finishDateString === null) {
         throw new Error('Invalid date');
      }
      return this.http.get<Task[]>(`${environment.apiEndpoint}/task/user`, {
         params: {
            propertyId: encodeURIComponent(propId),
            type: 6,
            start: startDateString,
            end: finishDateString,
            incompleteOnly: incompleteOnly
         }
      });
   }

   getAllAssignedTasks(propId: number): Observable<Task[]> {
      return this.http.get<Task[]>(`${environment.apiEndpoint}/task/user`, {
         params: {
            propertyId: encodeURIComponent(propId),
            type: 2,
            incompleteOnly: true
         }
      });
   }

   getMyTasks(
      startDate: DateTime,
      endDate: DateTime,
      propId: number
   ): Observable<DailyTaskDetail[]> {
      const startDateString = startDate.toISODate();
      const finishDateString = endDate.toISODate();
      if (startDateString == null || finishDateString === null) {
         throw new Error('Invalid date');
      }
      return this.http.get<DailyTaskDetail[]>(
         `${environment.apiEndpoint}/task/user`,
         {
            params: {
               propertyId: encodeURIComponent(propId),
               type: 1,
               start: startDateString,
               end: finishDateString
            }
         }
      );
   }

   getAllTasks(
      startDate: DateTime,
      endDate: DateTime,
      propId: number
   ): Observable<DailyTaskDetail[]> {
      const startDateString = startDate.toISODate();
      const finishDateString = endDate.toISODate();
      if (startDateString == null || finishDateString === null) {
         throw new Error('Invalid date');
      }
      return this.http.get<DailyTaskDetail[]>(
         `${environment.apiEndpoint}/task/user`,
         {
            params: {
               propertyId: encodeURIComponent(propId),
               type: 5,
               start: startDateString,
               end: finishDateString
            }
         }
      );
   }

   getTasksByPropertyId(propertyId: number): Observable<PhobosTaskTemplate[]> {
      return this.http.get<PhobosTaskTemplate[]>(
         `${environment.apiEndpoint}/phobos/task/property/${encodeURIComponent(
            propertyId
         )}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getTasksByTaskId(taskId: number): Observable<FormTaskAssignment[]> {
      return this.http.get<FormTaskAssignment[]>(
         `${environment.apiEndpoint}/phobos/task/${encodeURIComponent(taskId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getAllUnclaimedByProperty(propId: number): Observable<Task[]> {
      return this.http.get<Task[]>(
         `${environment.apiEndpoint}/task/property/${encodeURIComponent(propId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getWorkflowFileByProcessId(processId: number): Observable<WorkflowFile> {
      return this.http.get<WorkflowFile>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(processId)}/file`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getSupportingFilesByProcessId(processId: number): Observable<WorkflowFile[]> {
      return this.http.get<WorkflowFile[]>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(
            processId
         )}/file/supporting`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getTaskByProcessId(processId: number): Observable<Task> {
      return this.http.get<Task>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(processId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getAssignments(processId: number): Observable<Assignment[]> {
      return this.http.get<Assignment[]>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(
            processId
         )}/assignment`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   uploadWorkflowFile(
      processId: number,
      workflowFile: File
   ): Observable<WorkflowFile> {
      const formData = new FormData();
      formData.append('worlflowfile', workflowFile);
      return this.http.post<WorkflowFile>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(processId)}/file`,
         formData,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   uploadSupportingFile(
      processId: number,
      supportingFile: File
   ): Observable<WorkflowFile[]> {
      const formData = new FormData();
      formData.append('supportingFile', supportingFile);
      return this.http.post<WorkflowFile[]>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(
            processId
         )}/file/supporting`,
         formData,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getCommentsForWorkflowByProcessId(processId: number): Observable<Comment[]> {
      return this.http.get<Comment[]>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(processId)}/comment`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addComment(passedComment: CommentRequest, processId: number): Observable<Comment> {
      return this.http.post<Comment>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(processId)}/comment`,
         passedComment,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addAnnotation(
      passedAnnotation: Annotation,
      processId: number
   ): Observable<Annotation> {
      return this.http.post<Annotation>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(
            processId
         )}/annotation`,
         [passedAnnotation],
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addAnnotationList(
      passedAnnotations: Annotation[],
      processId: number
   ): Observable<Annotation> {
      return this.http.post<Annotation>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(
            processId
         )}/annotation`,
         passedAnnotations,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   claimTasks(taskList: number[], propId: number): Observable<number[]> {
      return this.http.post<number[]>(
         `${environment.apiEndpoint}/task/claim`,
         {
            ProcessIds: taskList,
            propertyId: propId
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   declaimTasks(
      userId: number,
      propertyId: number
   ): Observable<{ declaimedTaskCount: number }> {
      return this.http.post<{ declaimedTaskCount: number }>(
         `${environment.apiEndpoint}/task/declaim`,
         {
            userId: userId,
            propertyId: propertyId
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   completeTask(processId: number): Observable<Annotation> {
      return this.http.post<Annotation>(
         `${environment.apiEndpoint}/task/${encodeURIComponent(processId)}/complete`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   createTask(task: CreateTaskRequest): Observable<PhobosTaskTemplate> {
      return this.http.post<PhobosTaskTemplate>(
         `${environment.apiEndpoint}/phobos/task`,
         task,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   editTask(task: UpdateTaskRequest): Observable<PhobosTaskTemplate> {
      return this.http.put<PhobosTaskTemplate>(
         `${environment.apiEndpoint}/phobos/task`,
         task,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }
}
