import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { PermissionsService } from '../../services/permissions.service';
import { UserPermissions } from '../../domain/security/user-permissions';
import { MenuItem } from 'primeng/api';

@Component({
   selector: 'app-menu',
   templateUrl: './menu.component.html',
   styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
   standardMenuItems: MenuItem[] = [];
   reportMenuItems: MenuItem[] = [];
   adminMenuItems: MenuItem[] = [];
   appAdminItems: MenuItem[] = [];
   userPermissions: UserPermissions;

   constructor(
      public app: AppComponent,
      public permissionService: PermissionsService
   ) {
      this.userPermissions = new UserPermissions();
   }

   createMenus(): void {
      this.standardMenuItems = [
         {
            label: 'Home',
            icon: 'fal fa-sharp fa-thin fa-house-blank',
            routerLink: ['/'],
            visible: true
         },
         {
            label: 'My Tasks',
            icon: 'fal fa-thin fa-list-check',
            routerLink: ['/tasks'],
            visible: true
         },
         {
            label: 'Launch',
            icon: 'fal fa-sharp fa-thin fa-rocket',
            routerLink: ['/taskboard'],
            visible: true
         },
         {
            label: 'Manual Launch',
            icon: 'fal fa-sharp fa-thin fa-person-running',
            routerLink: ['/workflow/launch'],
            visible: true
         }
      ];
      this.reportMenuItems = [
         {
            label: 'Portfolio Snapshot',
            icon: 'fa-light fa-chart-pie-simple',
            routerLink: ['/report/portfolio'],
            visible: true
         },
         {
            label: 'Audit Report',
            icon: 'fal fa-sharp fa-thin fa-table',
            routerLink: ['/report/audit'],
            visible: true
         },
         {
            label: 'Tasks',
            icon: 'fa-sharp fa-light fa-chart-bar',
            routerLink: ['/report/tasks'],
            visible: this.userPermissions.reports.read
         },
         {
            label: 'Groups',
            icon: 'fa-sharp fa-regular fa-chart-waterfall',
            routerLink: ['/report/groups'],
            visible: this.userPermissions.reports.beta
         },
         {
            label: 'Team',
            icon: 'fa-sharp fa-regular fa-chart-column',
            routerLink: ['/report/staff'],
            visible: this.userPermissions.reports.read
         }
      ];

      this.adminMenuItems = [
         {
            label: 'Users',
            icon: 'fal fa-sharp fa-thin fa-user',
            routerLink: ['/property/admin/users'],
            visible: true
         },
         {
            label: 'Groups',
            icon: 'fal fa-thin fa-users',
            routerLink: ['/property/admin/groups'],
            visible: this.userPermissions.groups.read
         },
         {
            label: 'Task Management',
            icon: 'fal fa-thin fa-list',
            routerLink: ['/workflow/tasks'],
            visible: this.userPermissions.taskManagement.read
         }
      ];
      this.appAdminItems = [
         {
            label: 'Form Creator',
            icon: 'fal fa-file-alt',
            routerLink: ['/admin/form'],
            visible: true
         }
      ];
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this.createMenus();
   }
}
