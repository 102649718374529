<div class="p-grid prop-dropdown-div">
   <div class="p-sm-10 p-md-6 p-lg-6 p-pt-6">
      <p-dropdown
         (onChange)="selectedUserChange($event)"
         [(ngModel)]="selectedUser"
         [options]="users"
         [showClear]="false"
         name="selectedUser"
         placeholder="Select a User"
      >
         <ng-template pTemplate="selectedItem">
            <div *ngIf="selectedUser">
               <div>
                  {{ selectedUser.firstName | trimString }}
                  {{ selectedUser.lastName | trimString }}
               </div>
            </div>
         </ng-template>
         <ng-template let-user pTemplate="item">
            <div>
               <div>
                  {{ user.firstName | trimString }} {{ user.lastName | trimString }}
               </div>
            </div>
         </ng-template>
      </p-dropdown>
   </div>
</div>
