import { WorkflowType } from './enums/workflow-type';

export class PhobosTaskInstance {
   id: number = 0;
   propertyId: number = 0;
   taskId: number = 0;
   documentId: string = '';
   createdDate: string = '';
   completedDate: string = '';
   createdBy: number = 0;
   name: string = '';
   description: string = '';
   isAssignedToUser: boolean = false;
   type: WorkflowType = WorkflowType.Daily;

   constructor() {}
}
