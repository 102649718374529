import { Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { TaskService } from '../../services/task.service';
import { WorkflowFile } from '../../domain/workflow-file';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { Comment } from '../../domain/comment';
import { RowButton } from '../../panel-components/models/row-button';
import { MentionStoreService } from '../../services/stores/mention-store.service';
import { FollowUpStoreService } from '../../services/stores/follow-up-store.service';
import { MenuItem, MessageService, SelectItem } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { Task } from '../../domain/task';
import { Annotation } from '../../domain/annotation';
import { MenuStoreService } from '../../services/stores/menu-store.service';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { DataStoreService } from '../../services/data-store.service';
import { UploadFile } from '../../domain/uploadfile';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { DocumentSidePanelComponent } from '../../panel-components/document-side-panel/document-side-panel.component';
import { TaskParent } from '../parents/task-parent';
import { ITaskComponent } from '../interfaces/i-task-component';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';

@Component({
   selector: 'app-tasks-detail',
   templateUrl: './tasks-detail.component.html',
   styleUrls: ['./tasks-detail.component.scss']
})
export class TasksDetailComponent
   extends TaskParent
   implements ITaskComponent, OnInit
{
   @ViewChild(DocumentSidePanelComponent) documentSidePanelComponent:
      | DocumentSidePanelComponent
      | undefined;
   @Input()
   processId: number;
   currentTask: Task;
   comments: Array<Comment>;
   isCommentDialogVisible: boolean;
   headingButtons: RowButton[];
   isCommentListVisible: boolean;
   mainPdfContainer: string;
   primaryFile: WorkflowFile;
   currentSecondaryFile: WorkflowFile;
   supportingFiles: SelectItem[];
   sideBarMenuItems: MenuItem[];
   isLoading: boolean;
   buttons: RowButton[];
   pdfCss: string;
   commentsCss: string;
   pdfPanelInFocus: boolean;
   isSubmitting: boolean = false;
   showPanelPadding: string;
   protected readonly WorkflowType = WorkflowType;

   constructor(
      private taskService: TaskService,
      private route: ActivatedRoute,
      private propStore: PropertyStoreService,
      private mentionStore: MentionStoreService,
      private followupStore: FollowUpStoreService,
      private messageService: MessageService,
      private menuStore: MenuStoreService,
      private router: Router,
      private workflowTaskStore: WorkflowTasksStoreService,
      private dataStoreService: DataStoreService,
      private dropDownStore: DropDownStoreService
   ) {
      super(router, propStore);
      this.buttons = [];
      this.processId = 0;
      this.primaryFile = new WorkflowFile();
      this.currentSecondaryFile = new WorkflowFile();
      this.currentTask = new Task();
      this.isLoading = true;
      this.propStore.setIsPropertyChangeEnabled(false);
      if (!router.getCurrentNavigation()?.extras.state?.task) {
         this.route.params.subscribe((params: any) => {
            this.processId = params.id;
            this.taskService.getTaskByProcessId(this.processId).subscribe((task) => {
               this.currentTask = task;
               this.loadFile();
            });
         });
      } else {
         this.currentTask = router.getCurrentNavigation()?.extras.state?.task;
         this.processId =
            this.currentTask !== undefined ? this.currentTask.processId : 0;
         this.loadFile();
      }

      this.mainPdfContainer = 'main-file-container';
      this.comments = [];
      this.isCommentDialogVisible = false;
      this.isCommentListVisible = true;
      this.headingButtons = [
         new RowButton(
            'supporting_doc',
            'Supporting Documents',
            'fal fa-file',
            ['p-mr-2', 'p-mb-2'],
            ['p-col', 'p-text-left']
         )
      ];
      this.supportingFiles = [];
      this.sideBarMenuItems = [
         {
            label: 'Comments',
            icon: 'fal fa-comments',
            command: () => {}
         },
         {
            label: 'Supporting Documents',
            icon: 'fal fa-file-alt',
            command: () => {}
         }
      ];
      this.pdfCss = 'xl:col-8 col-12';
      this.commentsCss = 'xl:col-3 col-12';
      this.pdfPanelInFocus = true;
      this.menuStore.setMenuState(false);
      this.showPanelPadding = 'h-4rem mt-3';
   }

   ngOnInit(): void {
      this.updateDropDownState(true);
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   @HostListener('window:popstate', ['$event'])
   onBrowserBackBtnClose(event: Event) {
      event.preventDefault();
      this.router.navigate(['/tasks'], { replaceUrl: true });
      this.menuStore.setMenuState(true);
   }

   loadFile() {
      if (this.processId) {
         this.supportingFiles = [];
         this.primaryFile = new WorkflowFile();
         this.currentSecondaryFile = new WorkflowFile();
         this.taskService.getWorkflowFileByProcessId(this.processId).subscribe(
            (file) => {
               this.primaryFile = file;
               this.isLoading = false;
            },
            (e: HttpErrorResponse) => {
               if (e.status) {
                  this.isLoading = false;
                  this.primaryFile = new WorkflowFile();
               }
            }
         );
      } else {
         console.log('Unable to Load file, process id null');
      }
   }

   openCommentDialog(): void {
      this.isCommentDialogVisible = true;
   }

   onDialogClose(): void {
      this.isCommentDialogVisible = false;
      this.followupStore.refreshStore();
      this.mentionStore.refreshStore();
      if (this.documentSidePanelComponent) {
         this.documentSidePanelComponent.getComments();
      }
   }

   onReview() {
      if (this.processId) {
         this.isSubmitting = true;
         this.taskService.completeTask(this.processId).subscribe({
            next: () => {
               this.currentTask.isComplete = true;
               this.messageService.add({
                  severity: 'success',
                  summary: 'Task has been reviewed',
                  detail: `${this.processId} tasks reviewed`
               });
               const refreshDate = new RefreshDataState();
               refreshDate.ClaimedTasks = true;
               refreshDate.UnclaimedTasks = true;
               refreshDate.MyTasks = true;
               refreshDate.MyTasksMonthly = true;
               this.dataStoreService.refreshTaskData(refreshDate);
            },
            complete: () => {
               this.isSubmitting = false;
            }
         });
      }
   }

   uploadWorkflowFile($event: { file: UploadFile[] }) {
      const currentFile = $event.file.pop();
      if (currentFile && this.processId && currentFile) {
         this.taskService
            .uploadWorkflowFile(this.processId, currentFile.file)
            .subscribe((file) => {
               file.annotations = new Array<Annotation>();
               this.primaryFile = file;
            });
      }
   }

   changeCurrentTask(newTask: Task) {
      if (Task) {
         this.documentSidePanelComponent?.clear();
         this.isLoading = true;
         this.currentTask = newTask;
         this.processId =
            this.currentTask !== undefined ? this.currentTask.processId : 0;
         this.loadFile();
      }
   }

   getOpenComment() {
      this.pdfCss = 'xl:col-3 col-12';
      this.commentsCss = 'xl:col-8 col-12';
      this.pdfPanelInFocus = false;
   }

   getOpenPdf() {
      this.pdfCss = 'xl:col-8 col-12';
      this.commentsCss = 'xl:col-3 col-12';
      this.pdfPanelInFocus = true;
   }
}
