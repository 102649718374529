import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

if (environment.datadog.enabled) {
   const datadogSite = 'datadoghq.com';
   const appName = 'tyalls-v2-ui';
   datadogRum.init({
      applicationId: environment.datadog.applicationId,
      clientToken: environment.datadog.clientToken,
      site: datadogSite,
      service: appName,
      env: environment.datadog.env,
      allowedTracingUrls: [environment.apiEndpoint],
      sessionSampleRate: 100,
      trackUserInteractions: true,
      trackSessionAcrossSubdomains: true,
      trackResources: true,
      trackLongTasks: true
   });
   datadogLogs.init({
      clientToken: environment.datadog.clientToken,
      site: datadogSite,
      service: appName,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100
   });
}
if (environment.production) {
   enableProdMode();
}
platformBrowserDynamic()
   .bootstrapModule(AppModule)
   .then(() => {
      if ('serviceWorker' in navigator && environment.production) {
         navigator.serviceWorker.register('ngsw-worker.js');
      }
   })
   .catch((err) => console.log(err));
