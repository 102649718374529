import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
   providedIn: 'root'
})
export class LocationStoreService {
   private locationParamsSubject: BehaviorSubject<any>;

   constructor() {
      this.locationParamsSubject = new BehaviorSubject<any>({});
   }

   setLocationParams(params: any): void {
      this.locationParamsSubject.next(params);
   }

   getLocationParams(): Observable<any> {
      return this.locationParamsSubject.asObservable();
   }
}
