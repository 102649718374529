import { Injectable } from '@angular/core';
import { PropertyService } from '../property.service';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { PropertyStoreService } from './property-store.service';
import { Mention } from '../../domain/mention';
import { IRefreshable } from './irefreshable';
import { LoadingState } from '../../domain/loading-state';
import { LoadingOverlayStoreService } from './loading-overlay-store.service';
import { DataStoreService } from '../data-store.service';

@Injectable({
   providedIn: 'root'
})
export class MentionStoreService implements IRefreshable {
   private _mentions: BehaviorSubject<Mention[]> = new BehaviorSubject(
      new Array<Mention>()
   );

   public readonly mentions: Observable<Mention[]> = this._mentions.asObservable();

   private currentPropertyId: number | undefined;

   constructor(
      private propertyStore: PropertyStoreService,
      private propertyService: PropertyService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService
   ) {
      propertyStore.currentProperty.subscribe((property) => {
         this.currentPropertyId = property.id;
         const loadingState = new LoadingState();
         loadingState.Mentions = true;
         this.loadingOverlayStore.setLoadingState(loadingState);
         propertyService.getMentions(this.currentPropertyId).subscribe((mentions) => {
            this._mentions.next(mentions);
            loadingState.Mentions = false;
            this.loadingOverlayStore.setLoadingState(loadingState);
         });
      });
      dataStoreService.refreshTaskDataSubject.subscribe((value) => {
         if (value.Mentions) {
            this.refreshStore();
         }
      });
   }

   readMentions(mentionIds: number[]): Observable<number> {
      if (this.currentPropertyId != undefined) {
         return this.propertyService.readMentions(this.currentPropertyId, mentionIds);
      }
      return new Observable<number>((subscriber: Subscriber<number>) =>
         subscriber.next(0)
      );
   }

   deleteMentions(mentionIds: number[]): Observable<number> {
      if (this.currentPropertyId != undefined) {
         return this.propertyService.deleteMentions(
            this.currentPropertyId,
            mentionIds
         );
      }
      return new Observable<number>((subscriber: Subscriber<number>) =>
         subscriber.next(0)
      );
   }

   refreshStore(): void {
      if (this.currentPropertyId != undefined) {
         const loadingState = new LoadingState();
         loadingState.Mentions = true;
         this.propertyService
            .getMentions(this.currentPropertyId)
            .subscribe((mentions) => {
               this._mentions.next(mentions);
               loadingState.Mentions = false;
               this.loadingOverlayStore.setLoadingState(loadingState);
            });
      }
   }
}
