import { Component } from '@angular/core';
import { Widget } from '../models/widget';
import { WidgetStoreService } from '../../services/stores/widget-store.service';

@Component({
   selector: 'app-widget-row',
   templateUrl: './widget-row.component.html',
   styleUrls: ['./widget-row.component.scss']
})
export class WidgetRowComponent {
   widgets: Widget[];

   constructor(private widgetStore: WidgetStoreService) {
      this.widgets = [];
      this.widgetStore.widgets.subscribe((widgets) => {
         this.widgets = widgets;
      });
   }
}
