import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { AvatarModule } from 'primeng/avatar';
import { MenuModule } from 'primeng/menu';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { TaskCalendarComponent } from './panel-components/task-calendar/task-calendar.component';
import { PropertyDropDownComponent } from './form-components/property-drop-down/property-drop-down.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AccordionModule } from 'primeng/accordion';
import { AppRoutingModule } from './app-routing.module';
import { TopBarComponent } from './components/topbar/top-bar.component';
import { MenuComponent } from './components/menu/menu.component';
import { CheckboxModule } from 'primeng/checkbox';
import { MenuitemComponent } from './components/menuitem/menuitem.component';
import { WorkflowTableComponent } from './panel-components/workflow-table/workflow-table.component';
import { TableModule } from 'primeng/table';
import { ProgressBarModule } from 'primeng/progressbar';
import { MentionsCardComponent } from './form-components/mentions-card/mentions-card.component';
import { TeamSnapshotComponent } from './form-components/team-snapshot/team-snapshot.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TasksDetailComponent } from './components/tasks-detail/tasks-detail.component';
import { TasksDetailPhobosComponent } from './components/tasks-detail-phobos/tasks-detail-phobos.component';
import { RippleModule } from 'primeng/ripple';
import { TabViewModule } from 'primeng/tabview';
import { A11yModule } from '@angular/cdk/a11y';
import { DataViewModule } from 'primeng/dataview';
import { RatingModule } from 'primeng/rating';
import { DocumentListComponent } from './panel-components/document-list/document-list.component';
import { TopHeadingComponent } from './panel-components/top-heading/top-heading.component';
import { TaskCommentsComponent } from './panel-components/task-comments/task-comments.component';
import { FileUploadModule } from 'primeng/fileupload';
import { AuditBasketComponent } from './components/audit-basket/audit-basket.component';
import { AuditBasketListComponent } from './components/audit-basket-list/audit-basket-list.component';
import { ToastModule } from 'primeng/toast';
import { environment } from '../environments/environment';
import { DatePipe, NgOptimizedImage } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessageService } from 'primeng/api';
import { FullCalendarModule } from '@fullcalendar/angular';
import { BadgeModule } from 'primeng/badge';
import { HttpCacheInterceptorModule, useHttpCacheLocalStorage } from '@ngneat/cashew';
import { ChipModule } from 'primeng/chip';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ButtonRowComponent } from './panel-components/button-row/button-row.component';
import { WidgetRowComponent } from './panel-components/widget-row/widget-row.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { FollowUpTableComponent } from './panel-components/follow-up-table/follow-up-table.component';
import { MentionTableComponent } from './panel-components/mention-table/mention-table.component';
import { AddCommentDialogComponent } from './dialogs/add-comment-dialog/add-comment-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TableHeadingRowComponent } from './panel-components/table-heading-row/table-heading-row.component';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StyleClassModule } from 'primeng/styleclass';
import { UploadWorkflowFileComponent } from './panel-components/upload-workflow-file/upload-workflow-file.component';
import { WorkflowRunComponent } from './components/workflow-run/workflow-run.component';
import { DividerModule } from 'primeng/divider';
import { AuditReportComponent } from './components/reporting/audit-report/audit-report.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ClaimedTasksTableComponent } from './panel-components/claimed-tasks-table/claimed-tasks-table.component';
import { PortfolioReportComponent } from './components/reporting/portfolio-report/portfolio-report.component';
import { PdfFilePanelComponent } from './panel-components/pdf-file-panel/pdf-file-panel.component';
import { SpeedDialModule } from 'primeng/speeddial';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { PanelModule } from 'primeng/panel';
import { TaskboardComponent } from './components/taskboard/taskboard.component';
import { TaskListComponent } from './panel-components/task-list/task-list.component';
import { SwapUploadFileDialogComponent } from './dialogs/swap-upload-file-dialog/swap-upload-file-dialog.component';
import { DialogService } from 'primeng/dynamicdialog';
import { TaskSortPipe } from './pipes/task-sort.pipe';
import { DayTaskTableComponent } from './panel-components/day-task-table/day-task-table.component';
import { DailyTaskListComponent } from './components/daily-task-list/daily-task-list.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';
import { DatePropertyTimezonePipe } from './pipes/date-property-timezone.pipe';
import { PropertyUsersComponent } from './components/prop-admin/property-users/property-users.component';
import { UserTableComponent } from './panel-components/user-table/user-table.component';
import { ToCamelCasePipe } from './pipes/to-camel-case.pipe';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { AddUserDialogComponent } from './dialogs/add-user-dialog/add-user-dialog.component';
import { TrimStringPipe } from './pipes/trim-string.pipe';
import { FormCreatorComponent } from './components/app-admin/form-creator/form-creator.component';
import { UserDropDownComponent } from './form-components/user-drop-down/user-drop-down.component';
import { GroupDropDownComponent } from './form-components/group-drop-down/group-drop-down.component';
import { FormCreateComponent } from './components/form/form-create/form-create.component';
import { TimelineModule } from 'primeng/timeline';
import { DocumentSidePanelComponent } from './panel-components/document-side-panel/document-side-panel.component';
import { CompleteFormDialogComponent } from './dialogs/complete-form-dialog/complete-form-dialog.component';
import { DateLocalFormatPipe } from './pipes/date-local-format.pipe';
import { ErrorPanelComponent } from './panel-components/error-panel/error-panel.component';
import { CreatePageComponent } from './components/onboarding/create-page/create-page.component';
import { PropertyGroupsComponent } from './components/prop-admin/property-groups/property-groups.component';
import { GroupsTableComponent } from './panel-components/groups-table/groups-table.component';
import { AddGroupDialogComponent } from './dialogs/add-group-dialog/add-group-dialog.component';
import { TaskManagementComponent } from './components/tasks-admin/task-management/task-management.component';
import { AddTasksDialogComponent } from './dialogs/add-tasks-dialog/add-tasks-dialog.component';
import { RoundButtonLeftComponent } from './components/common/round-button-left/round-button-left.component';
import { RoundButtonRightComponent } from './components/common/round-button-right/round-button-right.component';
import { DropdownButtonComponent } from './components/common/dropdown-button/dropdown-button.component';
import { DropdownMenuComponent } from './components/common/dropdown-menu/dropdown-menu.component';
import { TasksOverviewChartComponent } from './components/reporting/tasks-overview-chart/tasks-overview-chart.component';
import { StaffOverviewChartComponent } from './components/reporting/staff-overview-chart/staff-overview-chart.component';
import { GroupsOverviewChartComponent } from './components/reporting/groups-overview-chart/groups-overview-chart.component';

import { ChartModule } from 'primeng/chart';
import { DragDropModule } from 'primeng/dragdrop';

@NgModule({
   declarations: [
      AppComponent,
      DashboardComponent,
      TaskCalendarComponent,
      PropertyDropDownComponent,
      TopBarComponent,
      MenuComponent,
      MenuitemComponent,
      WorkflowTableComponent,
      MentionsCardComponent,
      TeamSnapshotComponent,
      TasksComponent,
      TasksDetailComponent,
      TasksDetailPhobosComponent,
      TaskListComponent,
      DocumentListComponent,
      TopHeadingComponent,
      TaskCommentsComponent,
      AuditBasketComponent,
      AuditBasketListComponent,
      ButtonRowComponent,
      WidgetRowComponent,
      FollowUpTableComponent,
      MentionTableComponent,
      AddCommentDialogComponent,
      TableHeadingRowComponent,
      UploadWorkflowFileComponent,
      WorkflowRunComponent,
      AuditReportComponent,
      ClaimedTasksTableComponent,
      PortfolioReportComponent,
      PdfFilePanelComponent,
      TaskboardComponent,
      TaskListComponent,
      SwapUploadFileDialogComponent,
      TaskSortPipe,
      DayTaskTableComponent,
      DailyTaskListComponent,
      ErrorPageComponent,
      DatePropertyTimezonePipe,
      PropertyUsersComponent,
      UserTableComponent,
      ToCamelCasePipe,
      AddUserDialogComponent,
      TrimStringPipe,
      FormCreateComponent,
      FormCreatorComponent,
      UserDropDownComponent,
      GroupDropDownComponent,
      DocumentSidePanelComponent,
      CompleteFormDialogComponent,
      DateLocalFormatPipe,
      ErrorPanelComponent,
      CreatePageComponent,
      PropertyGroupsComponent,
      GroupsTableComponent,
      AddGroupDialogComponent,
      TaskManagementComponent,
      AddTasksDialogComponent,
      RoundButtonLeftComponent,
      RoundButtonRightComponent,
      DropdownButtonComponent,
      DropdownMenuComponent,
      TasksOverviewChartComponent,
      StaffOverviewChartComponent,
      GroupsOverviewChartComponent
   ],
   imports: [
      BrowserModule,
      AppRoutingModule,
      DropdownModule,
      FormsModule,
      BrowserAnimationsModule,
      MenubarModule,
      InputTextModule,
      ButtonModule,
      CardModule,
      BadgeModule,
      AvatarModule,
      MenuModule,
      FullCalendarModule,
      AutoCompleteModule,
      HttpCacheInterceptorModule.forRoot(),
      AccordionModule,
      CheckboxModule,
      TableModule,
      ProgressBarModule,
      RippleModule,
      TabViewModule,
      A11yModule,
      DataViewModule,
      RatingModule,
      FileUploadModule,
      ToastModule,
      ProgressSpinnerModule,
      ChipModule,
      ScrollPanelModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
         enabled: environment.production,
         // Register the ServiceWorker as soon as the app is stable
         // or after 30 seconds (whichever comes first).
         registrationStrategy: 'registerWhenStable:30000'
      }),
      DialogModule,
      CalendarModule,
      InputTextareaModule,
      SplitButtonModule,
      StyleClassModule,
      DividerModule,
      MultiSelectModule,
      ReactiveFormsModule,
      RadioButtonModule,
      SpeedDialModule,
      TieredMenuModule,
      PanelModule,
      OverlayPanelModule,
      TimelineModule,
      ChartModule,
      DragDropModule,
      TimelineModule,
      ProgressSpinnerModule,
      NgOptimizedImage
   ],
   providers: [
      provideHttpClient(withInterceptors([authHttpInterceptorFn])),
      useHttpCacheLocalStorage,
      {
         provide: DatePipe
      },
      {
         provide: MessageService
      },
      {
         provide: DialogService
      },
      provideAuth0({
         domain: `${environment.auth.domain}`,
         clientId: `${environment.auth.clientId}`,
         cacheLocation: 'localstorage',
         authorizationParams: {
            redirect_uri: window.location.origin
         },
         httpInterceptor: {
            allowedList: [
               {
                  uri: `${environment.apiEndpoint}/*`,
                  tokenOptions: {
                     authorizationParams: {
                        audience: `${environment.auth.audience}`
                     }
                  }
               }
            ]
         }
      })
   ],
   bootstrap: [AppComponent]
})
export class AppModule {}
