import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Property } from '../../domain/property';
import { PhobosTaskInstance } from '../../domain/phobos-task-instance';
import { WorkflowFile } from '../../domain/workflow-file';
import { Task } from '../../domain/task';
import { Group } from '../../domain/group';
import { FormTaskService } from '../../services/form-task.service';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { ITaskComponent } from '../interfaces/i-task-component';
import { TaskParent } from '../parents/task-parent';
import { MentionStoreService } from '../../services/stores/mention-store.service';
import { FollowUpStoreService } from '../../services/stores/follow-up-store.service';
import { DocumentSidePanelComponent } from '../../panel-components/document-side-panel/document-side-panel.component';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { DataStoreService } from '../../services/data-store.service';
import { UploadFile } from '../../domain/uploadfile';
import { MessageService } from 'primeng/api';
import { TaskService } from '../../services/task.service';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';

@Component({
   selector: 'app-tasks-detail-phobos',
   templateUrl: './tasks-detail-phobos.component.html',
   styleUrl: './tasks-detail-phobos.component.scss'
})
export class TasksDetailPhobosComponent
   extends TaskParent
   implements OnInit, ITaskComponent
{
   @ViewChild(DocumentSidePanelComponent) documentSidePanelComponent:
      | DocumentSidePanelComponent
      | undefined;
   currentProperty: Property | undefined;
   phobosTaskInstance: PhobosTaskInstance;
   mainPdfContainer: string;
   primaryFile: WorkflowFile;
   isLoading: boolean;
   hasError: boolean;
   selectedGroup: Group | undefined;
   isCompleteFormVisible: boolean;
   isCommentDialogVisible: boolean;
   isResend: boolean;
   formId: number;
   pdfCss: string;
   commentsCss: string;
   pdfPanelInFocus: boolean;
   showPanelPadding: string;
   flexCss: string;
   type: number;
   protected readonly WorkflowType = WorkflowType;
   isSubmitting: boolean = false;

   constructor(
      private formTaskService: FormTaskService,
      private mentionStore: MentionStoreService,
      private followupStore: FollowUpStoreService,
      private messageService: MessageService,
      private taskService: TaskService,
      private _propertyStore: PropertyStoreService,
      private _route: ActivatedRoute,
      private router: Router,
      private cdr: ChangeDetectorRef,
      private dataStoreService: DataStoreService,
      private propStore: PropertyStoreService,
      private dropDownStore: DropDownStoreService
   ) {
      super(router, propStore);
      this.mainPdfContainer = 'form-pdf-container';
      this.isLoading = true;
      this.hasError = false;
      this.isCompleteFormVisible = false;
      this.isCommentDialogVisible = false;
      this.primaryFile = new WorkflowFile();
      this.phobosTaskInstance = new PhobosTaskInstance();
      this.formId = 0;
      this.isResend = false;
      this.pdfCss = 'xl:col-8 col-12';
      this.commentsCss = 'xl:col-3 col-12';
      this.pdfPanelInFocus = true;
      this.showPanelPadding = 'h-4rem';
      this.flexCss = 'flex justify-content-end gap-3';
      this.type = 0;
      this._propertyStore.currentProperty.subscribe((property) => {
         this.currentProperty = property;
      });
   }

   ngOnInit(): void {
      this.updateDropDownState(true);
      this._route.params.subscribe((params: any) => {
         this.hasError = false;
         this.isLoading = true;
         if (params.id) {
            this._propertyStore.setIsPropertyChangeEnabled(false);
            this.hasError = false;
            this.isLoading = true;
            this.formId = params.id;
            this.getTaskFile();
         }
      });
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   getTaskFile(): void {
      if (this.formId !== 0) {
         this.formTaskService.getFormTaskInstance(this.formId).subscribe(
            (fti) => {
               this.updateTaskInstance(fti);
            },
            () => {
               this.currentTask = new Task();
               this.primaryFile = new WorkflowFile();
               this.primaryFile.storageId = undefined;
               this.primaryFile.documentId = undefined;
               this.primaryFile.isPrimary = true;
               this.primaryFile.createdDate = undefined;
               this.primaryFile.type = undefined;
               this.isLoading = false;
               this.hasError = true;
            }
         );
      }
   }

   updateTaskInstance(fti: PhobosTaskInstance) {
      if (fti.propertyId !== this.currentProperty?.id) {
         this.hasError = true;
         this.isLoading = false;
      } else {
         this.currentTask = new Task();
         this.currentTask.name = fti.name;
         this.currentTask.dueDate = fti.createdDate;
         this.currentTask.processId = this.formId;
         this.currentTask.type = fti.type;
         this.currentTask.description = fti.description;
         this.currentTask.isAssignedToUser = fti.isAssignedToUser;
         this.primaryFile = new WorkflowFile();
         this.primaryFile.storageId =
            fti.documentId === null ? undefined : fti.documentId;
         this.primaryFile.documentId = fti.id;
         this.primaryFile.type = fti.type;
         this.primaryFile.isPrimary = true;
         this.primaryFile.createdDate = new Date(fti.createdDate);
         this.isLoading = false;
         this.phobosTaskInstance = fti;
         if (history.state?.task) {
            this.currentTask.nextTask = history.state?.task.nextTask;
            this.currentTask.prevTask = history.state?.task.prevTask;
         }
         this.cdr.detectChanges();
      }
   }

   assign($event: MouseEvent) {
      $event.preventDefault();
      if (this.phobosTaskInstance && this.selectedGroup && this.currentProperty) {
         this.isLoading = true;
         this.isSubmitting = true;
         this.formTaskService
            .assignFormTaskInstance(
               this.phobosTaskInstance?.id,
               this.selectedGroup?.id,
               this.currentProperty?.id
            )
            .subscribe({
               next: () => {
                  const refreshDate = new RefreshDataState();
                  refreshDate.ClaimedTasks = true;
                  refreshDate.UnclaimedTasks = true;
                  refreshDate.MyTasks = true;
                  refreshDate.MyTasksMonthly = true;
                  this.dataStoreService.refreshTaskData(refreshDate);
                  this.childRouter.navigate(['/dashboard']);
               },
               complete: () => {
                  this.isSubmitting = false;
               }
            });
      }
   }

   groupSelected($event: any) {
      this.selectedGroup = $event;
   }

   complete($event: MouseEvent) {
      $event.preventDefault();
      if (this.phobosTaskInstance) {
         this.isCompleteFormVisible = true;
      }
   }

   completeFormDialogClose(): void {
      this.isCompleteFormVisible = false;
      this.currentTask.isAssignedToUser = false;
      this.isResend = false;
      this.getTaskFile();
   }

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   changeCurrentTask(newTask: Task): void {}

   onDialogClose(): void {
      this.isCommentDialogVisible = false;
      this.refreshData();
   }

   refreshData(): void {
      this.followupStore.refreshStore();
      this.mentionStore.refreshStore();
      if (this.documentSidePanelComponent) {
         this.documentSidePanelComponent.getComments();
      }
   }

   openCommentDialog(): void {
      this.isCommentDialogVisible = true;
   }

   resend($event: MouseEvent) {
      $event.preventDefault();
      if (this.phobosTaskInstance) {
         this.isResend = true;
         this.isCompleteFormVisible = true;
      }
   }

   uploadWorkflowFile($event: { file: UploadFile[] }) {
      const currentFile = $event.file.pop();
      if (currentFile && this.formId) {
         this.formTaskService
            .uploadWorkflowFile(this.formId, currentFile.file)
            .subscribe((file) => {
               this.updateTaskInstance(file);
            });
      }
   }

   onReview() {
      if (this.phobosTaskInstance.id) {
         this.isSubmitting = true;
         this.taskService.completeTask(this.phobosTaskInstance.id).subscribe({
            next: () => {
               this.currentTask.isComplete = true;
               this.messageService.add({
                  severity: 'success',
                  summary: 'Task has been reviewed',
                  detail: `${this.phobosTaskInstance.id} tasks reviewed`
               });
               const refreshDate = new RefreshDataState();
               refreshDate.ClaimedTasks = true;
               refreshDate.UnclaimedTasks = true;
               refreshDate.MyTasks = true;
               refreshDate.MyTasksMonthly = true;
               this.dataStoreService.refreshTaskData(refreshDate);
            },
            complete: () => {
               this.isSubmitting = false;
            }
         });
      }
   }

   getOpenComment() {
      this.pdfCss = 'xl:col-3 col-12';
      this.commentsCss = 'xl:col-8 col-12';
      this.pdfPanelInFocus = false;
      this.flexCss = '';
   }

   getOpenPdf() {
      this.pdfCss = 'xl:col-8 col-12';
      this.commentsCss = 'xl:col-3 col-12';
      this.pdfPanelInFocus = true;
      this.flexCss = 'flex justify-content-end gap-3';
   }
}
