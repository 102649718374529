<div *ngIf="this.isLoading" class="progress-spinner">
   <p-progressSpinner></p-progressSpinner>
</div>

<div class="card container">
   <div id="mention_table">
      <div class="tyalls-table-name mb-3">
         <h6 class="tyalls-table-heading">Calendars<span class="font-light"> - {{currentProperty?.name}}</span></h6>
      </div>
   </div>
   <div class="sm:flex gap-8 justify-content-around mb-2 tasks-canvas">
      <button
         (click)="this.onMenuRowButtonClick($event, 1)"
         [loading]="this.isLoadingTasks"
         [ngClass]="
            currentlySelectedType === 1 ? 'selected-row-button' : 'row-button'
         "
         pButton
         pRipple
         class="button-height"
      >
         <div class="flex mx-auto">
            <i class="fal fa-tasks mr-3 icon-padding"></i>
            <p>My Tasks</p>
         </div>
      </button>

      <button
         (click)="this.onMenuRowButtonClick($event, 3)"
         [loading]="this.isLoadingAllTasks"
         [ngClass]="
            currentlySelectedType === 3 ? 'selected-row-button' : 'row-button'
         "
         pButton
         pRipple
         class="button-height"
      >
         <div class="flex mx-auto">
            <i class="fal fa-layer-group mr-3 icon-padding"></i>
            <p>All Tasks</p>
         </div>
      </button>
   </div>
   <!-- <p-panel [showHeader]="true"> -->
   <div class="h-full p-3">
      <app-task-calendar
         (dateChange)="this.onChangeDate($event)"
         (dateSelect)="this.onDateSelect($event)"
         [currentDate]="this.calendarCurrentMonth"
         [data]="calendarViewData"
         [showForms]="this.toggleFormsCells"
      >
      </app-task-calendar>
   </div>
   <!-- </p-panel> -->
</div>
