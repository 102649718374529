import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RowButton } from '../models/row-button';
import { Group } from '../../domain/group';
import { GroupStoreService } from 'src/app/services/stores/group-store.service';
import { UserPermissions } from '../../domain/security/user-permissions';
import { PermissionsService } from '../../services/permissions.service';
import { Property } from '../../domain/property';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { UpdateGroupRequest } from '../../services/request/update-group-request';
import { DataStoreService } from '../../services/data-store.service';

@Component({
   selector: 'app-groups-table',
   templateUrl: './groups-table.component.html',
   styleUrls: ['./groups-table.component.scss'],
   providers: [GroupStoreService]
})
export class GroupsTableComponent implements OnInit {
   headingButtons: RowButton[];
   @Input()
   groups: Group[];
   isCurrentlyVisible: boolean;
   userPermissions: UserPermissions;
   currentProperty: Property | undefined;

   @Output() addGroupsDialogOpen = new EventEmitter();

   constructor(
      public permissionService: PermissionsService,
      private groupStore: GroupStoreService,
      private _propertyStore: PropertyStoreService,
      private dataStoreService: DataStoreService
   ) {
      this.groups = [];
      this.isCurrentlyVisible = true;
      this.headingButtons = [
         new RowButton(
            'add_group',
            'Create Group',
            'fal fa-plus',
            [
               'p-button-rounded',
               'p-button-success',
               'p-button-outlined',
               'p-mr-2',
               'p-mb-2'
            ],
            ['p-col-12', 'p-text-right'],
            'right'
         )
      ];
      this.userPermissions = new UserPermissions();
      groupStore.groups.subscribe((groups) => {
         this.groups = groups;
      });

      this.dataStoreService.refreshTaskDataSubject.subscribe((state) => {
         if (state.Groups) {
            console.log('🔄 Refreshing groups...'); // Debugging
            this.groupStore.refreshStore(); // Refresh the groups when new data is added
         }
      });
   }

   async ngOnInit(): Promise<void> {
      this.userPermissions = await this.permissionService.getPermission();
      this.headingButtons.forEach(
         (x) => (x.isVisible = this.userPermissions.user.create)
      );

      this._propertyStore.currentProperty.subscribe((property: any) => {
         this.currentProperty = property;
      });
   }

   onButtonRowClick(id: string) {
      switch (id) {
         case 'add_group':
            this.addGroupsDialogOpen.emit();
            break;
         default:
            break;
      }
   }

   updateGroup(group: Group) {
      const request = new UpdateGroupRequest();
      request.isActive = !group.isActive;
      this.groupStore.updateGroup(group, request).subscribe(() => {
         this.groupStore.refreshStore();
      });
   }
}
