import { PortfolioReportRecord } from './portfolio-report-record';
import { DateTime } from 'luxon';
import { ReportRequest } from '../services/request/report-request';

export class PortfolioReportHistory {
   request: ReportRequest;
   results: PortfolioReportRecord[];
   runDateTime: DateTime;

   constructor(request: ReportRequest) {
      this.request = request;
      this.results = [];
      this.runDateTime = DateTime.now();
   }
}
