<div class="chat">
   <div class="chat-messages">
      <div
         *ngIf="
            this.comments.length > 0 && this.comments.length <= this.comments.length;
            else defaultComment
         "
      >
         <div *ngFor="let comment of comments" class="chat-message-row">
            <div class="chat-message">
               <div class="chat-message-content">
                  <span>{{ comment.text }}</span>
                  <span class="chip-span">
                     <p-chip
                        *ngFor="let mention of comment.mentions"
                        label="{{ mention.fullName }}"
                     ></p-chip>
                  </span>
               </div>
            </div>
            <div class="chat-message-footer">
               <h6 class="comment-name-header">{{ comment.createdUserFullName }}</h6>
               <span class="comment-name-date">{{
                  comment.createdDate | DatePropertyTimezone: true
               }}</span>
            </div>
         </div>
      </div>
      <ng-template #defaultComment>
         <div class="-pt-6">
            <h6 class="text-right pr-6">Add comments</h6>
         </div>
      </ng-template>
   </div>
</div>
