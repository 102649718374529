import { Injectable } from '@angular/core';
import { Base } from './base';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { AutoRunSchedule } from '../domain/auto-run-schedule';
import { AutoRunTask } from '../domain/auto-run-task';

@Injectable({
   providedIn: 'root'
})
export class AutoRunService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   getSchedulesForProperty(propId: number): Observable<AutoRunSchedule[]> {
      return this.http.get<AutoRunSchedule[]>(
         `${environment.apiEndpoint}/autorun/schedule/property/${encodeURIComponent(propId)}`
      );
   }

   updateAutoRunTask(
      propId: number,
      autoRunTaskId: number,
      isActive: boolean
   ): Observable<AutoRunTask> {
      return this.http.put<AutoRunTask>(
         `${environment.apiEndpoint}/autorun/property/${encodeURIComponent(propId)}/task/${encodeURIComponent(autoRunTaskId)}`,
         {
            isActive: isActive
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }
}
