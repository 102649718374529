import { Component, OnInit } from '@angular/core';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { Task } from '../../domain/task';
import { UploadFile } from '../../domain/uploadfile';
import { TaskUploadFile } from '../../domain/task-upload-file';
import { TaskService } from '../../services/task.service';
import { SortParameters } from '../../pipes/domain/sort-parameters';
import { DateTime } from 'luxon';
import { WorkflowStoreService } from '../../services/stores/workflow-store.service';
import { LoadingOverlayStoreService } from '../../services/stores/loading-overlay-store.service';
import { FormTaskService } from '../../services/form-task.service';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { Property } from '../../domain/property';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';

@Component({
   selector: 'app-taskboard',
   templateUrl: './taskboard.component.html',
   styleUrls: ['./taskboard.component.scss']
})
export class TaskboardComponent implements OnInit {
   taskListDate: Date;
   selectedProperty?: Property;
   tasks: Task[];
   taskUploadFiles: TaskUploadFile[];
   selectedSortOption: SortParameters;
   sortOptions: SortParameters[];
   isLoading: boolean;
   tooltip: any;

   constructor(
      private workflowStore: WorkflowStoreService,
      private formTaskService: FormTaskService,
      private workFlowTasksStore: WorkflowTasksStoreService,
      private taskService: TaskService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private propStore: PropertyStoreService,
      private dropDownStore: DropDownStoreService
   ) {
      this.taskListDate = new Date();
      this.tasks = [];
      this.tooltip = '*The file name of your upload needs to match your task name.';
      this.taskUploadFiles = [];
      this.isLoading = true;
      this.selectedSortOption = new SortParameters('hasFile', true, 'Upload Status');
      this.sortOptions = [
         this.selectedSortOption,
         new SortParameters('name', true, 'Workflow Name'),
         new SortParameters('type', true, 'Workflow Type')
      ];
      this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
         this.isLoading =
            loadingState.LastWorkflowRunTasks ||
            loadingState.Properties ||
            loadingState.AllPropertyTasks;
      });
      this.workflowStore.lastWorkflowRunDate.subscribe((value) => {
         const runDate = DateTime.fromISO(value);
         this.taskListDate = runDate.toJSDate();
      });
      this.workFlowTasksStore.lastWorkflowRunTasks.subscribe((tasks) => {
         this.tasks = tasks.filter((task) => task.type !== 4);
      });

      this.propStore.currentProperty.subscribe((value) => {
         this.selectedProperty = value;
      });
   }

   async ngOnInit(): Promise<void> {
      this.updateDropDownState(false);
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   updateDate() {
      this.workFlowTasksStore.setLastWorkflowRunDate(
         DateTime.fromJSDate(this.taskListDate)
      );
   }

   onUpload($event: any) {
      if ($event.target) {
         for (const file of $event.target.files) {
            let taskFound = false;
            let taskIndex = 0;
            while (!taskFound && taskIndex < this.tasks.length) {
               const currentTask = this.tasks[taskIndex];
               const fileName = file.name
                  .substring(0, file.name.indexOf('.'))
                  .toLowerCase();
               if (
                  !currentTask.hasFile &&
                  currentTask.name.trim().toLowerCase().includes(fileName.trim())
               ) {
                  this.taskUploadFiles.push(
                     new TaskUploadFile(currentTask, new UploadFile(file))
                  );
                  taskFound = true;
               }
               taskIndex++;
            }
            //Task wasn't found so let's enter it as an undefined
            if (!taskFound) {
               this.taskUploadFiles.push(
                  new TaskUploadFile(undefined, new UploadFile(file))
               );
            }
         }
      }
   }

   onSave() {
      for (const uploadFile of this.taskUploadFiles) {
         if (uploadFile.task && uploadFile.file) {
            if (this.selectedProperty?.isPhobosEnabled) {
               this.formTaskService
                  .uploadWorkflowFile(uploadFile.task.processId, uploadFile.file.file)
                  .subscribe(() => {
                     this.tasks.map((f) => {
                        if (f.processId == uploadFile.task?.processId) {
                           f.hasFile = true;
                        }
                     });
                  });
            } else {
               this.taskService
                  .uploadWorkflowFile(uploadFile.task.processId, uploadFile.file.file)
                  .subscribe(() => {
                     this.tasks.map((f) => {
                        if (f.processId == uploadFile.task?.processId) {
                           f.hasFile = true;
                        }
                     });
                  });
            }
         }
      }
   }
}
