import { WorkflowType } from '../../domain/enums/workflow-type';
import { CreateStageRequest } from './create-stage-request';

export class CreateTaskRequest {
   propertyId?: number;
   isActive: boolean = true;
   isPrivate: boolean = false;
   name: string = '';
   templateId: string = '';
   isEnabledAi: boolean = true;
   type: WorkflowType = WorkflowType.Daily;
   scheduleId: number = 0;
   stages: CreateStageRequest[] = [];
   description: string = '';
}
