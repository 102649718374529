import { Component } from '@angular/core';

@Component({
   selector: 'app-form-creator',
   templateUrl: './form-creator.component.html',
   styleUrls: ['./form-creator.component.css']
})
export class FormCreatorComponent {
   constructor() {}
}
