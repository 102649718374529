import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Property } from '../domain/property';
import { Base } from './base';
import { withCache } from '@ngneat/cashew';
import { Mention } from '../domain/mention';
import { FollowUp } from '../domain/follow-up';
import { Observable } from 'rxjs';
import { User } from '../domain/user';
import { Role } from '../domain/role';
import { PhobosAiRunLog } from '../domain/phobos-ai-run-log';

@Injectable({
   providedIn: 'root'
})
export class PropertyService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   getAiRunLog(propId: number): Observable<PhobosAiRunLog[]> {
      return this.http.get<PhobosAiRunLog[]>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/ai/runlog`
      );
   }

   get(GetAll: boolean = false, GetAllForUser: boolean = true) {
      return this.http.get<Property[]>(`${environment.apiEndpoint}/property`, {
         params: {
            GetAll: GetAll,
            GetAllForUser: GetAllForUser
         }
      });
   }

   getMentions(propId: number): Observable<Mention[]> {
      return this.http.get<Mention[]>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/mention`
      );
   }

   getFollowUp(propId: number): Observable<FollowUp[]> {
      return this.http.get<FollowUp[]>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/followup`
      );
   }

   addFollowUp(propId: number, passedFollowup: FollowUp): Observable<FollowUp> {
      return this.http.post<FollowUp>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/followup`,
         passedFollowup,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   completeFollowUps(propId: number, followUpIds: number[]): Observable<number> {
      return this.http.post<number>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(
            propId
         )}/followup/complete`,
         followUpIds,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   readMentions(propId: number, mentionIds: number[]): Observable<number> {
      return this.http.post<number>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(
            propId
         )}/mention/read`,
         mentionIds,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   deleteMentions(propId: number, mentionIds: number[]): Observable<number> {
      return this.http.post<number>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(
            propId
         )}/mention/delete`,
         mentionIds,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getUsers(propId: number): Observable<User[]> {
      return this.http.get<User[]>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/user`
      );
   }

   updateTerms(propId: number, value: boolean): Observable<Property> {
      return this.http.post<Property>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/terms`,
         {
            terms_conditions: value
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getRoles(propId: number): Observable<Role[]> {
      return this.http.get<Role[]>(
         `${environment.apiEndpoint}/property/${encodeURIComponent(propId)}/role`,
         {
            context: withCache()
         }
      );
   }
}
