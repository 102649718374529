import { Component } from '@angular/core';
import { LoadingOverlayStoreService } from '../../../services/stores/loading-overlay-store.service';
import { Property } from '../../../domain/property';

@Component({
   selector: 'app-user-admin',
   templateUrl: './user-admin.component.html',
   styleUrl: './user-admin.component.css'
})
export class UserAdminComponent {
   isLoading: boolean;
   isAddUserVisible: boolean;
   currentSelectedProperty: Property | undefined;
   constructor(private loadingOverlayStore: LoadingOverlayStoreService) {
      this.isAddUserVisible = false;
      this.isLoading = true;
      this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
         this.isLoading = loadingState.Users;
      });
   }
   propertyChange($event: any) {
      if ($event) {
         this.currentSelectedProperty = $event;
      }
   }

   onDialogClose() {
      this.isAddUserVisible = false;
   }

   onAddUserOpen() {
      this.isAddUserVisible = true;
   }
}
