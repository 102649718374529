<!-- Users Page Component. -->
<div *ngIf="this.isLoading" class="progress-spinner">
   <p-progressSpinner></p-progressSpinner>
</div>
<app-add-user-dialog
   (addUserDialogClose)="this.onDialogClose()"
   [isVisible]="this.isAddUserVisible"
>
</app-add-user-dialog>
<div class="p-grid container">
   <div class="p-sm-10 p-md-10 p-lg-10 p-pt-3">
      <div class="card">
         <app-user-table
            (addUserDialogOpen)="this.onAddUserOpen()"
            [hidden]="this.isLoading"
         ></app-user-table>
      </div>
   </div>
</div>
