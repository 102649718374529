import { Component } from '@angular/core';
import { Document } from '../../domain/document';

@Component({
   selector: 'app-document-list',
   templateUrl: './document-list.component.html',
   styleUrls: ['./document-list.component.scss']
})
export class DocumentListComponent {
   documents: Document[];
   selectedDocument: Document | undefined;

   constructor() {
      this.documents = [
         {
            id: 1,
            name: 'test1',
            uploaded_by: 'test@test.com',
            updated_date: '2020-01-02'
         },
         {
            id: 2,
            name: 'test2',
            uploaded_by: 'test@test.com',
            updated_date: '2020-01-03'
         },
         {
            id: 3,
            name: 'test2',
            uploaded_by: 'test@test.com',
            updated_date: '2020-01-04'
         }
      ];
   }

   createClicked() {
      console.log('Thing');
   }
}
