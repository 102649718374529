import { Component, OnInit } from '@angular/core';
import { LoadingOverlayStoreService } from '../../../services/stores/loading-overlay-store.service';
import { DropDownStoreService } from '../../../services/stores/drop-down-store.service';
import { GroupStoreService } from '../../../services/stores/group-store.service';
import { Group } from '../../../domain/group';

@Component({
   selector: 'app-property-groups',
   templateUrl: './property-groups.component.html',
   styleUrls: ['./property-groups.component.scss']
})
export class PropertyGroupsComponent implements OnInit {
   isAddGroupsVisible: boolean;
   isLoading: boolean;
   groups: Group[];

   constructor(
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dropDownStore: DropDownStoreService,
      private groupStore: GroupStoreService
   ) {
      this.isAddGroupsVisible = false;
      this.isLoading = true;
      this.groups = [];
      this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
         this.isLoading = loadingState.Groups;
      });
      this.groupStore.groups.subscribe((g) => {
         this.groups = g;
      });
   }

   async ngOnInit(): Promise<void> {
      this.updateDropDownState(false);
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   onDialogClose() {
      this.isAddGroupsVisible = false;
   }

   onAddGroupsOpen() {
      this.isAddGroupsVisible = true;
   }
}
