<div class="layout-topbar">
   <div class="layout-topbar-wrapper">
      <div class="layout-topbar-left">
         <div class="flex flex-wrap w-full lg:justify-content-between ml-3 lg:ml-0">
            <div class="lg:flex-order-2">
               <a
                  (click)="this.onClickMenuToggle($event)"
                  class="menu-button"
                  href="#"
               >
                  <i class="fal fa-bars"></i>
               </a>
            </div>

            <div class="lg:flex-order-1 ml-5 lg:ml-0">
               <div
                  class="layout-topbar-logo"
                  id="logolink"
                  routerLink="/"
                  style="cursor: pointer; outline: none"
               >
                  <img
										ngSrc="
                        https://tyalls-images.s3.ap-southeast-2.amazonaws.com/app/logo/Tyalls_CMYK_logo_default.svg
                     "
                     alt="tyalls"
                     id="app-logo"
										height="220"
										width="100"
										priority
										/>
               </div>
            </div>
         </div>
      </div>

      <div class="layout-topbar-right">

         <div class="lg:ml-4 ml-5">
            <app-property-drop-down></app-property-drop-down>
         </div>

         <ul class="layout-topbar-actions">
            <li
               #profile
               [ngClass]="{ 'active-topmenuitem': app.activeTopbarItem === profile }"
               class="topbar-item user-profile"
            >
               <a (click)="app.onTopbarItemClick($event, profile)" href="#">
                  <i
                     class="fal fa-sharp fa-light fa-circle-user fa-2x margin-top"
                  ></i>
                  <div *ngIf="auth.user$ | async as user" class="profile-info">
                     <h6>{{ user.name }}</h6>
                     <span>{{ user.email }}</span>
                  </div>
               </a>

               <ul class="fadeInDown px-2">

                  <li role="menuitem">
                     <a (click)="this.signOut($event)" href="#">
                        <i class="fal fa-power-off"></i>
                        <h6>Logout</h6>
                     </a>
                  </li>
               </ul>
            </li>
         </ul>
      </div>
   </div>
</div>
