export class LoadingState {
   public UnclaimedTasks: boolean;
   public AllAssigned: boolean;
   public AllPropertyTasks: boolean;
   public MyTasks: boolean;
   public AllTasks: boolean;
   public MyAssigned: boolean;
   public Properties: boolean;
   public Mentions: boolean;
   public FollowUps: boolean;
   public LastWorkflowRunTasks: boolean;
   public Users: boolean;
   public Groups: boolean;
   public AutoRun: boolean;
   public Tasks: boolean;
   public PhobosTask: boolean;
   public PhobosTaskTemplates: boolean;

   constructor() {
      this.UnclaimedTasks = false;
      this.AllAssigned = false;
      this.AllPropertyTasks = false;
      this.MyTasks = false;
      this.AllTasks = false;
      this.MyAssigned = false;
      this.Properties = false;
      this.Mentions = false;
      this.FollowUps = false;
      this.LastWorkflowRunTasks = false;
      this.Users = false;
      this.Groups = false;
      this.Tasks = false;
      this.PhobosTask = false;
      this.AutoRun = false;
      this.PhobosTaskTemplates = false;
   }
}
