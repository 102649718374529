import { Component } from '@angular/core';

@Component({
   selector: 'app-mentions-card',
   templateUrl: './mentions-card.component.html',
   styleUrls: ['./mentions-card.component.css']
})
export class MentionsCardComponent {
   constructor() {}
}
