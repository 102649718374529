import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Property } from '../../domain/property';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UserQueryRequest } from '../../services/request/user-query-request';
import { User } from '../../domain/user';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { DataStoreService } from '../../services/data-store.service';
import { MessageService } from 'primeng/api';

@Component({
   selector: 'app-add-user-dialog',
   templateUrl: './add-user-dialog.component.html',
   styleUrls: ['./add-user-dialog.component.css']
})
export class AddUserDialogComponent {
   @Input()
   isVisible: boolean;
   @Input()
   currentProperty: Property | undefined;

   @Output() addUserDialogClose = new EventEmitter();
   @Output() userAdded = new EventEmitter<void>();

   userForm: FormGroup;

   isSearching: boolean = false;
   isNewUser: boolean = false;
   isExistingUser: boolean = false;
   hasAccess: boolean = false;
   existingUser: User | undefined;

   constructor(
      private userService: UserService,
      private dataStoreService: DataStoreService,
      private messageService: MessageService
   ) {
      this.currentProperty = undefined;
      this.isVisible = false;
      this.userForm = new FormGroup({
         email: new FormControl([], [Validators.required, Validators.email]),
         firstName: new FormControl(
            [],
            [Validators.required, Validators.minLength(1)]
         ),
         lastName: new FormControl(
            [],
            [Validators.required, Validators.minLength(1)]
         ),
         username: new FormControl([], [Validators.required, Validators.email])
      });
   }

   get email() {
      return this.userForm.get('email')!;
   }

   get firstName() {
      return this.userForm.get('firstName')!;
   }

   get lastName() {
      return this.userForm.get('lastName')!;
   }

   get username() {
      return this.userForm.get('username')!;
   }

   public close($event: Event) {
      $event?.preventDefault?.();
      this.isVisible = false;
      this.isExistingUser = false;
      this.isNewUser = false;
      this.hasAccess = false;
      this.userForm.reset();
      this.addUserDialogClose.emit();
   }

   public onEmailChange() {
      if (this.currentProperty) {
         this.isSearching = true;
         const request = new UserQueryRequest();
         request.emailAddress = this.email.value;
         if (request.emailAddress && request.emailAddress.length > 0) {
            request.propertyId = this.currentProperty.id;
            this.userService.queryUser(request).subscribe((response) => {
               this.isSearching = false;
               if (response.exists) {
                  this.isExistingUser = true;
                  this.isNewUser = false;
                  this.hasAccess = response.hasAccess;
                  this.existingUser = response.user;
               } else {
                  this.isExistingUser = false;
                  this.isNewUser = true;
                  this.hasAccess = false;
               }
            });
            this.username.setValue(this.email.value.toLowerCase());
         }
      }
   }

   public async onAddUser($event: MouseEvent) {
      $event.preventDefault();
      if (this.currentProperty) {
         this.userService
            .addUser(
               this.username.value,
               this.email.value,
               this.firstName.value,
               this.lastName.value,
               this.currentProperty.id,
               []
            )
            .subscribe(() => {
               this.messageService.add({
                  severity: 'success',
                  summary: 'User Added',
                  detail: `${this.username.value} has been successfully added!`
               });

               const refreshDataStore = new RefreshDataState();
               refreshDataStore.Users = true;
               this.dataStoreService.refreshTaskData(refreshDataStore);
               this.userAdded.emit();
               this.close($event);
            });
      }
   }

   public async onExistingUser($event: MouseEvent) {
      $event.preventDefault();
      if (this.currentProperty && this.existingUser) {
         this.userService
            .addUserToProperty(this.existingUser.authId, this.currentProperty.id)
            .subscribe(() => {
               this.messageService.add({
                  severity: 'success',
                  summary: 'User Added',
                  detail: `${this.username.value} has been successfully added!`
               });
               const refreshDataStore = new RefreshDataState();
               refreshDataStore.Users = true;
               this.dataStoreService.refreshTaskData(refreshDataStore);
               this.userAdded.emit();
               this.close($event);
            });
      }
   }

   search($event: MouseEvent) {
      $event.preventDefault();
      this.onEmailChange();
   }
}
