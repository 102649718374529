import { Injectable } from '@angular/core';
import { Base } from './base';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PhobosTaskInstance } from '../domain/phobos-task-instance';
import { FormTaskAssignment } from '../domain/form-task-assignment';
import { Observable } from 'rxjs';
import { WorkflowFile } from '../domain/workflow-file';
import { Comment } from '../domain/comment';
import { CommentRequest } from './request/comment-request';
import { FollowUp } from '../domain/follow-up';

@Injectable({
   providedIn: 'root'
})
export class FormTaskService extends Base {
   constructor(private http: HttpClient) {
      super();
   }

   claimAssignmentInstance(assignmentId: number) {
      return this.http.get<number>(
         `${
            environment.apiEndpoint
         }/form-task/instance/assignment/${encodeURIComponent(assignmentId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   createNewFormTaskInstance(formTaskId: number, propId: number) {
      return this.http.get<PhobosTaskInstance>(
         `${environment.apiEndpoint}/form-task/${encodeURIComponent(
            formTaskId
         )}/instance/property/${encodeURIComponent(propId)}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   assignFormTaskInstance(
      formTaskInstanceId: number,
      groupId: number,
      propId: number
   ) {
      return this.http.post<number>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            formTaskInstanceId
         )}`,
         {
            groupId: groupId,
            propertyId: propId
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getFormTaskInstance(formTaskInstanceId: number) {
      return this.http.get<PhobosTaskInstance>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            formTaskInstanceId
         )}`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getFormTaskInstanceAssignments(formTaskInstanceId: number) {
      return this.http.get<FormTaskAssignment[]>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            formTaskInstanceId
         )}/assignment`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   completeFormTaskInstance(
      formTaskInstanceId: number,
      sendEmail: boolean = false,
      emailAddresses: number[] = []
   ) {
      return this.http.post<number>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            formTaskInstanceId
         )}/complete`,
         {
            sendEmail: sendEmail,
            emailAddresses: emailAddresses
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   sendCompleteFormTaskInstance(
      formTaskInstanceId: number,
      sendEmail: boolean = false,
      emailAddresses: number[] = []
   ) {
      return this.http.post<number>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            formTaskInstanceId
         )}/complete/email`,
         {
            sendEmail: sendEmail,
            emailAddresses: emailAddresses
         },
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getSupportingFilesByFormTaskInstance(id: number): Observable<WorkflowFile[]> {
      return this.http.get<WorkflowFile[]>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            id
         )}/file/supporting`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getSupportingFileSourceByFormTaskInstance(
      id: number,
      supportingFileId: number
   ): Observable<WorkflowFile> {
      return this.http.get<WorkflowFile>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            id
         )}/file/supporting/${encodeURIComponent(supportingFileId)}/source`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   uploadSupportingFile(processId: number, supportingFile: File) {
      const formData = new FormData();
      formData.append('supportingFile', supportingFile);
      console.log(JSON.stringify(formData));
      return this.http.post<WorkflowFile[]>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            processId
         )}/file/supporting`,
         formData,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   getCommentsForFormTaskInstance(id: number) {
      return this.http.get<Comment[]>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            id
         )}/comment`,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addCommentForFormTaskInstance(
      passedComment: CommentRequest,
      id: number
   ): Observable<Comment> {
      return this.http.post<Comment>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            id
         )}/comment`,
         passedComment,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   addFollowUp(id: number, passedFollowup: FollowUp): Observable<FollowUp> {
      return this.http.post<FollowUp>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            id
         )}/followup`,
         passedFollowup,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   completeFollowUps(followUpIds: number[]): Observable<number> {
      return this.http.post<number>(
         `${environment.apiEndpoint}/form-task/instance/followup/complete`,
         followUpIds,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   readMention(mentionIds: number[]): Observable<number> {
      return this.http.post<number>(
         `${environment.apiEndpoint}/form-task/instance/mention/read`,
         mentionIds,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   completeMention(mentionIds: number[]): Observable<number> {
      return this.http.post<number>(
         `${environment.apiEndpoint}/form-task/instance/mention/complete`,
         mentionIds,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }

   uploadWorkflowFile(processId: number, workflowFile: File) {
      const formData = new FormData();
      formData.append('worlflowfile', workflowFile);
      return this.http.post<PhobosTaskInstance>(
         `${environment.apiEndpoint}/form-task/instance/${encodeURIComponent(
            processId
         )}/file`,
         formData,
         {
            headers: this.headers,
            responseType: 'json'
         }
      );
   }
}
