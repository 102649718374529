import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Group } from '../../domain/group';
import { GroupStoreService } from '../../services/stores/group-store.service';

@Component({
   selector: 'app-group-drop-down',
   templateUrl: './group-drop-down.component.html',
   styleUrls: ['./group-drop-down.component.scss']
})
export class GroupDropDownComponent {
   @Input()
   label: string;
   groups: Group[];
   selectedGroup: Group | undefined;
   @Output() groupChange: EventEmitter<Group> = new EventEmitter();

   constructor(private groupStore: GroupStoreService) {
      this.label = '';
      this.groups = [];
      groupStore.groups.subscribe((groups) => {
         this.groups = groups;
      });
   }

   selectedGroupChange($event: { value: Group }) {
      if ($event.value) {
         this.groupChange.emit($event.value);
      }
   }
}
