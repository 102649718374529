import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
   name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
   transform(value: Date | string | null | undefined): string {
      if (!value) {
         return 'Never logged in';
      }

      const dateTime =
         typeof value === 'string'
            ? DateTime.fromISO(value)
            : DateTime.fromJSDate(value);

      if (!dateTime.isValid) {
         return 'Invalid date';
      }

      return dateTime.toRelative({ locale: 'en' }) || 'Just now';
   }
}
