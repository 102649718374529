import { Task } from '../domain/task';
import { WorkflowType } from '../domain/enums/workflow-type';
import { Router } from '@angular/router';
import { AuditReportRecord } from '../domain/audit-report-record';
import { FollowUp } from '../domain/follow-up';
import { Mention } from '../domain/mention';
import { Property } from '../domain/property';

export class TaskUtils {
   static v1TaskRoute = '/tasks/';
   static v2TaskRoute = '/task/v2/instance/';
   static async goToTask(taskData: Task, router: Router, currentProp: Property) {
      if (currentProp.isPhobosEnabled || taskData?.type == WorkflowType.Form) {
         await router.navigateByUrl(TaskUtils.v2TaskRoute + taskData.processId, {
            state: { task: taskData }
         });
      } else {
         await router.navigateByUrl(TaskUtils.v1TaskRoute + taskData.processId, {
            state: { task: taskData }
         });
      }
   }

   static async goToForm(formData: FollowUp, router: Router, currentProp: Property) {
      if (currentProp.isPhobosEnabled || formData?.type == WorkflowType.Form) {
         await router.navigateByUrl(TaskUtils.v2TaskRoute + formData.processId);
      } else {
         await router.navigateByUrl(TaskUtils.v1TaskRoute + formData.processId);
      }
   }

   static async goToMention(
      mentionData: Mention,
      router: Router,
      currentProp: Property
   ) {
      if (currentProp.isPhobosEnabled || mentionData?.type == WorkflowType.Form) {
         await router.navigateByUrl(TaskUtils.v2TaskRoute + mentionData.processId);
      } else {
         await router.navigateByUrl(TaskUtils.v1TaskRoute + mentionData.processId);
      }
   }

   static async goToTaskViaAuditRecord(
      report: AuditReportRecord,
      router: Router,
      currentProp: Property
   ) {
      if (currentProp.isPhobosEnabled || report.dailyState == 'FORM') {
         await router.navigateByUrl(TaskUtils.v2TaskRoute + report.processId);
      } else {
         await router.navigateByUrl(TaskUtils.v1TaskRoute + report.processId);
      }
   }
}
