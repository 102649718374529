export class DailyRateCheck {
   labels: string[];
   datasets: any[];

   constructor() {
      this.labels = [
         'Facility Management',
         'Front of House',
         'Ops Management',
         'Finance'
      ];
      this.datasets = [
         {
            label: ['Duration in hours'],
            data: [
               { x: 'Facility Management', y: [0, 3.2] },
               { x: 'Front of House', y: [3.2, 5] },
               { x: 'Ops Management', y: [5, 11.4] },
               { x: 'Finance', y: [11.4, 13.6] }
            ],
            backgroundColor: '#b1b5ff',
            borderColor: '#575cf4',
            borderWidth: 2,
            barPercentage: 0.8,
            maxBarThickness: 50,
            borderSkipped: false
         }
      ];
   }
}

export class Label {
   label: string;

   constructor() {
      this.label = '';
   }
}
