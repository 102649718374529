import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
   name: 'trimString'
})
export class TrimStringPipe implements PipeTransform {
   private static SizeLimit: number = 23;

   transform(value?: string): string {
      let result = '';
      if (value) {
         result = value;
         if (result.length > TrimStringPipe.SizeLimit) {
            result = result.slice(0, TrimStringPipe.SizeLimit);
            result += '...';
         }
      }
      return result;
   }
}
