import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { Task } from '../../domain/task';
import { ActivatedRoute } from '@angular/router';
import { PropertyStoreService } from '../../services/stores/property-store.service';
import { Property } from '../../domain/property';
import { TaskListType } from '../../domain/enums/task-list-type';
import { WorkflowTasksStoreService } from '../../services/stores/workflow-tasks-store.service';
import { LoadingOverlayStoreService } from '../../services/stores/loading-overlay-store.service';
import { DataStoreService } from '../../services/data-store.service';
import { RefreshDataState } from '../../domain/refresh-data-state';
import { PermissionsService } from '../../services/permissions.service';
import { UserPermissions } from '../../domain/security/user-permissions';
import { WorkflowType } from '../../domain/enums/workflow-type';
import { DropDownStoreService } from '../../services/stores/drop-down-store.service';

@Component({
   selector: 'app-daily-task-list',
   templateUrl: './daily-task-list.component.html',
   styleUrls: ['./daily-task-list.component.scss']
})
export class DailyTaskListComponent implements OnInit {
   isLoading: boolean;
   tasks: Task[];
   selectedDate: DateTime;
   userPermissions: UserPermissions;
   private currentProperty: Property | undefined;
   private listType: TaskListType;
   private readonly refreshDataStore: RefreshDataState;

   constructor(
      private route: ActivatedRoute,
      private propStore: PropertyStoreService,
      private workFlowTasksStore: WorkflowTasksStoreService,
      private loadingOverlayStore: LoadingOverlayStoreService,
      private dataStoreService: DataStoreService,
      public permissionService: PermissionsService,
      private dropDownStore: DropDownStoreService
   ) {
      this.selectedDate = DateTime.now();
      this.userPermissions = new UserPermissions();
      this.refreshDataStore = new RefreshDataState();
      this.listType = TaskListType.AssignedTasks;
      this.tasks = [];
      this.isLoading = true;
   }

   async ngOnInit(): Promise<void> {
      this.updateDropDownState(true);
      this.userPermissions = await this.permissionService.getPermission();
      this.propStore.currentProperty.subscribe((value) => {
         this.currentProperty = value;
         this.getTasks();
      });
      this.loadingOverlayStore.LoadingState.subscribe((loadingState) => {
         this.isLoading = !(
            !loadingState.AllPropertyTasks &&
            !loadingState.MyTasks &&
            !loadingState.MyAssigned
         );
      });

      this.route.queryParams.subscribe((params: any) => {
         // Save params to localStorage
         if (params) {
            localStorage.setItem('tasklist_page', JSON.stringify(params));
         }

         if (params.listType) {
            switch (+params.listType) {
               case TaskListType.AllTasks:
                  this.listType = TaskListType.AllTasks;
                  this.refreshDataStore.AllPropertyTasks = true;
                  break;
               case TaskListType.AssignedTasks:
               default:
                  this.listType = TaskListType.AssignedTasks;
                  this.refreshDataStore.MyTasks = true;
                  break;
            }
         }

         if (params.selecteddate) {
            this.selectedDate = DateTime.fromISO(params.selecteddate);
            console.log('Parsed selectedDate:', this.selectedDate);
            if (this.selectedDate.isValid) {
               this.workFlowTasksStore.setMyTasksDate(this.selectedDate.toJSDate());
               this.dataStoreService.refreshTaskData(this.refreshDataStore);
            } else {
               console.error('Parsed date is invalid:', this.selectedDate);
            }
         }
         this.getTasks();
      });
   }

   // Method to update boolean inside DropDownStoreService
   updateDropDownState(value: boolean): void {
      this.dropDownStore.setDropDownState(value);
   }

   updatetaskListParamsInLocalStorage() {
      const year = this.selectedDate.year;
      const month = this.selectedDate.month;
      const day = this.selectedDate.day;

      const concatStr = `${year}-${month}-${day}`;

      const newParams = {
         selecteddate: concatStr,
         listType: '3'
      };

      localStorage.setItem('tasklist_page', JSON.stringify(newParams));
   }

   changeDate($event: number) {
      if ($event > 0) {
         this.selectedDate = this.selectedDate.plus({ days: 1 });
         this.updatetaskListParamsInLocalStorage();
      } else {
         this.selectedDate = this.selectedDate.minus({ days: 1 });
         this.updatetaskListParamsInLocalStorage();
      }
      if (this.selectedDate.isValid) {
         this.workFlowTasksStore.setMyTasksDate(this.selectedDate.toJSDate());
         this.dataStoreService.refreshTaskData(this.refreshDataStore);
      }
   }

   private getTasks() {
      if (this.currentProperty != null) {
         switch (this.listType as TaskListType) {
            case TaskListType.AllTasks:
               this.workFlowTasksStore.allTasksBySelectedDate.subscribe((data) => {
                  this.tasks = this.filterTasks(data);
                  this.processData();
               });
               break;
            case TaskListType.AssignedTasks:
               this.workFlowTasksStore.myTasksBySelectedDate.subscribe((data) => {
                  this.tasks = this.filterTasks(data);
                  this.processData();
               });
               break;
            default:
               console.log('Unknown Type of list');
               break;
         }
      }
   }

   private filterTasks(tasks: Task[]): Task[] {
      if (
         (this.listType === TaskListType.AllTasks &&
            !this.userPermissions.forms.admin) ||
         (this.listType === TaskListType.AssignedTasks &&
            !this.userPermissions.forms.read)
      ) {
         return tasks.filter((f) => {
            return f.type != WorkflowType.Form;
         });
      }
      return tasks;
   }

   private processData(): void {
      if (this.tasks) {
         this.isLoading = false;

         for (let i = 0; i < this.tasks.length; i++) {
            const currentTask = this.tasks[i];
            if (i - 1 >= 0) {
               currentTask.prevTask = this.tasks[i - 1];
            }
            if (i + 1 <= this.tasks.length) {
               currentTask.nextTask = this.tasks[i + 1];
            }
         }
      }
   }
}
